<template>
  <main>
    <article>
      <section class="title">
        <h1>PRIVACY POLICY</h1>
        <h2>個人情報保護方針</h2>
      </section>
      <section class="policy-content">
        <div class="content-box">
          <h2>Privacy Policy</h2>
          <h3>個人情報保護方針</h3>
          <div class="splitter-accent">&nbsp;</div>
          <div class="text-content">
            <div class="chapter">
              <p class="align-right">
                制定年月日&nbsp;2021年12月27日<br/>
                最終改正年月日&nbsp;2024年3月19日<br/>
                一般社団法人地域医療介護連携推進機構<br/>
                代表理事 岡﨑光洋<br/>
              </p>
              <p>当社は、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守します。また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。</p>
              <ol type="A">
                <li>個人情報は、地域包括ケアシステムの構築・運用業務における当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。</li>
                <li>個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。</li>
                <li>個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、個人情報保護上、問題があると判断された場合には速やかに是正措置を講じます。</li>
                <li>個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に、適切な対応をさせていただきます。</li>
                <li>個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。</li>
              </ol>
              <p style="text-align: right;">以上</p>
            </div>
            <div class="chapter">
              <div class="privacy-title">【お問合せ窓口】</div>
              <p>個人情報保護方針に関するお問合せにつきましては、下記窓口で受付けております。</p>
              <p>
                一般社団法人地域医療介護連携推進機構 &nbsp;個人情報問合せ窓口<br/>
                電話：080-4209-7029<br/>
                メールアドレス：privacy@coco.or.jp
              </p>
            </div>
            <div class="chapter">
              <div class="privacy-title">（個人情報に関する公表文）</div>
              <p>■個人情報の取扱いについて</p>
              <p>
                １．当社が取り扱う個人情報の利用目的<br/>
                （1）ご本人から直接書面によって取得する個人情報（ホームページや電子メール等によるものを含む）の利用目的<br/>
                ・取得に先立ち、ご本人に対し書面により明示します。<br/>
                （2）前項以外の方法によって取得する個人情報の利用目的
              </p>
              <table>
                <tr>
                  <th class="category">分類</th>
                  <th>利用目的</th>
                </tr>
                <tr>
                  <td class="category">個人のお客様情報(電話等で問合せを頂いた場合)</td>
                  <td>
                    ご利用履歴管理のため<br/>
                    お問合せ対応のため
                  </td>
                </tr>
                <tr>
                  <td class="category">
                    会員様情報<br/>
                    ・地域にお住まいの方及びご家族<br/>
                    ・医療福祉介護従事者<br/>
                    ・施設（医療・福祉・介護・文教・行政等）に関わる方
                  </td>
                  <td>
                    会員様サポートのため<br/>
                    ご利用履歴管理のため<br/>
                    会員様向けサービス提供のため<br/>
                    お問合せ対応のため
                  </td>
                </tr>
                <tr>
                  <td class="category">お取引先担当者情報</td>
                  <td>依頼内容確認のため</td>
                </tr>
                <tr>
                  <td class="category">委託先様情報</td>
                  <td>
                    業務管理のため<br/>
                    委託料の支払いのため<br/>
                    セキュリティ管理のため
                  </td>
                </tr>
              </table>
            </div>
            <div class="chapter">
              <div class="privacy-title">■保有個人データに関する事項の周知</div>
              <p>当社で保有している保有個人データに関して、ご本人様又はその代理人様からの利用目的の通知、開示、第三者提供記録の開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止の請求（以下、「開示等の請求」といいます）につきましては、以下の要領にて対応させていただきます</p>
              <p>
                a. 事業者の名称<br/>
                一般社団法人地域医療介護連携推進機構<br/>
                〒010-8572&nbsp;秋田県 秋田市山王３丁目１−１&nbsp;秋田県第二庁舎&nbsp;3階 B-5<br/>
                代表理事 岡﨑光洋

              </p>
              <p>
                b. 個人情報の保護管理者<br/>
                管理者名：岡﨑光洋<br/>
                所属部署：総務<br/>
                連絡先：電話：080-4209-7029 メール：privacy@coco.or.jp

              </p>
              <p>
                c. 全ての開示対象個人情報の利用目的
              </p>
              <table>
                <tr>
                  <th class="category">分類</th>
                  <th>利用目的</th>
                </tr>
                <tr>
                  <td class="category">個人のお客様情報(電話等で問合せを頂いた場合)</td>
                  <td>
                    ご利用履歴管理のため<br/>
                    お問合せ対応のため
                  </td>
                </tr>
                <tr>
                  <td class="category">
                    会員様情報<br/>
                    ・地域にお住まいの方及びご家族<br/>
                    ・医療福祉介護従事者<br/>
                    ・施設（医療・福祉・介護・文教・行政等）に関わる方
                  </td>
                  <td>
                    会員様サポートのため<br/>
                    ご利用履歴管理のため<br/>
                    会員様向けサービス提供のため<br/>
                    お問合せ対応のため
                  </td>
                </tr>
                <tr>
                  <td class="category">お取引先担当者情報</td>
                  <td>依頼内容確認のため</td>
                </tr>
                <tr>
                  <td class="category">委託先様情報</td>
                  <td>
                    業務管理のため<br/>
                    委託料の支払いのため<br/>
                    セキュリティ管理のため
                  </td>
                </tr>
                <tr>
                  <td class="category">当社従業員情報</td>
                  <td>社員の人事労務管理、業務管理、健康管理、セキュリティ管理のため</td>
                </tr>
                <tr>
                  <td class="category">当社への採用応募者情報</td>
                  <td>採用応募者への連絡と当社の採用業務管理のため</td>
                </tr>
                <tr>
                  <td class="category">特定個人情報</td>
                  <td>番号法に定められた利用目的のため</td>
                </tr>
              </table>
              <p>d. 開示対象個人情報の取扱いに関する苦情の申し出先</p>
              <p>
                一般社団法人地域医療介護連携推進機構 &nbsp;個人情報問合せ窓口<br/>
                電話：080-4209-7029<br/>
                メールアドレス：privacy@coco.or.jp
              </p>
              <p>e. 認定個人情報保護団体</p>
              <p>現在、当社が加盟する認定個人情報保護団体はありません。</p>
              <p>f. 保有個人データ又は第三者提供記録の開示等の求めに応じる手続き</p>
              <ol type="i">
                <li>
                  開示等の求めの申し出先<br/>
                  開示等のお求めは、上記個人情報問合せ窓口にお申し出ください。<br/>
                  ※電磁的手続きによる開示等をご希望の方は、その旨お申し出ください。原則としてご希望に沿って処理させていただきます。<br/>
                </li>
                <li>
                  開示等の求めに関するお手続き
                  <ol type="1">
                    <li>お申し出受付け後、当社からご利用いただく所定の請求書様式「保有個人データ開示等請求書」を郵送いたします。</li>
                    <li>ご記入いただいた請求書、代理人によるお求めの場合は代理人であることを確認する書類、手数料分の郵便為替（利用目的の通知並びに開示の請求の場合のみ）を上記個人情報問合せ窓口までご郵送ください。</li>
                    <li>上記請求書を受領後、ご本人確認のため、当社に登録していただいている個人情報のうちご本人確認可能な2項目程度（例：電話番号と生年月日等）の情報をお問合せさせていただきます。</li>
                    <li>回答は原則としてご本人に対して書面（封書郵送）にておこないます。</li>
                  </ol>
                </li>
                <li>
                  代理人によるお求めの場合、代理人であることを確認する資料<br/>
                  開示等をお求めになる方が代理人様である場合は、代理人である事を証明する資料及び代理人様ご自身を証明する資料を同封してください。各資料に含まれる本籍地情報は都道府県までとし、それ以降の情報は黒塗り等の処理をしてください。また各資料は個人番号を含まないものをお送りいただくか、全桁を墨塗り等の処理をしてください。
                  <ol type="1">
                    <li>
                      代理人である事を証明する資料<br/>
                      ＜開示等の求めをすることにつき本人が委任した代理人様の場合＞<br/>
                      本人の委任状（原本）<br/>
                      ＜代理人様が未成年者の法定代理人の場合＞いずれかの写し<br/>
                      戸籍謄本<br/>
                      住民票（続柄の記載されたもの）<br/>
                      その他法定代理権の確認ができる公的書類<br/>
                      ＜代理人様が成年被後見人の法定代理人の場合＞いずれかの写し<br/>
                      後見登記等に関する登記事項証明書<br/>
                      その他法定代理権の確認ができる公的書類
                    </li>
                    <li>
                      代理人様ご自身を証明する資料<br/>
                      運転免許証<br/>
                      パスポート<br/>
                      健康保険の被保険者証（被保険者等記号・番号等は全桁を墨塗りしてご提出ください）<br/>
                      住民票
                    </li>
                  </ol>
                </li>
                <li>
                  利用目的の通知または開示のお求めについての手数料<br/>
                  1回のお求めにつき1000円<br/>
                  （紙面でのご請求の場合は、お送りいただく請求書等に郵便為替を同封していただきます。その他の方法でご請求いただく場合は、ご請求時にご相談させていただきます）
                </li>
              </ol>
              <p>g. 個人情報の取扱体制や講じている措置の内容</p>
              <ol type="1">
                <li>
                  基本方針の策定<br/>
                  個人データの適正な取扱いの確保のため、「関係法令・ガイドライン等の遵守」、「質問及び苦情処理の窓口」等について「個人情報保護方針」を策定しています。
                </li>
                <li>
                  個人データの取扱いに係る規律の整備<br/>
                  取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者及びその任務等について個人情報保護規程を策定しています。
                </li>
                <li>
                  組織的安全管理措置<br/>
                  ①個人データの取扱いに関する責任者を設置するとともに、個人データを取り扱う従業者及び当該従業者が取り扱う個人データの範囲を明確化し、法や取扱規程に違反している事実又は兆候を把握した場合の責任者への報告連絡体制を整備しています。<br/>
                  ②個人データの取扱状況について、定期的に自己点検を実施するとともに、他部署や外部の者による監査を実施しています。
                </li>
                <li>
                  人的安全管理措置<br/>
                  ①個人データの取扱いに関する留意事項について、従業者に定期的な研修を実施しています。<br/>
                  ②個人データについての秘密保持に関する事項を就業規則に記載しています。
                </li>
                <li>
                  物理的安全管理措置<br/>
                  ①個人データを取り扱う区域において、従業者の入退室管理及び持ち込む機器等の制限を行うとともに、権限を有しない者による個人データの閲覧を防止する措置を講じています。<br/>
                  ②個人データを取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止するための措置を講じるとともに、事業所内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、容易に個人データが判明しないよう措置を講じています。
                </li>
                <li>
                  技術的安全管理措置<br/>
                  ①アクセス制御を実施して、担当者及び取り扱う個人情報データベース等の範囲を限定しています。<br/>
                  ②個人データを取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入しています。
                </li>
              </ol>
            </div>
            <div class="end-of-content">以上</div>
          </div>
        </div>
      </section>
      <inside-footer/>
    </article>
  </main>
</template>

<script>
import InsideFooter from '@/components/CocoCommon/InsideFooter.vue'

export default {
  name: 'PrivacyContent',

components: {
  InsideFooter
},

}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

section.policy-content {
  padding: 107px 162px 0 62px;
  .content-box {
    background-color: transparent;
    div.chapter {
      margin-bottom: 10px;
    }
    ol>li {
      margin-bottom: 10px;
    }
    p.align-right {
      text-align: right;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid $content-base-color;
      th {
        text-align: left;
        font-weight: normal;
        font-size: 15px;
        background-color: #ddd;
        border: 1px solid $content-base-color;
      }
      td {
        background-color: #fff;
        border: 1px solid $content-base-color;
      }
      td.category {
        width: 200px;
      }
    }
    .end-of-content {
      text-align: right;
    }
  }
}
@include mediaQuery('phone') {
  section.title {
    height: auto;
  }
  section.policy-content {
    padding: 10px;
    .content-box {
      padding: 10px;
      background-color: rgba($color: #fff, $alpha: .5);
      border: none;
    }
  }
}

</style>
