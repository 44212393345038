<template>
  <navigation-header/>
  <router-view/>
  <coco-footer/>
</template>

<script>
import NavigationHeader from '@/components/NavigationHeader.vue'
import CocoFooter from '@/components/CocoFooter.vue'

export default {
  name: 'HomeView',

  components: {
    NavigationHeader,
    CocoFooter
  }
}
</script>

<style lang="scss">
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

main {
  margin: 0;
  background-color: #fff;
  background-image: url('~@/assets/css/images/background_repeat.png');
  background-repeat: repeat-y;
  background-position: right;
}

section {
  .content-box {
    background-color: #fff;
    h2 {
      margin: 0;
      padding: 0;
      font-size: 26px;
      font-weight: 600;
    }
    h3 {
      margin: 0;
      margin-top: -5px;
      padding: 0px;
      font-size: 14px;
      font-weight: 300;
    }
    .splitter-accent {
      width: 75px;
      height: 1px;
      margin-top: 21px;
      margin-bottom: 16px;
      border-top: 1px solid $accent-color;
    }
    .navigation-block {
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;
      a.jump-to-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 43px;
        width: 164px;
        padding: 0 20px;
        border-radius: 21px;
        background-color: $accent-color;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  @include mediaQuery('phone') {
    .content-box {
      background-color: #fff;
      border: 1px solid #ddd;
      h2 {
        font-size: 18px;
        font-weight: 600;
      }
      h3 {
        margin: 0;
        margin-top: -5px;
        padding: 0px;
        font-size: 13px;
        font-weight: 300;
      }
      .splitter-accent {
        width: 75px;
        height: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-top: 1px solid $accent-color;
      }
      .navigation-block {
        margin-top: 15px;
        display: flex;
        justify-content: flex-end;
        a.jump-to-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 43px;
          width: 164px;
          padding: 0 20px;
          border-radius: 21px;
          background-color: $accent-color;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}


a.back-to-top {
  color: $content-base-color;
}
a.back-to-top:hover {
  color: $accent-color;
}

section.title {
  margin: 0;
  padding: 32px 0 0;
  height: calc(175px - 52px);
  background-color: $accent-bg-color;
  h1 {
    margin: 0;
    margin-right: 70px;
    padding: 0;
    color: #fff;
    font-size: 35px;
    font-weight: 600;
    text-align: right;
  }
  h2 {
    margin: 0;
    margin-top: -5px;
    margin-right: 70px;
    padding: 0;
    color: $content-base-color;
    font-size: 15px;
    font-weight: 600;
    text-align: right;
  }
  nav.inpage-navigation {
    margin-top: 10px;
    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style-type: none;
      margin-block-start: 0;
      margin-inline-start: 0;
      background-color: #fff;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 6px;
        // width: 120px;
        height: 26px;
        font-size: 14px;
        text-align: center;
        a {
          color: $content-base-color;
          text-decoration: none;
        }
        a:hover {
          color: $accent-color;
        }
      }
    }
  }
  @include mediaQuery('phone') {
    padding: 12px 0;
    h1 {
      margin: 0;
      margin-right: 20px;
      font-size: 22px;
    }
    h2 {
      margin: 0;
      margin-right: 20px;
      font-size: 15px;
    }
    nav.inpage-navigation {
      margin-top: 10px;
      ul {
        flex-wrap: wrap;
        li {
          flex-grow: 1;
          margin: 0;
          border: 1px solid #aaa;
        }
      }
    }
  }
}


section.left-content {
  margin: 0;
  margin-bottom: 130px;
  padding: 0;
  padding-top: 52px;
  height: calc(558px - 52px);
  background-repeat: no-repeat;
  background-position: right;
  .content-box {
    padding-top: 90px;
    padding-left: 60px;
    padding-right: 128px;
    height: calc(464px - 90px);
    width: calc(690px - 60px - 128px);
    border-radius: 30px;
    h2 {
      font-size: 33px;
      letter-spacing: 2px;
    }
    h3 {
      margin-top: 5px;
    }
    .text-content {
      p {
        font-size: 13px;
        line-height: 25px;
      }
      ul {
        margin: 0;
        padding-inline-start: 1em;
        font-size: 13px;
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
  @include mediaQuery('phone') {
    margin: 0;
    padding: 0;
    height: auto;
    background-repeat: no-repeat;
    background-position: right;
    .content-box {
      margin: 10px auto;
      padding: 20px;
      height: auto;
      width: calc(100vw - 60px);
      border-radius: 30px;
      h2 {
        font-size: 22px;
        letter-spacing: 2px;
      }
      h3 {
        margin-top: 5px;
        font-size: 15px;
      }
    }
  }
}

section.right-content {
  margin: 0;
  margin-bottom: 130px;
  padding: 0;
  padding-top: 52px;
  height: calc(558px - 52px);
  background-repeat: no-repeat;
  background-position: left;
  .content-box {
    margin-left: calc(100% - 680px);
    padding-top: 90px;
    padding-left: 77px;
    padding-right: 100px;
    height: calc(464px - 90px);
    width: calc(680px - 77px - 100px);
    border-radius: 30px 0 0 30px;
    h2 {
      font-size: 33px;
      letter-spacing: 2px;
    }
    h3 {
      margin-top: 5px;
    }
    .text-content{
      p {
        font-size: 13px;
        line-height: 25px;
      }
      ul {
        margin: 0;
        padding-inline-start: 1em;
        font-size: 13px;
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
  @include mediaQuery('phone') {
    margin: 0;
    padding: 0;
    height: auto;
    background-repeat: no-repeat;
    background-position: right;
    .content-box {
      margin: 0 auto;
      padding: 20px;
      height: auto;
      width: calc(100vw - 60px);
      border-radius: 30px;
      h2 {
        font-size: 22px;
        letter-spacing: 2px;
      }
      h3 {
        margin-top: 5px;
        font-size: 15px;
      }
    }
  }
}
</style>