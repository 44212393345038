<template>
  <footer class="content-footer">
    <p>Copyright © 2021 Cooperation promotion organization for Community medical and care All Rights Reserved.</p>
  </footer>  
</template>

<script>
export default {
  name: 'CocoFooter'
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

footer.content-footer {
  height: 64px;
  background-color: $accent-bg-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 300;
  @include mediaQuery('phone') {
    height: 44px;
    text-align: center;
  }
}
</style>
