<template>
  <main>
    <article>
      <section class="title"></section>
      <section class="description block-section">
        <h2 class="title">事業の内容</h2>
        <div class="content">
          <p>県下で認知が進む、個人が情報を管理する「ナラティブブック」システムを基盤として、医療及び多職種が活用する医療関係者用バイタル測定機器を接続するオンライン接続機器を組み合わせ、へき地、無医地区等で活用するオンライン診療モデル(秋田県モデル)を構築する。</p>
          <ol>
            <li>参加希望医師や病院、地域（無医地域や医療過疎地域）を選定し、実施モデルの運用資料を作成すると共に、受診者数などの評価データを収集し、普及啓発に努める。</li>
            <li>ビデオ通信機能、バイタルサインの収集機能等を実装し、本人・家族の安心が得られ、効率的な医療が提供できるオンライン診療システムの実施モデルを構築する。</li>
            <li>実証事業として、秋田モデルの安全性・有用性、働き方改革の改善効果、対面診療との機能の違い、有用性を評価する。</li>
          </ol>
        </div>
      </section>
      <section class="documents block-section">
        <h2 class="title">資料</h2>
        <div class="content">
          <div class="checkseet">
            オンライン診療開始手順書（チェックシート付き）
            <div class="download">
              <a class="download-btn" href="/data/howto_start_online_care_220801.pdf" target="_blank">ダウンロード（PDF）</a>
            </div>
          </div>
          <ul>
            <li>
              参考：オンライン診療入門〜導入の手引き〜
              <div class="download">
                <a class="download-btn" href="https://www.mhlw.go.jp/content/000889114.pdf" target="_blank">【日本医師会】ダウンロード（PDF）</a>
              </div>
            </li>
            <li>
              参考：オンライン診療の適切な実施に関する指針
              <div class="download">
                <a class="download-btn" href="https://www.mhlw.go.jp/content/000889114.pdf" target="_blank">【厚生労働省】ダウンロード（PDF）</a>
              </div>
            </li>
            <li>
              参考：日本医学会連合 オンライン診療の初診に関する提言（2021年6月1日版）
              <div class="download">
                <a class="download-btn" href="https://www.jmsf.or.jp/uploads/media/2021/06/20210603172150.pdf" target="_blank">【日本医学会連合】ダウンロード（PDF）</a>
              </div>
            </li>
            <li>
              参考：オンライン診療による継続診療可能な疾患／病態（1.2版 2022年4月11日）
              <div class="download">
                <a class="download-btn" href="https://www.mhlw.go.jp/content/000889114.pdf" target="_blank">【日本医師会】ダウンロード（PDF）</a>
              </div>
            </li>
          </ul>
          <div class="checkseet">
            ●オンライン診療の実施にかかる診療計画・同意書（案）
            <div class="download">
              <a class="download-btn" href="/data/plan_agreement_template.xlsx" target="_blank">【日本医学会連合】ダウンロード（PDF）</a>
            </div>
          </div>
        </div>
      </section>
      <section class="reports block-section">
        <h2 class="title">報告会等</h2>
        <div class="content">
          <span class="date">2022.08</span>オンライン診療についてのアンケート調査&nbsp;（実施中）
        </div>
      </section>
      <section class="canference block-section">
        <h2 class="title">調査・報告資料等</h2>
        <div class="content">
          <div class="title"><span class="date">2022.03.30</span>令和 3 年度医療デジタル化推進事業成果報</div>
          <p class="description">
            日時&nbsp;令和4年&nbsp;3月20日&nbsp;10時30分〜<br/>
            場所&nbsp;一般社団法人秋田県医師会 会議室<br/>
            主 催 一般社団法人秋田県医師会<br/>
          </p>
          <ol>
            <li>
              <a href="https://youtu.be/JGKYf0m205M" target="_blank">開会の挨拶&nbsp;秋田県医師会&nbsp;副会長&nbsp;伊藤伸一<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/n6BovTiaNV4" target="_blank">講演&nbsp;東京大学大学院薬学系研究科医薬政策学講座&nbsp;特任研究員&nbsp;岡﨑光洋<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/1Q2oB4XV9lU" target="_blank">取組説明&nbsp;秋田県医師会&nbsp;副会長&nbsp;伊藤伸一<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/GInZX8KjpNo" target="_blank">WG報告1&nbsp;秋田県医師会&nbsp;理事&nbsp;小野崎圭助<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/3eD-RFt8Kqo" target="_blank">WG報告2&nbsp;秋田県医師会&nbsp;副会長&nbsp;伊藤伸一<img src="/img/video_link_icon_blue.png" /></a>
            </li>
          </ol>
          <div class="page-link">
            <a href="https://www.youtube.com/watch?v=JGKYf0m205M&list=PLbYxMSJzPemAbwEXmmn4STtgZdPYkhNUP" target="_blank">続けて見られるプレイリストはこちら&nbsp;▶</a>
          </div>
          <div class="title"><span class="date">2024.02.24</span>令和5年度 秋田県オンライン実証事業成果報告会</div>
          <p class="description">
            日時&nbsp;令和6年&nbsp;2月24日&nbsp;10時30分〜<br/>
            場所&nbsp;秋田県総合保健センター2F 大会議室<br/>
            主 催 一般社団法人秋田県医師会<br/>
          </p>
          <ol>
            <li>
              <a href="https://youtu.be/iQGfkA20Gig" target="_blank">開会の挨拶 秋田県オンライン診療実証事業 運営委員会 委員長 南谷佳弘<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/VE87OuY_CUA" target="_blank">講演「地域医療におけるオンライン診療活用の可能性」 厚生労働省保険局保険課 課長補佐 柴田直慧氏<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/DwevtXhHv4s" target="_blank">取組説明 秋田県医師会 副会長 伊藤伸一<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/mCel50g1Sl8" target="_blank">ワーキンググループ取組報告① 秋田大学 遠隔医療推進センター 特別教授 山本浩史氏<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/KBml85aTOPo" target="_blank">ワーキンググループ取組報告② たむら船越クリニック 院長 田村広美<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/Ag5booIHQFo" target="_blank">ワーキンググループ取組報告③ 男鹿みなと市民病院 診療部長 関 啓二<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/48v1zL7Z_5A" target="_blank">ワーキンググループ取組報告④ 佐藤病院 院長 佐藤麻美子<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/FJT753otOLM" target="_blank">ワーキンググループ取組報告⑤ 小野崎医院 院長 小野崎圭助<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/pyxSFjZ2j7U" target="_blank">ワーキンググループ取組報告⑥ 管医院 院長 管 康徳<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/7PhWwk-tcRc" target="_blank">ワーキンググループ取組報告⑦ 地域医療介護連携推進機構 代表理事 岡崎光洋（小泉医院 院長 小泉亮の代理報告を含む）<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/4EZEvW5EvsI" target="_blank">ワーキンググループ取組報告⑧ 秋田県医師会 副会長 伊藤伸一<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/XiwGH2l45cQ" target="_blank">ナラティブブック秋田の活用について 地域医療介護連携推進機構 代表理事 岡崎光洋<img src="/img/video_link_icon_blue.png" /></a>
            </li>
            <li>
              <a href="https://youtu.be/60qlUmq5DFE" target="_blank">閉会の挨拶 秋田県オンライン診療実証事業 運営委員会 副委員長 大田原康成<img src="/img/video_link_icon_blue.png" /></a>
            </li>
          </ol>
          <div class="page-link">
            <a href="https://www.youtube.com/playlist?list=PLbYxMSJzPemDaqqWFtYf6acFA7CiZ9KOg" target="_blank">続けて見られるプレイリストはこちら&nbsp;▶</a>
          </div>
        </div>
      </section>
      <section class="links block-section">
        <h2 class="title">参考資料・関係サイト</h2>
        <div class="content">
          <h3>厚生労働省</h3>
          <p>
            医療分野の情報化の推進について<br/>
            <a href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/kenkou_iryou/iryou/johoka/index.html" target="_blank">https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/kenkou_iryou/iryou/johoka/index.html</a>
          </p>
          <p>
            医療分野のサイバーセキュリティ対策について<br/>
            <a href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/kenkou_iryou/iryou/johoka/cyber-security.html" target="_blank">https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/kenkou_iryou/iryou/johoka/cyber-security.html</a>
          </p>
          <p>
            オンライン診療に関するホームページ<br/>
            <a href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/kenkou_iryou/iryou/rinsyo/index_00010.html" target="_blank">https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/kenkou_iryou/iryou/rinsyo/index_00010.html</a>
          </p>
        </div>
      </section>
    </article>
  </main>
</template>

<script>
export default {
  name: 'OnlineContent'
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

div.outer-frame {
  background-color: $online-bg-color;
  color: $online-main-color;
  main {
    background-color: $online-bg-color;
    background-image: none;
    height: calc(100vh - 80px);
    height: calc(100dvh - 80px);
    overflow: auto;
  }
  section.title {
    height: 340px;
    margin: 0;
    padding: 0;
    background-image: url('~@/assets/css/images/online_medical_hero_bg.png');
    background-color: $online-main-color;
    background-position: top left;
  }
  section.block-section {
    margin: 67px 206px;
    padding: 0;
    background-color: #fff;
    font-size: 15px;
    border-radius: 10px;
    border: 1px solid $online-main-color;
    a {
      color: $online-main-color;
    }
    h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      height: 77px;
      background-color: $online-main-color;
      color: #fff;
      text-align: center;
      border-radius: 10px 10px 0 0;
    }
    h3 {
      text-align: center;
    }
    .content {
      padding: 52px 42px;
      ol {
        padding-left: 1.5em;
        li {
          margin: 20px 0;
          a {
            text-decoration: none;
            border-bottom: 1px solid $online-main-color;
            img {
              margin-left: 5px;
            }
          }
        }
      }
      .checkseet {
        font-size: 17px;
      }
      .download {
        display: flex;
        justify-content: flex-end;
      }
      a.download-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 26px;
        width: auto;
        padding: 0 20px;
        border-radius: 13px;
        background-color: #ffd833;
        color: $online-main-color;
        font-size: 12px;
        font-weight: 500;
        text-decoration: none;
      }
      ul {
        li {
          padding: 10px;
        }
      }
      span.date {
        margin-right: 20px;
        padding: 2px 5px;
        background-color: $online-main-color;
        color: #fff;
        font-weight: bold;
      }
      p.description {
        padding-left: 10px;
      }
      .page-link {
        display: flex;
        justify-content: flex-end;
        a.page-link-btn {
          padding: 0 24px;
          display: flex;
          align-items: center;
          height: 45px;
          background-color: $online-main-color;
          color: #fff;
          text-decoration: none;
        }
      }
      h3 {
        margin: 0;
      }
      p {
        margin: 20px 0;
        line-height: 15px;
        a {
          font-size: 11px;
        }
      }
    }
  }
  @include mediaQuery('phone') {
    section.title {
      height: 120px;
      background-size: cover;
    }
    section.block-section {
      margin: 10px;
      h2 {
        padding: 10px 20px;
        height: auto;
      }
      .content {
        padding: 10px;
        overflow-wrap: break-word;
        ul {
          padding-left: 1.5em;
          li {
            padding-left: 0;
          }
        }
        p {
          margin: 0;
          line-height: 1.5em;
        }
      }
    }
  }
}
</style>
