<template>
  <div class="kids-how-to-start">
    <section>
      <h2 class="title"><span>キッズナラティブ秋田の</span><span>詳しい始め方</span></h2>
      <div class="lower-decoration"></div>
      <div class="content-index">
        <h3>利用開始までの流れ</h3>
        <ol class="index-list">
          <li><a href="#checkmail">メールアドレス確認</a></li>
          <li><a href="#createkidsaccount">児童のアカウントを作成</a></li>
          <li><a href="#signinpage">ページにログイン</a></li>
          <li><a href="#writetoinfo">infoへの書き込み</a></li>
          <li><a href="#setralation">かかりつけ設定</a></li>
        </ol>
      </div>
      <div class="action check-mailaddress">
        <h3><a name="checkmail">1. メールアドレス確認</a></h3>
        <div class="content">
          登録する時の本人確認や利用中の各種通知が、このアドレス宛に届きます。
        </div>
        <div class="notify">
          <ul class="notify-list">
            <li>
              メールアドレスにGメール、Yahoo!メールをお薦めします。<br/>
              @narrativebook.jpドメインからのメールを受信できるようにしておいてください。
            </li>
          </ul>
        </div>
      </div>
      <div class="action check-mailaddress">
        <h3><a name="createkidsaccount">2. 児童のナラティブブックID(NBID)を取得する</a></h3>
        <div class="content">
          <div class="list-content">
            <span class="index-number">1.</span>最初に<a href="https://apple.co/3ouEy6B" target="_blank">Appleアプリストア</a>または<a href="https://play.google.com/store/apps/details?id=jp.co.xcf.narrativebook" target="_blank">Googleプレイストア</a>からナラティブブックアプリを取得してインストールします。
          </div>
          <div class="parent-list-content">
            <div class="child-list-content">
              <div class="text-content"><span class="index-number">2.</span>アプリを立ち上げたら「新規利用者登録」ボタンをタップして登録手続き画面に進みます。</div>
              <div class="figure-content"><img class="smartphone-screen" src="/img/manualscreen/kids2-2.png"/></div>
            </div>
            <div class="child-list-content">
              <div class="text-content"><span class="index-number">3.</span>利用するメールアドレスを入力し「次へ」をタップします。本人確認メールが送信されます。</div>
              <div class="figure-content"><img class="smartphone-screen" src="/img/manualscreen/kids2-3.png"/></div>
            </div>
            <div class="child-list-content">
              <div class="text-content"><span class="index-number">4.</span>メール記載のURLから登録画面に進み、アドレスに変更が無ければここでも「次へ」をタップします。</div>
              <div class="figure-content"><img class="smartphone-screen" src="/img/manualscreen/kids2-4.png"/></div>
            </div>
            <div class="child-list-content">
              <div class="text-content"><span class="index-number">5.</span>最初にNBIDとパスワードを決めます<span class="notify-mark">※</span>。引き続き利用者の情報を入力して登録します。</div>
              <div class="figure-content"><img class="smartphone-screen" src="/img/manualscreen/kids2-5.png"/></div>
            </div>
          </div>
        </div>
        <div class="notify">
          <ul class="notify-list">
            <li>NBIDは左記の文字種で 6文字以上の任意の文字列にご自分で決められますが、他のユーザーと重複する場合には枠の左に「X」が表示され、使用できません。</li>
            <li>パスワードは任意の8文字以上を使用してください。</li>
            <li>新規利用登録(アカウント作成)の方法はこの動画<a href="https://youtu.be/GjYX-ZOQckc" target="_blank">(https://youtu.be/GjYX-ZOQckc)</a>で説明されています。</li>
          </ul>
          <div class="boxed-notify">
            【NBID に使用可能な文字】<br/>
            ➔ 半角英小文字<br/>
            ➔ 半角数字、 ➔@._/-+()<br/>
              (半角記号8種)<br/>
          </div>
        </div>
      </div>
      <div class="action check-mailaddress">
        <h3><a name="signinpage">3. 児童のナラティブブックにログインします</a></h3>
        <div class="content">
          最初はNBIDとパスワードを入力してログインします。(このときオートログインのチェックを外していなければ2回目以降はワンタッチでログインできます。)
          <div class="content-subtitle">【ワンタッチログインの追加手順】</div>
          <div class="parent-list-content">
            <div class="child-list-content">
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids3-1.png"/>
                <div class="figure-caption">[初期ログイン画面]</div>
              </div>
            </div>
            <div class="child-list-content">
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids3-2.png"/>
                <div class="figure-caption">[ログイン設定画面]</div>
              </div>
            </div>
            <div class="child-list-content">
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids3-3.png"/>
              </div>
            </div>
            <div class="child-list-content">
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids3-4.png"/>
                <div class="figure-caption">[ワンタッチログイン]</div>
              </div>
            </div>
          </div>
        </div>
        <div class="notify">
          <ul class="notify-list">
            <li>登録時にそのままログインした場合には、そのアカウントですぐにワンタッチでログインできるようになります。</li>
            <li>スマートフォンでのログインについては、この動画<a href="https://youtu.be/-SoTxuyBIeA" target="_blank">(https://youtu.be/-SoTxuyBIeA)</a>で説明されています。</li>
          </ul>
          <div class="boxed-content">
            <h4>登録したい児童がふたり以上の場合</h4>
            両親がそれぞれの児童を担当するか、「代理設定機能」をご利用ください。
            <ul class="notify-list">
              <li>代理設定については、この動画<a href="https://youtu.be/7p-o2wkL-D0" target="_blank">(https://youtu.be/7p-o2wkL-D0)</a>で説明されています。</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="action check-mailaddress">
        <h3><a name="writetoinfo">4. 児童の掲示板にあるinfoに重要な情報を書き込む</a></h3>
        <div class="content">
          <span class="impotantly">【重 要】 ナラティブブックを使い始めるときに、<span class="attention">info</span>に次の<span class="attention">3</span>つの情報を記入しておいてください。</span>
          <ol class="impotantly-list">
            <li>「保護者」 「主治医」 「緊急時の連絡先」</li>
            <li>「使っている医療機器など」</li>
            <li>「栄養摂取の内容・方法」 「内服薬」</li>
          </ol>
          <ul class="notify-list">
            <li>これらの情報は、毎年の誕生日に見直し・更新してください(年一回以上)。</li>
            <li>書式は自由です。右ページにある「書き込みサンプル」を参考にしてください。</li>
          </ul>
          <div class="content-subtitle">【infoの追加・編集方法】</div>
          <div class="parent-list-content">
            <div class="child-list-content">
              <div class="text-content"><span class="index-number">1.</span>児童のタイムラインを表示したら<img class="inline-images" src="/img/manualscreen/bord_comment_icon.png"/>をタップして掲示板画面に移動します。</div>
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids4-1.png"/>
                <div class="figure-caption">[児童タイムライン]</div>
              </div>
            </div>
            <div class="child-list-content">
              <div class="text-content"><span class="index-number">2.</span>移動したら<img class="inline-images" src="/img/manualscreen/info_icon.png"/>をタップしてinfo編集画面を開きます。(<img class="inline-images" src="/img/manualscreen/open_close_icon.png"/>ではありません)</div>
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids4-2.png"/>
              </div>
            </div>
            <div class="child-list-content">
              <div class="text-content"><span class="index-number">3.</span>「新規作成」か<img class="inline-images" src="/img/manualscreen/edit_icon.png"/>(編集)をクリックして編集を始めます。</div>
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids4-3.png"/>
              </div>
            </div>
          </div>
          <div class="parent-list-content left">
            <div class="child-list-content">
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids4-4.png"/>
                <div class="figure-caption">[新規作成]</div>
              </div>
            </div>
            <div class="child-list-content">
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids4-5.png"/>
                <div class="figure-caption">[編集]</div>
              </div>
            </div>
          </div>
          <div class="blocked-content">
            <h4>【info編集初期画面 各ボタンの機能】</h4>
            <img src="/img/manualscreen/kids4-6.png" />
          </div>
          <ul class="notify-list">
            <li>infoは掲示板への表示・非表示が選択できます。</li>
            <li>「緊急連絡先」「病気や健康に関する事など」の2項目のタイトルが「表示しているinfo」にデフォルトで設定されています。</li>
            <li>上記2項目を開くと本文にはinfoの使い方が書いてあります。</li>
          </ul>
        </div>
        <div class="additional-content content">
          <h4>書き込みサンプル(編集画面)</h4>
          <div class="parent-list-content">
            <div class="child-list-content sample">
              <div class="sample-title">「保護者」 「主治医」 「緊急時の連絡先」</div>
              <img class="smartphone-screen" src="/img/manualscreen/kids4sample1.png"/>
              <div>
                <div class="copy-link" @click="copyContent('contact')">テキストをコピー</div>
                <div class="copied-label" v-show="copiedContent === 'contact'">コピーしました</div>
              </div>
            </div>
            <div class="child-list-content sample">
              <div class="sample-title">「使っている医療機器など」</div>
              <img class="smartphone-screen" src="/img/manualscreen/kids4sample2.png"/>
              <div>
                <div class="copy-link" @click="copyContent('devices')">テキストをコピー</div>
                <div class="copied-label" v-show="copiedContent === 'devices'">コピーしました</div>
              </div>
            </div>
            <div class="child-list-content sample">
              <div class="sample-title">「栄養摂取の内容・方法」 「内服薬」</div>
              <img class="smartphone-screen" src="/img/manualscreen/kids4sample3.png"/>
              <div>
                <div class="copy-link" @click="copyContent('medicine')">テキストをコピー</div>
                <div class="copied-label" v-show="copiedContent === 'medicine'">コピーしました</div>
              </div>
            </div>
          </div>
        </div>
        <div class="notify">
          <ul class="notify-list">
            <li>2と3については、次の「かかりつけ設定」を行ってから医療機関、薬局等が追加・修正することができます。</li>
          </ul>
        </div>
      </div>
      <div class="action check-mailaddress">
        <h3><a name="setrelation">医療機関・薬局等の施設と「かかりつけ設定」を行なう</a></h3>
        <div class="content">
          <div class="content-subtitle">【依頼状の送信】</div>
          メニューからかかりつけ設定画面に切り替えて、依頼状の送付を行います。
          <div class="parent-list-content">
            <div class="child-list-content">
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids5-1-1.png"/>
              </div>
            </div>
            <div class="child-list-content">
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids5-1-2.png"/>
              </div>
            </div>
            <div class="child-list-content side-content">
              <div class="figure-content side-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids5-1-3.png"/>
                <div class="side-content">
                  ユーザー側から施設に「かかりつけの依頼状」を送るには、ナラティブブックにおけるその施設の施設番号(<span class="notify-mark">※1</span>)を入力する必要があります。
                  <ul class="notify-list">
                    <li>1 かかりつけ設定する施設は管理団体に登録していることが必要です。</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="content-subtitle">【案内状の承認】</div>
          施設から「かかりつけの案内状」を送ってもらうことにより、受信したユーザーはかかりつけ画面でその案内状を承認することでかかりつけ設定が完了します。
          <div class="parent-list-content">
            <div class="child-list-content">
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids5-2-1.png"/>
              </div>
            </div>
            <div class="child-list-content">
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids5-2-2.png"/>
              </div>
            </div>
            <div class="child-list-content">
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids5-2-3.png"/>
              </div>
            </div>
            <div class="child-list-content">
              <div class="figure-content">
                <img class="smartphone-screen" src="/img/manualscreen/kids5-2-4.png"/>
              </div>
            </div>
          </div>
          <div class="boxed-content">
            アプリ同士での設定には、さらに簡単な方法があります。詳しくは下記の動画マニュアルをご覧ください。
            <ul class="normal-list">
              <li>利用者から施設管理者への「かかりつけ依頼」の送信<a href="https://youtu.be/z2OQi3QqnEA" target="_blank">(https://youtu.be/z2OQi3QqnEA)</a></li>
              <li>(施設からの「かかりつけ案内」) 利用者による承認<a href="https://youtu.be/xD99gKdW1rA" target="_blank">(https://youtu.be/xD99gKdW1rA)</a></li>
              <li>かんたんQRでの「かかりつけ登録」 (参考)<span class="notify-mark">※</span><a href="https://youtu.be/7UH4bwyZ7RI" target="_blank">(https://youtu.be/7UH4bwyZ7RI)</a></li>
            </ul>
          </div>
          <div class="notify">
            <ul class="notify-list">
              <li>動画マニュアルではスマートフォン画面で説明していますが、 PCの場合にも操作は同じです。</li>
              <li>かかりつけシリーズの動画マニュアルは全6本(一般ユーザー向けには3本)です。<br/>ただし、代理でかかりつけ設定を行う場合には、かんたんQRでの「かかりつけ登録」は利用できません。</li>
            </ul>
          </div>
        </div>
        <div class="action set-family">
          <h3><a name="setfamily">家族や親しいユーザーと「家族設定」 を行う</a></h3>
          <div class="content">
            「家族設定」は「かかりつけ設定」とほとんど同じです。相手が施設ではなく一般ユーザーだという違いがあるだけです。動画マニュアルのURLをまとめて掲載しておきますので、知人、親子などで利用者の輪を広げましょう。
            <ul class="normal-list">
              <li>「家族設定」かんたんQRで家族設定<a href="https://youtu.be/Ubl7q9edaZg" target="_blank">(https://youtu.be/Ubl7q9edaZg)</a></li>
              <li>「家族設定」NBIDかメールアドレスで依頼状を送信<a href="https://youtu.be/nBvLl2rAdI8" target="_blank">(https://youtu.be/nBvLl2rAdI8)</a></li>
              <li>「家族設定」依頼上の承認<a href="https://youtu.be/Hn2_5Z3vHd8" target="_blank">(https://youtu.be/Hn2_5Z3vHd8)</a></li>
            </ul>
          </div>
          <div class="notify">
            <ul class="notify-list">
              <li>対象児童の家族設定は代理画面の利用者リストのメニューから「家族設定」を選択して代理者が行います。</li>
              <li>代理で家族設定を行う場合には、代理対象の児童側でQRコードを表示することはできません。(読み取りでの登録は可能です)</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'KidsHowToStartContentBlock',

  data () {
    return {
      copiedContent: ''
    }
  },

  methods: {
    copyContent (content) {
      var copyText = `【保護者連絡先】
母携帯：
父携帯：
住  所：

【医療機関】
◯かかりつけ医（在宅管理）

TEL:

◯緊急連絡先
`
      if (content === 'devices') {
        copyText = `人工呼吸器の種類
設定

酸素投与量

気管カニューレの種類とサイズ
`
      } else if (content === 'medicine') {
        copyText = `ミルクや栄養剤の種類と注入量

注入時間と速度

食形態やアレルギー
`
      }
      navigator.clipboard.writeText(copyText)
      this.copiedContent = content
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
@import '~@/assets/css/narrative_akita_title_style.scss';
.kids-how-to-start {
  margin: 0;
  h2.title {
    color: $kids-main-color;
    span {
      display: inline-block;
    }
  }
  .lower-decoration {
    background-color: $kids-main-color;
  }
  h3 {
    margin: 10px 0;
    border-bottom: 2px solid $kids-main-color;
    color: $kids-main-color;
  }
  .content-index {
    ol.index-list {
      margin: 0;
      padding: 0;
      list-style-position: inside;
      li {
        color: #fff;
        margin: 3px 0;
        padding: 5px 10px;
        background-color: $kids-mid-main-color;
        font-weight: bold;
        a {
          display: inline-block;
          width: calc(100% - 60px);
          height: 100%;
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
  .action {
    margin-top: 30px;
    .content {
      .index-number {
        display: inline-block;
        margin-right: 5px;
        font-weight: bold;
        color: $kids-notify-color;
      }
      .inline-images {
        height: 1em;
      }
      .impotantly {
        font-weight: bold;
        .attention {
          padding: 0 5px;
          color: $kids-notify-color;
        }
      }
      ol.impotantly-list {
        margin: 0;
        padding: 0 50px;
        list-style-position: inside;
        li {
          color: #fff;
          margin: 3px 0;
          padding: 5px 10px;
          background-color: $kids-mid-main-color;
          font-weight: bold;
          a {
            display: inline-block;
            width: calc(100% - 60px);
            height: 100%;
            text-decoration: none;
            color: #fff;
          }
        }
      }
      .content-subtitle {
        margin-bottom: 5px;
        font-weight: bold;
      }
      .list-content {
        margin: 10px 0;
        font-size: 16px;
      }
      .parent-list-content {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        @include mediaQuery('phone') {
          flex-wrap: wrap;
        }
        &.left {
          justify-content: flex-start;
        }
        .child-list-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .figure-content {
        &.side-content {
          display: flex;
          .side-content {
            text-align: left;
            font-size: 14px;
          }
        }
        text-align: center;
        img {
          margin: 10px;
          margin-bottom: 0;
        }
        .figure-caption {
          text-align: center;
          font-size: 14px;
        }
      }
    }
    .boxed-notify {
      display: inline-block;
      padding: 10px 20px;
      border-radius: 10px;
      background-color: $kids-mid-main-color;
      color: #fff;
    }
    .boxed-content {
      padding: 0 20px;
      border: 3px solid #ddd;
      background-color: #fff;
      h4 {
        margin: 10px 0;
        color: #888;
        border-bottom: 2px solid $kids-main-color;
      }
    }
    .blocked-content {
      margin: 10px 0;
      padding: 10px 20px;
      background-color: #eee;
      h4 {
        margin: 0;
      }
      @include mediaQuery('phone') {
        img {
          width: 100%;
        }
      }
    }
    .additional-content {
      h4 {
        display: inline-block;
        margin: 10px;
        padding: 5px 20px;
        border-radius: 20px;
        background-color: $kids-main-color;
        color: #fff;
      }
      .parent-list-content {
        flex-wrap: wrap;
        .sample {
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
          .sample-title {
            color: $kids-main-color;
          }
          .copy-link {
            display: inline-block;
            padding: 5px 10px;
            border-radius: 15px;
            background-color: $kids-main-color;
            color: #fff;
            cursor: pointer;
          }
          .copied-label {
            font-size: 14px;
            color: $content-text-color;
          }
        }
      }
    }
    ul.notify-list {
      margin: 10px 0;
      padding: 0;
      color: $kids-notify-color;
      list-style: none;
      font-size: 14px;
      li {
        padding-left: 1.2em;
        padding-bottom: 5px;
        position: relative;
      }
      li::before {
        content: '\0203B';
        position: absolute;
        left: 0;
      }
    }
    ul.normal-list {
      list-style: none;
    }
  }
}
</style>