export const pageList = [
  {
    pageName: 'seminar20230218care',
    headerSource: `
    <div class="title-top" style="display:inline-block;margin: 10px;padding: 5px 10px;border: 1px solid #0e893b;background-color: #fff;color: #0e893b;font-weight: bold;font-size: 18px;">Youtubeで動画公開中</div>
      <h1 class="article-title" style="margin: 10px 20px;">
        <span class="pre-title" style="display:block;font-size: 20px;font-weight: bold;">みんなで考えませんか？</span>
        <span class="main-title" style="display:block;font-size: 40px;font-weight: bold;">人生会議と看取り</span>
        <span class="sub-title" style="display:block;font-size: 16px;font-weight: bold;">〜住み慣れた地域で迎える最後のとき〜</span>
      </h1>
      <div class="sub" style="margin: 0 20px; font-weight: bold;font-size: 15px;">職種が関わるアドバンスケアプランニング及び看取りの普及啓発事業</div>
      <div class="event-information" style="margin: 10px 20px;">
        <div class="date" style="margin-bottom: 10px;">
          <div class="index" style="display: inline-block;padding: 2px 10px;background-color:#4a4949;color:#fff;font-weight:bold;">日時</div>
          <div class="content" style="display:inline-block;margin-left: 20px;">2023年2月18日(土)</div>
        </div>
        <div class="date" style="margin-bottom: 10px;">
          <div class="index" style="display: inline-block;padding: 2px 10px;background-color:#4a4949;color:#fff;font-weight:bold;">場所</div>
          <div class="content" style="display:inline-block;margin-left: 20px;">秋田市にぎわい交流館AU(あう)</div>
        </div>
        <div class="date" style="margin-bottom: 10px;">
          <div class="index" style="display: inline-block;padding: 2px 10px;background-color:#4a4949;color:#fff;font-weight:bold;">主催</div>
          <div class="content" style="display:inline-block;margin-left: 20px;">一般社団法人秋田県医師会</div>
        </div>
        <div class="date" style="margin-bottom: 10px;">
          <div class="index" style="display: inline-block;padding: 2px 10px;background-color:#4a4949;color:#fff;font-weight:bold;">後援</div>
          <div class="content" style="display:block;font-size: 12px;">秋田県、一般社団法人秋田県歯科医師会、一般社団法人秋田県薬剤師会、公益社団法人秋田県看護師協会、秋田魁新報社、朝日新聞秋田総局、NHK秋田放送局、ABS秋田放送、AKT秋田テレビ、AAB秋田朝日放送</div>
        </div>
      </div>`,
    headerBackgroundImage: '/img/seminar20230218care_top.png',
    playlistTitle: "県民公開講演会”ナラティブブック秋田” R5/2/18",
    playlistURL: 'https://www.youtube.com/watch?v=UyOgj2VZC5A&list=PLbYxMSJzPemBpE6H5w_JVm5rjWOXRcGRs&pp=gAQBiAQB',
    playlistIndexes: [
      '開会の挨拶ナラティブブック秋田 委員長 西成 忍',
      '基調講演新潟大学 宮坂道夫氏',
      'パネルディスカッション① 小泉医院 小泉 亮氏',
      'パネルディスカッション② 池田薬局 菅井勝也氏',
      'パネルディスカッション③ JA秋田厚生連・雄勝訪問看護ステーション 坂田美穂氏',
      'パネルディスカッション④ 由利本荘市社協 大内居宅介護支援事業所 石垣妙子氏',
      'パネルディスカッション⑤ 櫻田美穂氏',
      '全体パネルディスカッション',
      '開会の挨拶 ナラティブブック秋田 副委員長 佐々木 範明',
    ],
    movieContents: [
      {
        thumbnail: '20230218_CNA_01.jpg',
        linkURL: 'https://youtu.be/UyOgj2VZC5A',
        title: '開会の挨拶[県民公開講演会”ナラティブブック秋田” R5/2/18 #1]ナラティブブック秋田 委員長 西成 忍',
        description: `みんなで考えませんか？
人生会議と看取り
県民公開講演会”ナラティブブック秋田”
令和5年2月18日(土) 会場秋田市にぎわい交流館AU

【開会の挨拶】
ナラティブブック秋田 委員長 西成 忍`,
        duration: '4:15'
      },
      {
        thumbnail: '20230218_CNA_02.jpg',
        linkURL: 'https://youtu.be/wL5pqxk9PQM',
        title: '基調講演[県民公開講演会”ナラティブブック秋田” R5/2/18 #2]新潟大学 宮坂道夫氏',
        description: `みんなで考えませんか？
人生会議と看取り
県民公開講演会”ナラティブブック秋田”
令和5年2月18日(土) 会場秋田市にぎわい交流館AU

【基調講演】
「人生会議と私の物語」

新潟大学 大学院保健研究科（医学部保健学科）教授 宮坂道夫氏`,
        duration: '1:03:41'
      },
      {
        thumbnail: '20230218_CNA_03.jpg',
        linkURL: 'https://youtu.be/7xm-BpnqzY0',
        title: 'パネルディスカッション①[県民公開講演会”ナラティブブック秋田” R5/2/18 #3]小泉医院 小泉 亮氏',
        description: `みんなで考えませんか？
人生会議と看取り
県民公開講演会”ナラティブブック秋田”
令和5年2月18日(土) 会場秋田市にぎわい交流館AU

【パネルディスカッション】
「みんながこれでいがったんだと思える最期のために」
尊厳ある最後のために 〜在宅看取りをチームで支える〜

小泉医院 院長 小泉 亮氏`,
        duration: '16:29'
      },
      {
        thumbnail: '20230218_CNA_04.jpg',
        linkURL: 'https://youtu.be/nNawnYUC2RY',
        title: 'パネルディスカッション②[県民公開講演会”ナラティブブック秋田” R5/2/18 #4]池田薬局 菅井勝也氏',
        description: `みんなで考えませんか？
人生会議と看取り
県民公開講演会”ナラティブブック秋田”
令和5年2月18日(土) 会場秋田市にぎわい交流館AU

【パネルディスカッション】
「みんながこれでいがったんだと思える最期のために」

池田薬局  薬剤師 菅井 勝也氏`,
        duration: '21:59'
      },
      {
        thumbnail: '20230218_CNA_05.jpg',
        linkURL: 'https://youtu.be/NgAI-wqxmgo',
        title: 'パネルディスカッション③[県民公開講演会”ナラティブブック秋田” R5/2/18 #5]JA秋田厚生連・雄勝訪問看護ステーション 坂田美穂氏',
        description: `みんなで考えませんか？
人生会議と看取り
県民公開講演会”ナラティブブック秋田”
令和5年2月18日(土) 会場秋田市にぎわい交流館AU

【パネルディスカッション】
「みんながこれでいがったんだと思える最期のために」

JA秋田厚生連・雄勝訪問看護ステーション 訪問看護師 坂田美穂氏`,
        duration: '17:36'
      },
      {
        thumbnail: '20230218_CNA_06.jpg',
        linkURL: 'https://youtu.be/ZJzhHsmjVYc',
        title: 'パネルディスカッション④[県民公開講演会”ナラティブブック秋田” R5/2/18 #6]由利本荘市社協 大内居宅介護支援事業所 石垣妙子氏',
        description: `みんなで考えませんか？
人生会議と看取り
県民公開講演会”ナラティブブック秋田”
令和5年2月18日(土) 会場秋田市にぎわい交流館AU

【パネルディスカッション】
「みんながこれでいがったんだと思える最期のために」
住み慣れた地域で迎える最後のとき 〜ケアマネージャーとしての関わりから学んだこと〜

由利本荘市社協 大内居宅介護支援事業所 介護支援専門員 石垣 妙子氏`,
        duration: '13:27'
      },
      {
        thumbnail: '20230218_CNA_07.jpg',
        linkURL: 'https://youtu.be/AfgThJ2BDvE',
        title: 'パネルディスカッション⑤[県民公開講演会”ナラティブブック秋田” R5/2/18 #7]櫻田美穂氏',
        description: `みんなで考えませんか？
人生会議と看取り
県民公開講演会”ナラティブブック秋田”
令和5年2月18日(土) 会場秋田市にぎわい交流館AU

【パネルディスカッション】
「みんながこれでいがったんだと思える最期のために」
ナラティブブック秋田に参加して 〜高齢ターミナル患者家族として〜

家族・患者 櫻田 美穂氏`,
        duration: '10:32'
      },
      {
        thumbnail: '20230218_CNA_08.jpg',
        linkURL: 'https://youtu.be/P4D1xJVmSaM',
        title: '全体パネルディスカッション[県民公開講演会”ナラティブブック秋田” R5/2/18 #8]',
        description: `みんなで考えませんか？
人生会議と看取り
県民公開講演会”ナラティブブック秋田”
令和5年2月18日(土) 会場秋田市にぎわい交流館AU

【パネルディスカッション】
「みんながこれでいがったんだと思える最期のために」
司会進行・座長 秋田県医師会 副会長 伊藤伸一
小泉医院 院長 小泉 亮氏
池田薬局  薬剤師 菅井 勝也氏
JA秋田厚生連・雄勝訪問看護ステーション 訪問看護師 坂田美穂氏
由利本荘市社協 大内居宅介護支援事業所 介護支援専門員 石垣 妙子氏
家族・患者 櫻田 美穂氏`,
        duration: '16:57'
      },
      {
        thumbnail: '20230218_CNA_09.jpg',
        linkURL: 'https://youtu.be/k4dRnGXbq1M',
        title: '開会の挨拶[県民公開講演会”ナラティブブック秋田” R5/2/18 #9]ナラティブブック秋田 副委員長 佐々木 範明',
        description: `みんなで考えませんか？
人生会議と看取り
県民公開講演会”ナラティブブック秋田”
令和5年2月18日(土) 会場秋田市にぎわい交流館AU

【閉会の挨拶】
ナラティブブック秋田 副委員長 佐々木 範明`,
        duration: '3:40'
      },
    ]
  },
  {
    pageName: 'seminar20230312kids',
    headerSource: `
    <div class="title-top" style="display:inline-block;margin: 10px;padding: 5px 10px;border: 1px solid #0e893b;background-color: #fff;color: #0e893b;font-weight: bold;font-size: 18px;">Youtubeで動画公開中</div>
    <h1 class="article-title" style="margin: 10px 20px;color: #fff;">
      <span class="pre-title" style="display:block;font-size: 20px;font-weight: bold;">キッズナラティブ秋田でつなぐ</span>
      <span class="main-title" style="display:block;font-size: 40px;font-weight: bold;">医療的ケア児支援</span>
    </h1>
    <div class="sub" style="margin: 0 20px; font-weight: bold;font-size: 15px;color: #fff;">令和四年度 キッズナラティブ秋田 県民公開講演会</div>
    <div class="event-information" style="margin: 10px 20px;color: #fff;">
      <div class="date" style="margin-bottom: 10px;">
        <div class="index" style="display: inline-block;padding: 2px 10px;background-color:#4a4949;color:#fff;font-weight:bold;">日時</div>
        <div class="content" style="display:inline-block;margin-left: 20px;">2023年3月12日(日)</div>
      </div>
      <div class="date" style="margin-bottom: 10px;">
        <div class="index" style="display: inline-block;padding: 2px 10px;background-color:#4a4949;color:#fff;font-weight:bold;">場所</div>
        <div class="content" style="display:inline-block;margin-left: 20px;">秋田県総合保険センター 2階 大会議室</div>
      </div>
      <div class="date" style="margin-bottom: 10px;">
        <div class="index" style="display: inline-block;padding: 2px 10px;background-color:#4a4949;color:#fff;font-weight:bold;">主催</div>
        <div class="content" style="display:inline-block;margin-left: 20px;">一般社団法人秋田県医師会</div>
      </div>
      <div class="date" style="margin-bottom: 10px;">
        <div class="index" style="display: inline-block;padding: 2px 10px;background-color:#4a4949;color:#fff;font-weight:bold;">後援</div>
        <div class="content" style="display:block;font-size: 12px;">秋田県、秋田県小児科医会、公益社団法人日本小児科学会秋田地方会、一般社団法人秋田県歯科医師会、一般社団法人秋田県薬剤師会、公益社団法人秋田県看護師協会、秋田魁新報社、朝日新聞秋田総局、読売新聞秋田支局、毎日新聞秋田支局、NHK秋田放送局、ABS秋田放送、AKT秋田テレビ、AAB秋田朝日放送</div>
      </div>
    </div>`,
  headerBackgroundImage: '/img/seminar20230312kids_top.png',
  playlistTitle: '2023/3/12 県民公開講演会”キッズナラティブ秋田”',
  playlistURL: 'https://www.youtube.com/watch?v=xxat443akDk&list=PLbYxMSJzPemDamgABbuVzVGwmnvdZSr7a&pp=gAQBiAQB',
  playlistIndexes: [
    '開会の挨拶 秋田県医師会 会長 小泉ひろみ',
    '基調講演 宮城県医療的ケア児等相談支援センター 遠山裕湖氏',
    '事業説明 秋田県医師会 副会長 伊藤伸一',
    'パネルディスカッション① 秋田県立医療教育センター 豊野美幸氏',
    'パネルディスカッション② 相談支援事業所 十人十色 佐々木望美氏',
    'パネルディスカッション③ ごてんまりＺ訪問看護ステーション 斉藤翔吾氏',
    'パネルディスカッション④ 秋田聖徳会多機能型支援センター第二聖和 八代雄市氏',
    'パネルディスカッション⑤ 平井 香氏',
    'パネルディスカッション⑥ パネルディスカッション 全体',
    '閉会の挨拶 キッズナラティブ秋田 委員 新井浩和'
  ],
  movieContents: [
    {
      thumbnail: '20230312_KNA_01.jpg',
      linkURL: 'https://youtu.be/xxat443akDk',
      title: '開会の挨拶[県民公開講演会”キッズナラティブ秋田” R5/3/12 #1]秋田県医師会 会長 小泉ひろみ',
      description: `キッズナラティブ秋田でつなぐ
医療的ケア児支援
県民公開講演会”キッズナラティブ秋田”
令和5年3月12日(日) 会場:秋田県総合保健センター2階

【開会の挨拶】
秋田県医師会 会長 小泉ひろみ`,
      duration: '3:05'
    },
    {
      thumbnail: '20230312_KNA_02.jpg',
      linkURL: 'https://youtu.be/c57JTEJFKfc',
      title: '基調講演[県民公開講演会”キッズナラティブ秋田” R5/3/12 #2]宮城県医療的ケア児等相談支援センター 遠山裕湖氏',
      description: `キッズナラティブ秋田でつなぐ
医療的ケア児支援
県民公開講演会”キッズナラティブ秋田”
令和5年3月12日(日) 会場:秋田県総合保健センター2階

【基調講演】
「医療的ケア児支援法施行から1年
宮城県医療的ケア児等相談支援センターの取り組みと課題」

宮城県医療的ケア児等相談支援センター センター長 遠山 裕湖氏`,
      duration: '1:06:38'
    },
    {
      thumbnail: '20230312_KNA_03.jpg',
      linkURL: 'https://youtu.be/No0b1S7Nl-c',
      title: '事業説明[県民公開講演会”キッズナラティブ秋田” R5/3/12 #3]秋田県医師会 副会長 伊藤伸一',
      description: `キッズナラティブ秋田でつなぐ
医療的ケア児支援
県民公開講演会”キッズナラティブ秋田”
令和5年3月12日(日) 会場:秋田県総合保健センター2階

【事業説明】
秋田県医師会 副会長 伊藤 伸一`,
      duration: '8:59'
    },
    {
      thumbnail: '20230312_KNA_04.jpg',
      linkURL: 'https://youtu.be/DUnjRJreztw',
      title: 'パネルディスカッション①[県民公開講演会”キッズナラティブ秋田” R5/3/12 #4]秋田県立医療教育センター 豊野美幸氏',
      description: `キッズナラティブ秋田でつなぐ
医療的ケア児支援
県民公開講演会”キッズナラティブ秋田”
令和5年3月12日(日) 会場:秋田県総合保健センター2階

【パネルディスカッション】
「キッズナラティブ秋田の取り組み報告」

秋田県立医療教育センター 豊野 美幸氏`,
      duration: '15:08'
    },
    {
      thumbnail: '20230312_KNA_05.jpg',
      linkURL: 'https://youtu.be/nHbcEfNJHH8',
      title: 'パネルディスカッション②[県民公開講演会”キッズナラティブ秋田” R5/3/12 #5]相談支援事業所 十人十色 佐々木望美氏',
      description: `キッズナラティブ秋田でつなぐ
医療的ケア児支援
県民公開講演会”キッズナラティブ秋田”
令和5年3月12日(日) 会場:秋田県総合保健センター2階

【パネルディスカッション】
「キッズナラティブ秋田の取り組み報告」
相談支援事業所 十人十色の取り組み

相談支援事業所 十人十色 佐々木 望美氏`,
      duration: '7:27'
    },
    {
      thumbnail: '20230312_KNA_06.jpg',
      linkURL: 'https://youtu.be/qWMNja_KmNo',
      title: 'パネルディスカッション③[県民公開講演会”キッズナラティブ秋田” R5/3/12 #6]ごてんまりＺ訪問看護ステーション 斉藤翔吾氏',
      description: `キッズナラティブ秋田でつなぐ
医療的ケア児支援
県民公開講演会”キッズナラティブ秋田”
令和5年3月12日(日) 会場:秋田県総合保健センター2階

【【パネルディスカッション】
「キッズナラティブ秋田の取り組み報告」
キッズナラティブ 訪問看護での使用状況について

ごてんまりＺ訪問看護ステーション 訪問看護師 斉藤 翔吾氏`,
      duration: '11:47'
    },
    {
      thumbnail: '20230312_KNA_07.jpg',
      linkURL: 'https://youtu.be/WGqj35tokTQ',
      title: 'パネルディスカッション④[県民公開講演会”キッズナラティブ秋田” R5/3/12 #7]秋田聖徳会多機能型支援センター第二聖和 八代雄市氏',
      description: `キッズナラティブ秋田でつなぐ
医療的ケア児支援
県民公開講演会”キッズナラティブ秋田”
令和5年3月12日(日) 会場:秋田県総合保健センター2階

【パネルディスカッション】
「キッズナラティブ秋田の取り組み報告」

秋田聖徳会多機能型支援センター第二聖和 通所事業所管理者 八代 雄市氏`,
      duration: '11:30'
    },
    {
      thumbnail: '20230312_KNA_08.jpg',
      linkURL: 'https://youtu.be/-mo2w-tpWTQ',
      title: 'パネルディスカッション⑤[県民公開講演会”キッズナラティブ秋田” R5/3/12 #8]平井 香氏',
      description: `キッズナラティブ秋田でつなぐ
医療的ケア児支援
県民公開講演会”キッズナラティブ秋田”
令和5年3月12日(日) 会場:秋田県総合保健センター2階

【パネルディス カッション】
「キッズナラティブ秋田の取り組み報告」
キッズナラティブ秋田について 医療ケア児家族として

医療ケア児・家族 平井 香氏`,
      duration: '17:17'
    },
    {
      thumbnail: '20230312_KNA_09.jpg',
      linkURL: 'https://youtu.be/OHbI0zPjOUM',
      title: 'パネルディスカッション⑥[県民公開講演会”キッズナラティブ秋田” R5/3/12 #9]パネルディスカッション 全体',
      description: `キッズナラティブ秋田でつなぐ
医療的ケア児支援
県民公開講演会”キッズナラティブ秋田”
令和5年3月12日(日) 会場:秋田県総合保健センター2階

【パネルディスカッション】
「キッズナラティブ秋田の取り組み報告」

秋田県立医療教育センター 豊野 美幸氏
相談支援事業所 十人十色 佐々木 望美氏
ごてんまりＺ訪問看護ステーション 訪問看護師 斉藤 翔吾氏
秋田聖徳会多機能型支援センター第二聖和 通所事業所管理者 八代 雄市氏
医療ケア児・家族 平井 香氏`,
      duration: '19:01'
    },
    {
      thumbnail: '20230312_KNA_10.jpg',
      linkURL: 'https://youtu.be/upUMYLF7TVM',
      title: '閉会の挨拶[県民公開講演会”キッズナラティブ秋田” R5/3/12 #10]キッズナラティブ秋田 委員 新井浩和',
      description: `キッズナラティブ秋田でつなぐ
医療的ケア児支援
県民公開講演会”キッズナラティブ秋田”
令和5年3月12日(日) 会場:秋田県総合保健センター2階

【開会の挨拶】
キッズナラティブ秋田 委員 新井 浩和`,
      duration: '2:53'
    }]
  },
  {
    pageName: 'seminar20230225online',
    headerSource: `
    <div class="title-top" style="display:inline-block;margin: 10px;padding: 5px 10px;border: 1px solid #0e893b;background-color: #fff;color: #0e893b;font-weight: bold;font-size: 18px;">Youtubeで動画公開中</div>
    <h1 class="article-title" style="margin: 10px 20px;color: #fff;">
      <span class="pre-title" style="display:block;font-size: 20px;font-weight: bold;">医療デジタル化推進事業成果報告会</span>
      <span class="main-title" style="display:block;font-size: 40px;font-weight: bold;">「令和4年度秋田県オンライン診療実証事業」に基づく報告会</span>
    </h1>
    <div class="event-information" style="margin: 10px 20px;color: #fff;">
      <div class="date" style="margin-bottom: 10px;">
        <div class="index" style="display: inline-block;padding: 2px 10px;background-color:#fff;color:#4a4949;font-weight:bold;">日時</div>
        <div class="content" style="display:inline-block;margin-left: 20px;">令和5年2月25日(土)</div>
      </div>
      <div class="date" style="margin-bottom: 10px;">
        <div class="index" style="display: inline-block;padding: 2px 10px;background-color:#fff;color:#4a4949;font-weight:bold;">場所</div>
        <div class="content" style="display:inline-block;margin-left: 20px;">秋田県総合保健センター2F 大会議室</div>
      </div>
    </div>`,
    headerBackgroundImage: '/img/seminar20230225online_top.png',
    playlistTitle: '医療デジタル化推進事業成果報告会 R5/2/25',
  playlistURL: 'https://www.youtube.com/watch?v=ydt8mAwul9w&list=PLbYxMSJzPemAadSXXZCYDCcc-8xU05gBX&pp=gAQBiAQB',
  playlistIndexes: [
    '開会の挨拶 秋田県オンライン診療実証事業 運営委員会 委員長 南谷佳弘',
    '講演 東京大学大学院薬学系研究科医薬政策学講座 特任研究員 岡崎光洋',
    '取組説明 秋田県医師会 副会長 伊藤伸一',
    'ワーキンググループ取組報告① 市立大森病院 院長 小野 剛',
    'ワーキンググループ取組報告② 小泉医院 院長 小泉 亮',
    'ワーキンググループ取組報告③ たむら船越クリニック 院長 田村広美',
    'ワーキンググループ取組報告④ 男鹿みなと市民病院 診療部長 関 啓二',
    'ワーキンググループ取組報告⑤ 佐藤病院 院長 佐藤麻美子',
    'ワーキンググループ取組報告⑥ 小野崎医院 院長 小野崎圭助',
    'ワーキンググループ取組報告⑦ 菅医院 院長 菅 康徳',
    'ワーキンググループ取組報告⑧ 森岳温泉病院 理事長 島田かおる',
    'ワーキンググループ取組報告⑨ 秋田大学医学部付属病院 病院長 南谷佳弘',
    '閉会の挨拶 秋田県オンライン診療実証事業 運営委員会 副委員長 太田原康成',
  ],
  movieContents: [
    {
      thumbnail: '20230225_OL_01.jpg',
      linkURL: 'https://youtu.be/ydt8mAwul9w',
      title: '開会の挨拶[医療デジタル化推進事業成果報告会 R5/2/25 #1]秋田県オンライン診療実証事業 運営委員会 委員長 南谷佳弘',
      description: `医療デジタル化推進事業成果報告会 
「令和4年度秋田県オンライン診療実証事業」に基づく報告会
令和5年2月25日(土) 会場:秋田県総合保健センター2F 大会議室

【開会の挨拶】
秋田県オンライン診療実証事業 運営委員会 委員長 南谷 佳弘`,
      duration: '2:09'
    },
    {
      thumbnail: '20230225_OL_02.jpg',
      linkURL: 'https://youtu.be/7Dh9K6KcnZE',
      title: '講演[医療デジタル化推進事業成果報告会 R5/2/25 #2]東京大学大学院薬学系研究科医薬政策学講座 特任研究員 岡崎光洋',
      description: `医療デジタル化推進事業成果報告会 
「令和4年度秋田県オンライン診療実証事業」に基づく報告会
令和5年2月25日(土) 会場:秋田県総合保健センター2F 大会議室

【講演】
「オンライン診療の普及に関わる最近の話題」

東京大学大学院薬学系研究科医薬政策学講座 特任研究員 岡崎 光洋 `,
      duration: '40:58:00'
    },
    {
      thumbnail: '20230225_OL_03.jpg',
      linkURL: 'https://youtu.be/Dt8uOctiXNk',
      title: '取組説明[医療デジタル化推進事業成果報告会 R5/2/25 #3]秋田県医師会 副会長 伊藤伸一',
      description: `医療デジタル化推進事業成果報告会 
「令和4年度秋田県オンライン診療実証事業」に基づく報告会
令和5年2月25日(土) 会場:秋田県総合保健センター2F 大会議室

【取組説明】
「オンライン診療事業"秋田県モデル"について」

秋田県医師会 副会長 伊藤伸一 `,
      duration: '12:48'
    },
    {
      thumbnail: '20230225_OL_04.jpg',
      linkURL: 'https://youtu.be/lth8ZYtkBrY',
      title: 'ワーキンググループ取組報告①[医療デジタル化推進事業成果報告会 R5/2/25 #4]市立大森病院 院長 小野 剛',
      description: `医療デジタル化推進事業成果報告会 
「令和4年度秋田県オンライン診療実証事業」に基づく報告会
令和5年2月25日(土) 会場:秋田県総合保健センター2F 大会議室

【ワーキンググループ取組報告①】
「秋田県オンライン診療実証事業取組報告」

市立大森病院 院長 小野 剛`,
      duration: '9:37'
    },
    {
      thumbnail: '20230225_OL_05.jpg',
      linkURL: 'https://youtu.be/9oFNaj7ik6k',
      title: 'ワーキンググループ取組報告②[医療デジタル化推進事業成果報告会 R5/2/25 #5]小泉医院 院長 小泉 亮',
      description: `医療デジタル化推進事業成果報告会 
「令和4年度秋田県オンライン診療実証事業」に基づく報告会
令和5年2月25日(土) 会場:秋田県総合保健センター2F 大会議室

【ワーキンググループ取組報告②】
「秋田県オンライン診療実証事業取組報告」

小泉医院 院長 小泉 亮`,
      duration: '3:55'
    },
    {
      thumbnail: '20230225_OL_06.jpg',
      linkURL: 'https://youtu.be/p2RH5P7ljS8',
      title: 'ワーキンググループ取組報告③[医療デジタル化推進事業成果報告会 R5/2/25 #6]たむら船越クリニック 院長 田村広美',
      description: `医療デジタル化推進事業成果報告会 
「令和4年度秋田県オンライン診療実証事業」に基づく報告会
令和5年2月25日(土) 会場:秋田県総合保健センター2F 大会議室

【ワーキンググループ取組報告③】
「秋田県オンライン診療実証事業取組報告」

たむら船越クリニック 院長 田村広美`,
      duration: '6:37'
    },
    {
      thumbnail: '20230225_OL_07.jpg',
      linkURL: 'https://youtu.be/Cw7F7Y_3-Ik',
      title: 'ワーキンググループ取組報告④[医療デジタル化推進事業成果報告会 R5/2/25 #7]男鹿みなと市民病院 診療部長 関 啓二',
      description: `医療デジタル化推進事業成果報告会 
「令和4年度秋田県オンライン診療実証事業」に基づく報告会
令和5年2月25日(土) 会場:秋田県総合保健センター2F 大会議室

【ワーキンググループ取組報告④】
「秋田県オンライン診療実証事業取組報告」

男鹿みなと市民病院 診療部長 関 啓二`,
      duration: '5:46'
    },
    {
      thumbnail: '20230225_OL_08.jpg',
      linkURL: 'https://youtu.be/9M4xbayd1x4',
      title: 'ワーキンググループ取組報告⑤[医療デジタル化推進事業成果報告会 R5/2/25 #8]佐藤病院 院長 佐藤麻美子',
      description: `医療デジタル化推進事業成果報告会 
「令和4年度秋田県オンライン診療実証事業」に基づく報告会
令和5年2月25日(土) 会場:秋田県総合保健センター2F 大会議室

【ワーキンググループ取組報告⑤】
「秋田県オンライン診療実証事業取組報告」

佐藤病院 院長 佐藤麻美子`,
      duration: '7:08'
    },
    {
      thumbnail: '20230225_OL_09.jpg',
      linkURL: 'https://youtu.be/c1xgKmB4E20',
      title: 'ワーキンググループ取組報告⑥[医療デジタル化推進事業成果報告会 R5/2/25 #9]小野崎医院 院長 小野崎圭助',
      description: `医療デジタル化推進事業成果報告会 
「令和4年度秋田県オンライン診療実証事業」に基づく報告会
令和5年2月25日(土) 会場:秋田県総合保健センター2F 大会議室

【ワーキンググループ取組報告⑥】
「秋田県オンライン診療実証事業取組報告」

小野崎医院 院長 小野崎圭助`,
      duration: '7:56'
    },
    {
      thumbnail: '20230225_OL_10.jpg',
      linkURL: 'https://youtu.be/oVaXl28KLUY',
      title: 'ワーキンググループ取組報告⑦[医療デジタル化推進事業成果報告会 R5/2/25 #10]菅医院 院長 菅 康徳',
      description: `医療デジタル化推進事業成果報告会 
「令和4年度秋田県オンライン診療実証事業」に基づく報告会
令和5年2月25日(土) 会場:秋田県総合保健センター2F 大会議室

【ワーキンググループ取組報告⑦】
「秋田県オンライン診療実証事業取組報告」

菅医院 院長 菅康徳`,
      duration: '8:45'
    },
    {
      thumbnail: '20230225_OL_11.jpg',
      linkURL: 'https://youtu.be/9iYVrxsCwLk',
      title: 'ワーキンググループ取組報告⑧[医療デジタル化推進事業成果報告会 R5/2/25 #11]森岳温泉病院 理事長 島田かおる',
      description: `医療デジタル化推進事業成果報告会 
「令和4年度秋田県オンライン診療実証事業」に基づく報告会
令和5年2月25日(土) 会場:秋田県総合保健センター2F 大会議室

【ワーキンググループ取組報告⑧】
「秋田県オンライン診療実証事業取組報告」

森岳温泉病院 理事長 島田かおる`,
      duration: '9:40'
    },
    {
      thumbnail: '20230225_OL_12.jpg',
      linkURL: 'https://youtu.be/zwrPATjhvsA',
      title: 'ワーキンググループ取組報告⑨[医療デジタル化推進事業成果報告会 R5/2/25 #12]秋田大学医学部付属病院 病院長 南谷佳弘',
      description: `医療デジタル化推進事業成果報告会 
「令和4年度秋田県オンライン診療実証事業」に基づく報告会
令和5年2月25日(土) 会場:秋田県総合保健センター2F 大会議室

【ワーキンググループ取組報告⑨】
「秋田県オンライン診療実証事業取組報告」

秋田大学医学部付属病院 病院長 南谷佳弘`,
      duration: '9:08'
    },
    {
      thumbnail: '20230225_OL_13.jpg',
      linkURL: 'https://youtu.be/PkpFKXBXW3U',
      title: '閉会の挨拶[医療デジタル化推進事業成果報告会 R5/2/25 #13]秋田県オンライン診療実証事業 運営委員会 副委員長 太田原康成',
      description: `医療デジタル化推進事業成果報告会 
「令和4年度秋田県オンライン診療実証事業」に基づく報告会
令和5年2月25日(土) 会場:秋田県総合保健センター2F 大会議室

【開会の挨拶】
秋田県オンライン診療実証事業 運営委員会 副委員長 太田原康成`,
      duration: '2:36'
    }]
  }
]