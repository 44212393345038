<template>
  <main>
    <article>
      <section class="title">
        <h1>SERVICE</h1>
        <h2>COCOのサービス</h2>
        <nav class="inpage-navigation">
          <ul>
            <li><a href="#service-narrative">ナラティブブック秋田</a></li>
            <li><a href="#service-map">COCOサポートMAP</a></li>
            <li><router-link to="jinzai">外国人材の紹介</router-link></li>
          </ul>
        </nav>
      </section>
      <section class="service-narrative left-content" id="service-narrative">
        <div class="content-box">
          <h2>NARRATIVEBOOK AKITA</h2>
          <h3>ナラティブブック秋田</h3>
          <div class="splitter-accent">&nbsp;</div>
          <div class="text-content">
            <p>
              ナラティブブック秋田は、情報共有ツールである「ナラティブブック」を利用した、地域に暮らす人々と、それを見守る人をつなぐ、情報共有の仕組みです。<br/>
              情報共有ツール「ナラティブブック」は、利用する当事者本人の情報を、本人管理のもと、インターネットを用いて本人、家族、医療介護従事者の間で共有する事ができます。
            </p>
            <router-link to="narrativebook_akita">詳しくはこちら</router-link>
          </div>
          <a class="back-to-top" href="#navigation-header">TOPへ戻る</a>
        </div>
      </section>
      <section class="service-map right-content" id="service-map">
        <div class="content-box">
          <h2>COCO SUPPORT MAP</h2>
          <h3>COCOサポートMAP</h3>
          <div class="splitter-accent">&nbsp;</div>
          <div class="text-content">
            <p>
              COCOサポートMAPは医療介護資源の検索を皮切りとして、様々な医療介護資源に関する情報を地図を通して提供するサービスです。
            </p>
            <ul>
              <li>秋田県内の医療介護資源検索</li>
              <li>ナラティブブック秋田参加施設検索</li>
              <li>秋田県内の災害時における医療介護資源の情報提供</li>
            </ul>
          </div>
          <a class="back-to-top" href="#navigation-header">TOPへ戻る</a>
        </div>
      </section>
      <section class="recruitment left-content" id="recruitement">
        <div class="content-box">
          <h2>SUPPORT FOR FREIGN RECRUITMENT</h2>
          <h3>外国人材の紹介</h3>
          <div class="splitter-accent">&nbsp;</div>
          <div class="text-content">
            <p>
              優秀な人材を見つけ、確保することはこれまで以上に難しくなっています。<br/>
              地域医療介護連携推進機構は、医療機関、福祉・介護施設と質の高い人材との架け橋となり、経歴やスキルに沿った最適な仕事をご紹介しています。<br/>
            </p>
            <p>外国人採用３つのメリット</p>
            <ol>
              <li>若い労働力が確保できる</li>
              <li>地方の職場でも採用しやすい</li>
              <li>離職率が低い</li>
            </ol>
            <p>秋田県、東北地域の医療機関、福祉・介護施設で若い労働力を確保するために、ぜひ弊社にお問い合わせください。</p>
          </div>
          <router-link to="jinzai">詳しくはこちら</router-link><br/>
          <a class="back-to-top" href="#navigation-header">TOPへ戻る</a>
        </div>
      </section>
      <section class="service-channel right-content" id="service-channel">
        <div class="content-box">
          <h2>COCO CHANNEL</h2>
          <h3>COCOチャンネル</h3>
          <div class="splitter-accent">&nbsp;</div>
          <div class="text-content">
            <p>
              COCOチャンネルは、各種セミナーなどの動画コンテンツを提供するサービスです。<br/>
              ユーザーの属性/職種に合わせたコンテンツの提供とおすすめをセレクトして表示します。<br/>
              また、特定の会員のみに提供するコンテンツのコントロールや管理を行うこともできます。
            </p>
          </div>
          <a class="back-to-top" href="#navigation-header">TOPへ戻る</a>
        </div>
      </section>
      <inside-footer/>
    </article>
  </main>
</template>

<script>
import InsideFooter from '@/components/CocoCommon/InsideFooter.vue'

export default {
  name: 'ServiceContent',

components: {
  InsideFooter
},

}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

section.service-narrative {
  background-image: url('~@/assets/css/images/nb_akita_hero_bg.png');
  @include mediaQuery('phone') {
    background-image: none;
  }
}

section.service-map {
  background-image: url('~@/assets/css/images/about_mission_bg.png');
  @include mediaQuery('phone') {
    background-image: none;
  }
}

section.recruitment {
  .content-box {
    height: auto;
  }
}

section.service-channel {
  background-image: url('~@/assets/css/images/about_detail_bg.png');
  @include mediaQuery('phone') {
    background-image: none;
  }
}
</style>
