<template>
  <div class="youtube-playlists" :class="category">
    <div class="youtube-playlist" v-for="playlist in youtubePlaylists" :key="playlist.playListID">
      <div class="playlist-info">
        <div class="title">
          <span class="title-name">{{ playlist.title }}</span>
          <a class="to-youtube" target="_blank" :href="playlist.url">Youtubeで視聴<span class="video-jump-mark">▶</span></a>
        </div>
        <div class="content">
          <div class="embeded-movie">
            <a :href="playlist.url" target="_blank">
              <img class="movie-thumbnail" :src="youtubeThumbnailSrc(playlist)" />
            </a>
          </div>
          <div class="description" v-html="wrapedText(playlist.description)"></div>
        </div>
      </div>
      <div class="movie-title" @click="toggleMovieList(playlist.playListID)">
        <span class="open-close" :class="{ open: movieListOpen(playlist.playListID) }">▶</span>
        <span class="open-close-title">プレイリストに含まれる動画</span>
      </div>
      <div class="movie-list" :class="{ open: movieListOpen(playlist.playListID) }">
        <div class="movie-info" v-for="(movie, mvIndex) in playlistMovies(playlist)" :key="playlist.playListID + 'mv' + mvIndex">
          <div class="title">
            <span class="title-text">{{ movie.title }}</span>
            <span class="duration">({{ movie.duration }})</span>
            <a class="to-youtube" target="_blank" :href="movieURL(movie)">Youtubeで視聴<span class="video-jump-mark">▶</span></a>
          </div>
          <div class="content">
            <div class="embeded-movie">
              <a :href="movieURL(movie)" target="_blank">
                <img class="movie-thumbnail" :src="youtubeThumbnailSrc(movie)" />
              </a>
            </div>
            <div class="description" v-html="wrapedText(movie.description)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YoutubeContentList',

  props: {
    youtubePlaylists: Array,
    youtubeMovies: Array,
    category: String
  },

  data () {
    return {
      openMovieLists: [],
    }
  },

  methods: {
    playlistMovies (playlist) {
      return this.youtubeMovies.filter(movie => { return (movie.playListID === playlist.playListID) })
    },

    wrapedText (text) {
      return text.replace(/\n/gi, '<br />')
    },

    youtubeThumbnailSrc(content) {
      return '/img/youtube_thumbnails/' + content.thumbnailFileName
    },

    movieURL (content) {
      return 'https://youtu.be/' + content.contentID
    },

    embedMovieHTML (content) {
      return '<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' + content.contentID + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
    },

    movieListOpen (playListIndex) {
      if (this.openMovieLists.indexOf(playListIndex) < 0) {
        return false
      } else {
        return true
      }
    },

    toggleMovieList (playListIndex) {
      var index = this.openMovieLists.indexOf(playListIndex)
      if (index < 0) {
        this.openMovieLists.push(playListIndex)
      } else {
        this.openMovieLists.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
.youtube-playlists {
  .playlist-info {
    .title {
      font-size: 16px;
      .title-name {
        display: inline-block;
        transform: scale(1, 1.5);
        letter-spacing: 0.2em;
        line-height: 18px;
      }
      a.to-youtube {
        margin-left: 10px;
        margin-top: 10px;
        display: inline-flex;
        align-items: center;
        color: $care-text-color;
        text-decoration: none;
        font-size: 14px;
        .video-jump-mark {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
          width: 20px;
          height: 15px;
          font-size: 10px;
          text-align: center;
          border-radius: 2px;
          background-color: $content-text-color;
          color: #fff;
        }
      }
    }
    .content {
      display: flex;
      margin: 10px 0;
      @include mediaQuery('phone') {
        flex-wrap: wrap;
      }
      .embeded-movie {
        // flex-grow: 1;
        height: 135px;
        width: 240px;
        border: 1px solid #ddd;
      }
      .description {
        width: calc(100% - 240px - 20px);
        padding-left: 20px;
        font-size: 14px;
        color: $care-text-color;
        @include mediaQuery('phone') {
          width: 100%;
          padding-left: 0;
        }
      }
    }
  }
  .movie-title {
    margin: 10px 0 20px;
    color: $care-text-color;
    border-bottom: 1px solid $care-text-color;
    cursor: pointer;
    @include mediaQuery('phone') {
      margin-bottom: 40px;
    }
    .open-close {
      display: inline-block;
      color: $care-text-color;
      transition: 0.5s;
      &.open {
        transform: rotate(90deg);
      }
    }
    .open-close-title {
      padding-left: 10px;
    }
  }
  .movie-list {
    display: none;
    margin: 10px 0 20px;
    padding: 10px;
    border: 1px solid $care-text-color;
    color: $care-text-color;
    background-color: #fff;
    &.open {
      display: block;
    }
    .movie-info {
      .title {
        margin: 10px 0;
        .title-text {
          display: inline-block;
          transform: scale(1, 1.5);
          font-size: 14px;
          letter-spacing: 0.2em;
          line-height: 16px;
        }
        .duration {
          display: inline-block;
        }
        a.to-youtube {
          margin-left: 10px;
          margin-top: 10px;
          display: inline-flex;
          align-items: center;
          color: $care-text-color;
          text-decoration: none;
          font-size: 14px;
          .video-jump-mark {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            width: 20px;
            height: 15px;
            font-size: 10px;
            text-align: center;
            border-radius: 2px;
            background-color: $content-text-color;
            color: #fff;
          }
        }
      }
      .content {
        display: flex;
        margin: 10px 0 20px;
        border-bottom: 1px solid #ddd;
        @include mediaQuery('phone') {
          flex-wrap: wrap;
          margin-bottom: 30px;
        }
        .embeded-movie {
          height: 135px;
          width: 240px;
          border: 1px solid #ddd;
        }
        .description {
          padding-left: 20px;
          width: calc(100% - 240px - 20px);
          font-size: 14px;
          color: $care-text-color;
          @include mediaQuery('phone') {
            width: 100%;
            padding-left: 0;
          }
        }
      }
    }
  }
  &.care {
    .playlist-info {
      .title {
        a.to-youtube {
          color: $care-text-color;
          .video-jump-mark {
            background-color: $care-main-color;
          }
        }
      }
      .content {
        .description {
          color: $care-text-color;
        }
      }
    }
    .movie-title {
      color: $care-text-color;
      border-bottom: 1px solid $care-text-color;
      .open-close {
        color: $care-text-color;
      }
    }
    .movie-list {
      border: 1px solid $care-text-color;
      color: $care-text-color;
      .movie-info {
        .title {
          a.to-youtube {
            color: $care-text-color;
            .video-jump-mark {
              background-color: $care-main-color;
            }
          }
        }
        .content {
          .description {
            color: $care-text-color;
          }
        }
      }
    }
  }
  &.kids {
    .playlist-info {
      .title {
        a.to-youtube {
          color: $kids-text-color;
          .video-jump-mark {
            background-color: $kids-main-color;
          }
        }
      }
      .content {
        .description {
          color: $kids-text-color;
        }
      }
    }
    .movie-title {
      color: $kids-text-color;
      border-bottom: 1px solid $kids-text-color;
      .open-close {
        color: $kids-text-color;
      }
    }
    .movie-list {
      border: 1px solid $kids-text-color;
      color: $kids-text-color;
      .movie-info {
        .title {
          a.to-youtube {
            color: $kids-text-color;
            .video-jump-mark {
              background-color: $kids-main-color;
            }
          }
        }
        .content {
          .description {
            color: $kids-text-color;
          }
        }
      }
    }
  }
}
</style>
