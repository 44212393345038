export const csvData = `【能代山本支部】,,,,
病院,能代厚生医療センター,0185-52-3111,能代市落合字上前田知内,https://maps.app.goo.gl/H6tSmoNTjMYJ83sA9
,能代山本医師会病院,0185-58-3311,能代市桧山字新田沢105-11,https://maps.app.goo.gl/e1F9g5JiY8jpP8N69
,,,,
診療所,淡路医院,0185-52-5015,能代市大手町7-4,https://maps.app.goo.gl/PYThzrh2KwK6MYJr7
,加賀医院,0185-54-8080,能代市川反町1番26号,https://goo.gl/maps/kfWVfGbiDqQmCnJw9
,金田医院,0185-73-2511,能代市二ツ井町比井野94,https://maps.app.goo.gl/DxrEURmYp6hvJVGKA
,木村医院,0185-52-2500,能代市彩霞長根22-9,https://maps.app.goo.gl/meEaeULPvjts6Uyw6
,小泉医院,0185-52-2427,能代市富町8-12,https://maps.app.goo.gl/eBwMQYtha6ySgDb5A
,能代南内科内視鏡クリニック,0185-54-9011,能代市寿域長根55番地の30,https://maps.app.goo.gl/dLXPiQwEbPeMh7DV9
,山須田医院,0185-54-3120,能代市花園町10番1号,https://maps.app.goo.gl/UZTwT2mQGJERp9ft6
,楊整形外科医院,0185-89-7771,能代市栄町16-8,https://maps.app.goo.gl/b5SFq7FheGvxGyCR6
,わたなべ整形外科,0185-52-8881,能代市落合中前田207,https://maps.app.goo.gl/N81o16a6aayNNC6u9
,,,,
歯科診療所,みなみ歯科,0185-52-8117,能代市河戸川字大須賀53-3,https://maps.app.goo.gl/aETx4xUtzSvZKfKk6
,,,,
薬局関連施設,赤玉薬局 落合店,0185-89-1199,能代市落合字上悪土166,https://maps.app.goo.gl/QW3AJgXFMAF33dWm6
,赤玉店薬局 八竜店,0185-85-4881,山本郡三種町浜田字東浜田139-2,https://maps.app.goo.gl/PJuXUNufq4SthYSC6
,安濃大年堂薬局,0185-52-4261,能代市万町３－１３,https://goo.gl/maps/3ncnzUQ31NMRLJbp7
,（一社）秋田県薬剤師会 会営薬局のしろ,0185-55-0990,能代市緑町2-20,https://maps.app.goo.gl/FHDqop9QjQRErv576
,河畔薬局,0185-52-3996,能代市万町6番10号,https://maps.app.goo.gl/oJWLYbn8FVAxQ1e96
,共創未来 能代薬局,0185-52-8711,能代市落合字上悪土161,https://goo.gl/maps/iB9CEB5qYGhWyQNK8
,佐野薬局 花園店,0185-89-2525,能代市中和一丁目17-21,https://maps.app.goo.gl/6Qw8aXzSycGSS91RA
,塚本薬局,0185-52-5144,能代市富町１０番８号,https://maps.app.goo.gl/3Qqmn6gXsuAZAQhN9
,ひがし薬局,0185-58-3878,能代市機織轌ノ目72-15,https://maps.app.goo.gl/svvid3cpMocdtK9W6
,ぽんぽこ薬局,0185-89-2313,能代市下瀬32-5,https://goo.gl/maps/xBW42Svohu59qYwdA
,皆川薬局,0185-76-2052,山本郡八峰町峰浜水沢字稲荷堂後120-6,https://goo.gl/maps/ui9L3aCUUdpx38vQA
,やなぎ薬局,0185-54-6507,能代市景林町９−２３,https://goo.gl/maps/ggFr4Y2zmLvntaWs5
,きく薬局,0185-52-0345,能代市花園町26番18号,https://maps.app.goo.gl/5mLMy36Qnqc766oB6
,ライナス薬局,0185-70-4160,山本郡八峰町八森字古屋敷13-6,https://goo.gl/maps/j1amDCBhKjquBkm2A
,,,,
看護関連施設,JCHO秋田病院附属訪問看護ステーション,0185-52-1460,能代市緑町5番22号,https://maps.app.goo.gl/nn8DiiEqoHhnweGy9
,けやき訪問看護ステーション,050-8880-0569,能代市長崎25-5 コーポキモト102号室,https://maps.app.goo.gl/XUgm3Tvfqgq4bGq56
,能代山本訪問看護ステーション,0185-58-3892,能代市檜山字新田沢105番地,https://maps.app.goo.gl/3hXSaWKWFsM6jszY8
,訪問看護ステーション松峰園,0185-88-8416,能代市落合字落合72-1,https://maps.app.goo.gl/ULgnbHXidcS6A1Ep8
,訪問看護リハビリステーションわかば,0185-74-6163,能代市河戸川字上大須賀2番地1,https://maps.app.goo.gl/x8bmqVGqj2rcw49e6
,,,,
福祉・介護関連施設,厚生連山本居宅,0185-55-1613,能代市undefined,https://goo.gl/maps/mo93PUaLBdh2C8Jp9
,居宅介護支援事業所 よりそい,0185-54-6292,能代市落合字下谷地２７１番地 ,https://goo.gl/maps/aiCkd4igd4uYbTVs9
,居宅介護支援センター もりたけ,0185-72-4560,山本郡三種町森岳字木戸沢100-47,https://goo.gl/maps/xdfW5sDh8QRau7mD9
,ケアサポートオフィスやどり木,090-5353-8260,能代市向能代字上野215-2,https://goo.gl/maps/notTVq8AJbofX5WP6
,ケアセンターほほえみ,0185-72-4343,山本郡三種町森岳字木戸沢115-16,https://goo.gl/maps/nh4oKZeAv8jEhDxs6
,ケアプランしらかみ,0185-74-5300,能代市落合字古悪土1-217,https://goo.gl/maps/68j55W5o8bce5Su28
,ケアプランセンターつむぎ,0185-74-6572,能代市字町後31番地1,https://goo.gl/maps/RsnYNPrPkjpYhdeQ7
,けやき居宅,050-8880-0569,能代市長崎25番地5 コーポキモト102号,https://maps.app.goo.gl/NZYa4HbsbNHidFTE7
,JAあきた白神居宅介護支援事業所,0185-58-5811,能代市鰄渕字古屋布４３番地１,https://maps.app.goo.gl/UeAo9Lzv99xpRoEs5
,ショートステイ輪,0185-72-0005,能代市二ツ井町切石字竜毛沢17-2,https://maps.app.goo.gl/yGG3etn4Vcxrj9MS9
,ショートステイはまなす温泉,0185-89-5221,能代市落合字亀谷地１番地８４,https://goo.gl/maps/dsMbCYh8BiFYm2n97
,親孝の里居宅介護支援事業所,0185-55-3073,能代市南元町4番69号,https://maps.app.goo.gl/TyKXjGLpKPxLhYpv9
,スマイルケアセンター,0185-89-5770,能代市落合字下谷地165,https://maps.app.goo.gl/eA2mYsU6pR9XX2JF9
,塚本薬局居宅介護支援事業所,0185-52-5144,能代市富町１０番８号,https://goo.gl/maps/v8FkMxXxF6Eb5WdK7
,デイサービス いなほの里,0185-58-5822,能代市鰄渕字古屋布43番地1,https://goo.gl/maps/8K6iysgxXDB9u1g2A
,特養もりたけ,0185-72-4555,山本郡三種町森岳字木戸沢100-47,https://maps.app.goo.gl/HaHNyV2HbHmV14eg8
,虹の街 能代営業所,0185-89-1212,能代市河戸川字下大須賀40番,https://goo.gl/maps/dbn2Ry5vMGhwvFnD8
,能代社協居宅,0185-89-2388,能代市上町12-32,https://maps.app.goo.gl/bgT987A8dLbomcjZ9
,八峰町社会福祉協議会 居宅介護支援事業所,0185-74-5623,山本郡八峰町八森字椿台112,https://goo.gl/maps/r2kRz9hb4oUa88HD8
,ひまわりハートケア居宅介護支援事業所,0185-89-2550,能代市花園25番7号,https://goo.gl/maps/CW3wfDPq9wCbYo5f8
,ひまわり訪問介護事業所,0185-89-2550,能代市花園町25番7号,https://goo.gl/maps/BS5ud9WcCMWvEXab7
,松波苑居宅介護支援事業所,0185-76-3018,山本郡八峰町峰浜水沢字下カッチキ台４１番地１４ ,https://maps.app.goo.gl/wkZd3DQ7Czvjr9CL9
,みずさわきょたく,0185-76-2548,山本郡八峰町峰浜水沢字水沢155-3 ,https://maps.app.goo.gl/M4biGp5hN7Tva8NA7
,実り居宅介護支援事業所,0185-74-5775,能代市向能代字能代上野越５３番地,https://maps.app.goo.gl/mgadjbFiPyJ3USAJ8
,ゆかりの森居宅介護支援センター,0185-55-0830,能代市能代町字中川原25-8,https://goo.gl/maps/NW2Fv55ZnfYcu3mZ8
,,,,
公共機関,八峰町地域包括支援センター,0185-77-3200,山本郡八峰町八森字椿台112番地,https://goo.gl/maps/FMk9beRGkVVFNCwc6
,藤里町地域包括支援センター,0185-79-2848,山本郡藤里町藤琴字三ツ谷脇40,https://goo.gl/maps/U1T4YZRaEVAHiBZn9
,,,,
その他の事業所,能代市山本郡医師会,0185-58-2581,能代市檜山字新田沢105,https://goo.gl/maps/wFGLdU41Xt47ReqV9
,株式会社かんきょう能代支店,0185-89-2070,能代市河戸川字大須賀1番地2,https://maps.app.goo.gl/1zBZHBJ23FatHv346
,,,,
【男鹿潟上南秋支部】,,,,
病院,湖東厚生病院,0187-875-2100,南秋田郡八郎潟町川崎字貝保98番16号,https://maps.app.goo.gl/FCES9c3eVqWpdLRdA
,男鹿みなと市民病院 訪問看護センター,0185-27-8131,男鹿市船川港船川字海岸通り1号8番地6,https://maps.app.goo.gl/ECqdRXrfuaZ48Hue8
,,,,
診療所,たむら船越クリニック,0185-22-6789,男鹿市undefined,https://goo.gl/maps/KLZUuJ2HWex41csW9
,男鹿加藤診療所,0185-22-2001,男鹿市脇本脇本字下谷地３９番地１,https://maps.app.goo.gl/aQRaxafHnxtR728v9
,佐々木医院,018-877-2355,潟上市昭和大久保字堤の上91番地,https://goo.gl/maps/6jquCBk8Y8LobSV2A
,,,,
薬局関連施設,池田薬局 ことう店,0184-23-3443,南秋田郡八郎潟町川崎字貝保37-6 ,https://goo.gl/maps/SxDtdRnijEYXczj86
,えきまえ 佐藤薬局,018-854-4466,南秋田郡八郎潟町字中田67-21,https://maps.app.goo.gl/Q5aTKBh3H2Ah2xk27
,佐藤薬局,018-875-2707,南秋田郡八郎潟町字一日市366-2,https://maps.app.goo.gl/5MEaLB4rfYuTwVo68
,南秋調剤薬局,018-854-8500,潟上市昭和大久保字街道下96番地10,https://goo.gl/maps/1xmXpGuDH7ksgy939
,,,,
看護関連施設,五城目訪問看護ステーション,018-852-5192,南秋田郡五城目町西磯ノ目一丁目6－10 ,https://goo.gl/maps/bWWsu8zyhwKqdZhu5
,,,,
福祉・介護関連施設,飯田川居宅介護支援センターわかば園,018-877-7077,潟上市飯田川下虻川字上谷地168-1,https://goo.gl/maps/r6qT2fg8m4p2gj6q6
,Ohana ケアセンター,018-874-8644,潟上市天王字長沼20グレプションA103,https://maps.app.goo.gl/ur8atRKxwo5EcJRQA
,南秋つくし苑,018-875-2115,南秋田郡八郎潟町字中嶋282-1,https://maps.app.goo.gl/hFeLb1Mb9Be9b1j28
,,,,
【由利本荘支部】,,,,
病院,医療法人圭尚会 金病院,0184-43-5522,にかほ市象潟町字後田34の1,https://goo.gl/maps/d88qZT5TNNFkbipP9
,医療法人佐藤病院,0184-22-6555,由利本荘市小人町１１７－３,https://goo.gl/maps/w6gFE1RpLwjPMtXe6
,NHOあきた病院,0184-73-2002,由利本荘市岩城内道川字井戸ノ沢８４ー４０,https://goo.gl/maps/CorG15xUAVHucyvK7
,本荘第一病院,0184-22-0111,由利本荘市岩渕下110,https://goo.gl/maps/UaJWptXTvUd5hU8J7
,由利組合総合病院,0184-27-1200,由利本荘市川口字家後３８番地,https://goo.gl/maps/VLGQgbffRKUVzR3y7
,由利本荘医師会病院（リハビリテーション科,0184-22-0054,由利本荘市水林456番地4,https://goo.gl/maps/BG17XAj7uLo2MDAf7
,由利本荘医師会病院,0184-22-0054,由利本荘市水林４５６－４,https://goo.gl/maps/BG17XAj7uLo2MDAf7
,,,,
診療所,あきた森の保健室,080-5741-8620,由利本荘市中田代字板井沢 １１４－７,https://goo.gl/maps/BnRqY13NCMhuFpgB7
,伊藤医院,0184-67-2321,由利本荘市中田代字板井沢１１４－７,https://goo.gl/maps/mt3QNZ39L4PessFW9
,佐藤医院,0184-56-2065,由利本荘市矢島町七日町字曲り渕 １２８－１,https://maps.app.goo.gl/RBNkdntXpsVJuipN9
,医療法人 渡邊医院,0184-53-3611,由利本荘市前郷字家岸７９番地２０,https://goo.gl/maps/ds7syfewVUf7awWy6
,作左部医院,0184-22-0314,由利本荘市石脇字石脇46,https://maps.app.goo.gl/aPkoxY3xAi2jcBL18
,加賀医院,0184-57-3456,由利本荘市鳥海町伏見字久保12-9,https://goo.gl/maps/kfWVfGbiDqQmCnJw9
,奥山医院,0184-22-5031,由利本荘市花畑町２丁目５７－２,https://goo.gl/maps/kJZ1Jmq8NEtcq9fK9
,風平診療所,0184-73-3333,由利本荘市由利本荘市岩城内道川字馬道44-7,https://goo.gl/maps/Ur8RPSmQdqB5YvHk6
,川上クリニック,0184-74-7867,由利本荘市桜小路38ｰ7,https://maps.app.goo.gl/zy2Vq7DAuZcEaMks9
,にかほ市国保院内診療所,0184-43-3200,にかほ市院内字〆カケ７の１,https://goo.gl/maps/EVSBqH1tScQGBqng7
,にかほ市国保小出診療所,0184-36-2124,にかほ市中三地字前田１４の１,https://goo.gl/maps/v5v8qSDYShWjaLUw6
,本荘ひがしクリニック,0184-74-7032,本荘市荒町字真城67-1,https://maps.app.goo.gl/ZYHMj4f3gAR8TZPR8
,松ヶ崎診療所,0184-28-2040,由利本荘市神沢字浜辺１１１－１,https://goo.gl/maps/rMcijZFWDzTxuGAB9
,わかまつ内科クリニック,0184-22-7521,由利本荘市東梵天297-1,https://goo.gl/maps/e8StVavs5QTj48UZ9
,,,,
歯科診療所,あいば歯科,0184-33-4300,由利本荘市西目町沼田字弁天前４０－３１７,https://goo.gl/maps/Gj3RHxqQ5KncG8vM9
,武田歯科医院,0184-22-5208,由利本荘市桶屋町124,https://goo.gl/maps/ixk5sJ1zm4KpyioE7
,,,,
薬局関連施設,池田薬局 すみれ調剤店,0184-62-3077,由利本荘市中田代字板井沢１１２－８,https://goo.gl/maps/QELovgtvq53t8p6B6
,池田薬局 在宅支援室,0184-22-0611,由利本荘市川口字家後150-1 マカベ調剤店内２Ｆ,https://maps.app.goo.gl/ZSADLHPksQfYUJzY6
,池田薬局 石脇調剤店,0184-24-2478,由利本荘市石脇字石脇４５,https://goo.gl/maps/csBd5tZ2Fi4KpJhT8
,池田薬局 開光堂店,0184-24-2381,由利本荘市裏尾崎町４−８,https://goo.gl/maps/7Pdxc68qHuJqUfZc9
,池田薬局 かわぐち店,0184-23-7071,由利本荘市川口字八幡前２６１,https://goo.gl/maps/HMTmM1A3AKDbbHNeA
,池田薬局 きさかた店,0184-24-2478,にかほ市象潟町字家ノ後６５－２６,https://goo.gl/maps/Rvf1JAw8B297KDs76
,池田薬局 大門店,0184-24-2478,由利本荘市給人町７－３,https://goo.gl/maps/ZeQMfhr76p4PZTcz8
,池田薬局 たで沼店,0184-44-8075,由利本荘市一番堰１１７－１４,https://goo.gl/maps/ALG5285gXvNe3gaX8
,池田薬局 中央店,0184-25-8067,由利本荘市岩渕下１０８－２,https://goo.gl/maps/cDVn1kVSxnSTkxfo6
,池田薬局 つるまい店,0184-74-4131,由利本荘市小人町２２３－１,https://goo.gl/maps/RY6M5mQYMKZwyPSe8
,池田薬局 マカベ調剤店,0184-22-0611,由利本荘市川口字家後１５０－１,https://goo.gl/maps/71XEBwZBuMh3UzCg6
,池田薬局 わかば店,0184-22-3912,由利本荘市石脇字田尻野６－１１,https://goo.gl/maps/D9XNZfSHtFeYqWLw9
,みちかわ薬局,0184-62-6722,由利本荘市 岩城内道川字井戸ノ沢22-1,https://maps.app.goo.gl/rvN54nYHS98QDmmZ8
,由利調剤薬局,0184-22-8090,由利本荘市 川口字家後151-1,https://maps.app.goo.gl/rWvKGHMALkYnyivy5
,由利調剤薬局南店,0184-28-0303,由利本荘市一番堰145-5,https://goo.gl/maps/XSBc8NKDosepoDh99
,象潟調剤薬局,0184-43-4655,にかほ市象潟町字後田27番地1,https://goo.gl/maps/SzUhr1X84zhXkgba9
,すみれ調剤薬局,0184-62-3077,由利本荘市中田代字板井沢１１２ー８,https://goo.gl/maps/QELovgtvq53t8p6B6
,西目調剤薬局,0184-33-4880,由利本荘市西目町沼田字新道下２－９０,https://goo.gl/maps/NKQwFBPx7EGxXRt36
,大越調剤薬局このうら店,0184-22-3903,にかほ市金浦字古賀の田４－５７,https://goo.gl/maps/barAv47K49v9QTe2A
,オレンジ薬局,0184-28-1201,由利本荘市岩渕下４０－１,https://goo.gl/maps/ff3e9aVojG2ocU9z7
,おいかた調剤薬局,0184-62-4122,由利本荘市東由利老方字老方１３－７,https://goo.gl/maps/jMxt2r2NANpikoz47
,コアラ調剤薬局,0184-28-5055,由利本荘市西梵天７５ー４,https://goo.gl/maps/VV2rsoMquBNxF1FK9
,,,,
看護関連施設,総合介護福祉施設 ハルモニア・にかほ,0184-44-8535,にかほ市院内字嶋田４２番地１,https://goo.gl/maps/jG2vqkdukQgXDVGfA
,ごてんまり訪問看護ステーション,0184-73-3828,由利本荘市岩城内道川字井戸ノ沢84ｰ120,https://maps.app.goo.gl/ZYHMj4f3gAR8TZPR8
,第一病院訪問看護ステーション,0184-24-1161,由利本荘市岩渕下104,https://goo.gl/maps/aix237s82M8eY3pS9
,しあわせ訪問看護ステーション,0184-74-3068,にかほ市象潟町字四丁目塩越201番地,https://maps.app.goo.gl/PjnZQ7GUYEwVfiF4A
,医師会訪問看護ステーション,0184-22-0054,由利本荘市undefined,https://goo.gl/maps/BG17XAj7uLo2MDAf7
,,,,
福祉・介護関連施設,短期入所生活介護施設 千寿苑,0184-23-7200,由利本荘市川口字飛鳥下245-2,https://maps.app.goo.gl/nPYeD8K2CzkTbBB29
,あまさぎ園居宅介護支援事業所,0184-62-5015,由利本荘市岩城富田字根本9-3,https://goo.gl/maps/vpXBHTNqyGVcQdkk6
,池田ライフサポート＆システム（株）,0184-24-5601,由利本荘市石脇字田尻野7-3,https://goo.gl/maps/kAQjrUGBuehvNS666
,「わかば」訪問看護ステーション,0184-74-6535,由利本荘市川口家妻34-1,https://goo.gl/maps/AEtrrEtM9o116HPy7
,尾崎居宅介護支援事業所,0184-74-8073,由利本荘市裏尾崎町6番地5,https://maps.app.goo.gl/TMUpZeuSDdAhbQGC8
,虹の街 本荘営業所 ,0184-24-6052,由利本荘市川口字下野173-1,https://goo.gl/maps/TKPcZXrQtEDPqQWQ6
,虹の街 本荘営業所 訪問入浴,0184-24-6052,由利本荘市川口字下野１７３－１,https://goo.gl/maps/TKPcZXrQtEDPqQWQ6
,多機能わかばイースト,0184-74-3385,由利本荘市荒町字真城42-2,https://maps.app.goo.gl/1536NZsDjj2cVqHj8
,相談支援事業所ほっと,0184-28-5525,由利本荘市桜小路27番地1‐2階,https://maps.app.goo.gl/JP9rCfYuxcmm74Y76
,訪問介護ステーションほっと,0184-74-3163,由利本荘市桜小路27番地1−2階,https://maps.app.goo.gl/xC3mDNHBgGRQjBbe9
,居宅介護支援事業所ほっと,0184-28-5525,由利本荘市石脇字田尻28-802,https://goo.gl/maps/WiUYGkysBf6WKiNN9
,居宅介護支援「わかば」,0184-74-5471,由利本荘市石脇字田尻野7番地3,https://goo.gl/maps/4W6iwa95mm4weBSi7
,グループホーム夕なぎ,0184-74-4073,由利本荘市岩城内道川字水呑場28-30,https://maps.app.goo.gl/5XjaTiLFU9zCdXFB9
,第一病院居宅介護支援事業所,0184-28-5251,由利本荘市岩渕下１０４,https://goo.gl/maps/U9KDCWAhenJ6xyp46
,デイサービスセンター てまり,0184-24-3711,由利本荘市水林421番地,https://goo.gl/maps/8NmAwmFZm5f3QuW4A
,大内居宅介護支援事業所,0184-65-2808,由利本荘市岩谷町字日渡１２４－１,https://goo.gl/maps/kX8ebVSwS8pPyvmx9
,由利本荘市社協西目居宅介護支援事業所,0184-23-5519,由利本荘市西目町沼田字新道下２－５４８,https://goo.gl/maps/eq4PQzsYwKz9oyFx8
,あまさぎ園 通所リハビリテーション,0184-62-5001,由利本荘市岩城冨田字根本9-3,https://goo.gl/maps/Yq9NLdSzPaZq4vo48
,介護老人保健施設グランドファミリー西目,0185-32-1011,由利本荘市西目町沼田字新道下２－６,https://goo.gl/maps/tm4KSEzkZ38naJvg8
,居宅介護支援ゆうゆう,0184-27-1135,由利本荘市一番堰１４２番地１,https://goo.gl/maps/Q2uogwKx9Tr41EtC8
,鳥寿苑居宅介護支援事業所,0184-57-2561,由利本荘市鳥海町伏見字久保７７,https://maps.app.goo.gl/LFTj2vZ9VqbDZgBe7
,特別養護老人ホームわかばイースト,0184-74-6117,由利本荘市荒町真城42-1,https://maps.app.goo.gl/pyUG9oSLz7kxVwiB8
,ショートステイ夕陽の郷,0184-44-8770,由利本荘市石脇字田尻10番地2,https://goo.gl/maps/99wPMMc8WXP31FdH9
,千寿苑居宅介護支援事業所,0184-23-5660,由利本荘市川口字八幡前２２６番地１,https://goo.gl/maps/sUye8fA5txJsAmj69
,みんなのまち岩城,0184-74-3166,秋田県由利本荘市岩城内道川字築防潟５１番４,https://goo.gl/maps/U9KDCWAhenJ6xyp46
,SOMPOケア由利本荘,0184-28-0671,由利本荘市瓦谷地28-1 鶴沼ビル1階,https://maps.app.goo.gl/kkT3iupgFFjpuH2V8
,通所介護ほっと,0184-28-5525,由利本荘市石脇字田尻28-802,https://goo.gl/maps/WiUYGkysBf6WKiNN9
,デイサービスセンター だいち,0184-62-0067,由利本荘市岩谷町字ハケノ下８０－２ ,https://goo.gl/maps/5wiRTU6CNhyZBUKo7
,デイサービス「わかば」,0184-74-7626,由利本荘市石脇字田尻野7番地の3,https://goo.gl/maps/4W6iwa95mm4weBSi7
,特別養護老人ホーム 広洋苑,0184-73-2245,由利本荘市岩城内道川字上山134番地,https://goo.gl/maps/BesLrw4rhPusrttDA
,虹の街グループホーム本荘,0184-74-7555,由利本荘市川口字下野173-1,https://goo.gl/maps/TKPcZXrQtEDPqQWQ6
,ふるさと矢島居宅介護支援事業所,0184-27-5711,由利本荘市undefined,https://goo.gl/maps/5aHv5j3AafZhMiZt8
,渡邊医院介護保険センター,0184-53-3100,由利本荘市前郷字家岸７９番地２０,https://goo.gl/maps/tkdnc7tBPVSAYLSt6
,ケアセンターこすもす,0184-37-2060,にかほ市田抓字明後田46-2,https://maps.app.goo.gl/SQLzFVc6qvSDLKw9A
,ショートステイそら,0184-33-6355,にかほ市田抓字明後田47-4 ,https://maps.app.goo.gl/qsrP14G5VdfskqAe8
,小規模ディサービスこすもす,0184-37-3777,にかほ市平沢字堺田14-1,https://maps.app.goo.gl/z4q8G75iJ5nQHEJx6
,医師会居宅介護支援センター,0184-27-2891,一般社団法人由利本荘医師会水林４５６−４,https://goo.gl/maps/BG17XAj7uLo2MDAf7
,由利本荘市社協訪問入浴事業所,0184-24-3634,由利本荘市堤脇40番地1,https://goo.gl/maps/A6bRY4nvCsiTPQFj6
,グループホームきざくら,0184-62-4788,由利本荘市東由利老方字後田７０－３,https://goo.gl/maps/ZzwnMyVuGnPdNuTz8
,東由利居宅介護支援事業所,0184-69-2135,由利本荘市東由利老方字台山３６,https://goo.gl/maps/1VK9sKrrY1k3FWZu8
,本荘居宅介護支援事業所,0184-24-3634,由利本荘市堤脇40番地1,https://goo.gl/maps/A6bRY4nvCsiTPQFj6
,由利本荘市社協訪問介護事業所,0184-24-3634,由利本荘市堤脇40番地1,https://goo.gl/maps/A6bRY4nvCsiTPQFj6
,由利本荘地域生活支援センター,0184-25-7077,由利本荘市二番堰２５－１,https://goo.gl/maps/wd1zcJqTztxbXqdj7
,養護老人ホーム寿荘,0184-22-4645,由利本荘市水林457ｰ4,https://maps.app.goo.gl/drYhLmi7tTZJsb9z6
,,,,
公共機関,にかほ市地域包括支援センター,0184-32-3045,にかほ市平沢字鳥ノ子渕２１,https://maps.app.goo.gl/sPvDH9twJSELPzrW6
,由利本荘市健康管理課 成人保健班,0184-22-1834,由利本荘市瓦谷地１,https://goo.gl/maps/3Cmj1YweRhrzcRoJA
,由利本荘市健康管理課 母子保健班,0184-22-1834,由利本荘市瓦谷地１,https://goo.gl/maps/3Cmj1YweRhrzcRoJA
,由利本荘市地域包括支援センター,0184-24-6324,由利本荘市尾崎１７番地,https://goo.gl/maps/74DDsmcc7hLpRE9y5
,由利本荘市地域包括支援センター（鳥海）,0184-57-2076,由利本荘市鳥海町伏見字 赤渋２８－１,https://maps.app.goo.gl/QpoogtYtq99B7Lig9
,由利本荘市地域包括支援センター（東光苑）,0184-69-3938,由利本荘市東由利蔵字蔵８３,https://maps.app.goo.gl/mDwZs2wjnmqU7nia6
,,,,
その他の事業所,由利本荘医師会,0184-22-4085,由利本荘市水林456番地4,https://maps.app.goo.gl/MkVLAWT8YYdcJswu6
,有限会社菊長商店,0184-22-0332,由利本荘市本荘93番地,https://maps.app.goo.gl/3rsvjt6EtnQ8dKoi7
,,,,
【横手支部】,,,,
診療所,井田内科胃腸科医院,0182-42-0066,横手市十文字町字海道下121-4,https://goo.gl/maps/Cc9Hv8DW6fHUHomCA
,細谷内科医院,0182-36-2221,横手市前郷二番町8-18,https://maps.app.goo.gl/1BVFBup5CMvphCq19
,おぎわら内科診療所,0182-23-5301,横手市条里一丁目15番2号,https://goo.gl/maps/SG3gMzVSjxVGYdND7
,雄物川クリニック,0182-22-5511,横手市雄物川町今宿字棒突44-1,https://goo.gl/maps/niZ8PHze5wJUNBHM7
,さわぐちクリニック,0182-23-7830,横手市横手町四ノ口58-1,https://goo.gl/maps/pidgzwq1dzrDXMT1A
,曽根医院,0182-56-4111,横手市大森町袴形西神成７−２,https://goo.gl/maps/Y3y4xRRTVFcZJ27H9
,醍醐クリニック,0182-25-3090,横手市平鹿町醍醐字石成25-1,https://goo.gl/maps/XtW2WaXccqQFUJ8L6
,高橋医院,0182-42-0421,横手市十文字町仁井田東55-3,https://goo.gl/maps/Cc9Hv8DW6fHUHomCA
,高橋耳鼻咽喉科眼科クリニック,0182-32-2275,横手市前郷二番町4-25,https://goo.gl/maps/DeE4BdkHu4hEyh89A
,高橋内科医院,0182-32-5662,横手市安田ブンナ沢８０−４５,https://goo.gl/maps/kYR7vq8P5GdFC7fr7
,西成医院,0182-24-1023,横手市平鹿町浅舞字浅舞53,https://goo.gl/maps/F4qKeDGNC6wV9B9Y7
,,,,
歯科診療所,石川歯科医院,0182-42-0177,横手市横手市十文字町大道東55-5,https://goo.gl/maps/vXdv7PEEUoNncrAt5
,,,,
薬局関連施設,つばさ薬局横手店,0182-35-7150,横手市条里三丁目3番11号,https://goo.gl/maps/WhqwPQiBczMArCBa9
,さいた薬局,0182-32-1268,横手市四日町2番13号,https://goo.gl/maps/i5DKSPfh4igUibtQ8
,あさまい薬局 横手店,0182-38-8561,横手市横手町字四ノロ４８番４号,https://goo.gl/maps/8Ubrqb4wEM5Cv2vG9
,あさまい薬局,0182-24-1110,横手市平鹿町浅舞字館廻３１４－２,https://goo.gl/maps/Sg74J6BpFgZMqnet5
,平鹿調剤薬局東店,0182-33-2277,横手市前郷字八ツ口84-1,https://maps.app.goo.gl/e9gBtwS7KMgUxigTA
,平鹿調剤薬局中央店,0182-33-2272, 横手市横手町字四ノ口56-1,https://maps.app.goo.gl/6Bayvp8cTTnAUFKY9
,下田薬局,0182-32-0583,横手市四日町1-2,https://maps.app.goo.gl/Edexb15E1nhMs6ZE6
,どれみ薬局,0182-23-5191,横手市条里1-15-26,https://goo.gl/maps/dtE9xL7WLF6mPBAb6
,やまさ薬局,0182-32-2739,横手市鍛冶町１－３５高易ビル１階,https://goo.gl/maps/a7Vkftb1ncYDHDAq7
,横手南薬局,0182-32-2422,横手市安田字ブンナ沢８０－６,https://goo.gl/maps/XFskNLRKS6Lk1Tc38
,,,,
看護関連施設,すずらん訪問看護ステーション横手,0182-38-8355,横手市大雄字三村東18番地,https://goo.gl/maps/R2hJF1iSWQn8VjpJ9
,,,,
福祉・介護関連施設,りんごの里 福寿園,0182-45-3131,横手市増田町吉野字梨木塚100-1,https://goo.gl/maps/e9fb3rnSZB2HmQQK8
,ここきら居宅介護支援事業所,0187-73-7281,仙北郡美郷町六郷字上町44番地,https://maps.app.goo.gl/DACGpFNu5p2pTGURA
,GENKINEXT秋田横手,0182-23-5338,横手市赤坂字館ノ下105-6,https://goo.gl/maps/amyRPyeTVSmPBoud8
,西風苑居宅,0182-24-3033,横手市平鹿町浅舞字新堀91番地,https://goo.gl/maps/dPyzd7eMWdpCPT6i7
,相和会かいご相談センター,0182-32-1234,横手市赤坂字仁坂105番地2,https://goo.gl/maps/5mtX41tVEfWaJUuN6
,歩行と言葉のリハビリ空間なごみ横手,0182-23-5315,横手市八幡字石町165番地1号,https://maps.app.goo.gl/2mWeVoj6qtbL92vDA
,悠西苑居宅,0182-23-5252,横手市平鹿町浅舞字新堀146番地,https://goo.gl/maps/cLCUTYxZyZhPAoFy8
,横手市社協西部居宅,0182-26-3274,横手市大森町字大中島268番地,https://goo.gl/maps/skRGCSR475xuA8hv9
,横手市社協 雄物川福祉センター居宅,0182-36-5377,横手市雄物川町今宿字鳴田150番地,https://goo.gl/maps/kKPG5Jd7rxtWX3YY9
,横手市社協 山内福祉センター居宅,0182-53-3207,横手市山内土渕字鶴ケ池31番地3,https://goo.gl/maps/wRaDzEJQFPuqv9ZKA
,横手市社協 十文字福祉センター居宅,0182-36-5377,横手市十文字町梨木字御休ノ上29番地,https://goo.gl/maps/XFA895ck2cjirejaA
,横手市社協平寿苑居宅,0182-38-7010,横手市平鹿町浅舞字館廻353番地,https://goo.gl/maps/gvv9MoiidzrChxvA7
,横手市増田町居宅介護支援事業所,0182-45-4411,横手市増田町増田字七日町１７７番地,https://goo.gl/maps/HNM9HdDPxykmgKFr7
,横手市社協 横手福祉センター居宅,0182-36-5377,横手市卸町5番10号,https://goo.gl/maps/aHQ6GoXrN2sTFAcp8
,りんごの里福寿園居宅介護支援センター,0182-45-5566,横手市増田町増田字土肥館171番地,https://goo.gl/maps/nFiYVeEU6NDBtGSx6
,,,,
公共機関,横手市地域包括支援センター,0182-35-2135,横手市大森町字菅生田245-206,https://goo.gl/maps/KQ9snePjDVYE6wEf8
,,,,
【湯沢雄勝支部】,,,,
診療所,小野崎医院,0183-73-2540,湯沢市表町3−1−29,https://goo.gl/maps/nRzUwm6MG7VTc7cdA
,医療法人せいとく会 菅医院,0183-52-2000,湯沢市小野字東堺77番地1,https://goo.gl/maps/HtxJHrUot8JPR5nRA
,みわ内科,0183-62-1200,雄勝郡羽後町貝沢字稲荷23番地の9,https://maps.app.goo.gl/Z6fokiDsHWLU7dCT8
,佐藤外科,0183-73-7100,湯沢市沖鶴１６７－２,https://goo.gl/maps/2iRX3U5NVPvuDick9
,,,,
歯科診療所,佐藤歯科医院,0183-73-2720,湯沢市前森1-2-11,https://goo.gl/maps/Zd37u7Wtka8kL8qeA
,志水歯科医院,0183-62-1338,雄勝郡羽後町南西馬音内３１４,https://goo.gl/maps/cfrZ5DVYi9U94LMu8
,ジュネスデンタルクリニック,0182-47-3131,雄和郡東成瀬村田子内田子内51,https://maps.app.goo.gl/jB6NLFQezVZn7L7p8
,田中歯科医院,0183-73-2314,湯沢市大町1丁目2-35,https://maps.app.goo.gl/YYgpoqdgsUFseFFo9
,,,,
薬局関連施設,雄和調剤薬局,0183-72-3210,湯沢市山田字勇ヶ岡31,https://maps.app.goo.gl/cyqKjE8vCxe7QhKr9
,きむら薬局,0183-62-1237,雄勝郡羽後町西馬音内本町３０−２,https://goo.gl/maps/48hT1mn8ob6XfQcz5
,クオール薬局うごまち店,0183-78-4330,雄和郡羽後町字稲荷94−2,https://maps.app.goo.gl/3gEdUGnu2H3g7Mj7A
,サン薬局,0183-73-7557,湯沢市表町3丁目2-1,https://goo.gl/maps/29Xgpe2soAHGP1sL7
,日本調剤 羽後薬局,0183-78-4200,雄勝郡羽後町字南西馬音内169-3,https://maps.app.goo.gl/cDmpy3YKGLTdwhTo8
,,,,
看護関連施設,雄勝訪問看護ステーション,0183-72-1591,湯沢市山田勇ケ岡25,https://goo.gl/maps/LdFTPUmRtH9XxGJ18
,,,,
福祉・介護関連施設,アムールケアサービス,0183-56-6834,湯沢市御囲地町3-40 ,https://goo.gl/maps/dFXAq8B2zBEHXcKDA
,グループホーム夕陽の丘山田,0183-78-0515,湯沢市山田字中屋敷14番地,https://maps.app.goo.gl/PnLkp7XeCBDVJSJA8
,しゃくやく,0183-78-6221,湯沢市小野字諏訪23-6,https://maps.app.goo.gl/fEsHzfCYC1ppxiHL7
,しゃくやく3号館,0183-55-8535,湯沢市小野字諏訪23-6,https://maps.app.goo.gl/7nxFTtLP8RBKyzFB9
,グループホームかつら,0183-65-8325,湯沢市川連町大舘屋布前124,https://goo.gl/maps/rgi8ftuEdDHAaqaS6
,紫雲閣居宅,0183-55-8862,湯沢市横堀字小正寺16番地1,https://goo.gl/maps/eV415jutfw8y9yiX8
,湯沢市社協,0183-73-8696,湯沢市古館町4番5号,https://goo.gl/maps/41kx76cLpHa2rNUM9
,ショートステイ桂,0183-42-2646,湯沢市川連町字大舘屋布前１２４,https://goo.gl/maps/SRiMj7CGm8oij9DR7
,地域生活支援拠点 愛光園,0183-72-8107,湯沢市字両神15-1,https://maps.app.goo.gl/hpLr3fP1Q8jS2Gyv8
,ぱあとなあ訪問介護事業所,0183-72-8107,湯沢市字両神15番地1,https://goo.gl/maps/9SHHChQcM4FPhvjF6
,平成園指定居宅,0183-52-5210,湯沢市小野字大沢田221番地,https://goo.gl/maps/9N1XBPfxmxZtnFFr5
,,,,
【秋田市支部】,,,,
病院,医療療育センター,018-826-2401,秋田市南ヶ丘一丁目１－２,https://maps.app.goo.gl/pFyjdBEAmfsqNXRNA
,秋田大学病院,018-834-1111,秋田市広面字浅沼44-2,https://maps.app.goo.gl/DGeKh8b7axekD8DT6
,秋田大学小児科,018-834-1111,秋田市広面字蓮沼44-2,https://maps.app.goo.gl/DHFXp7VHvUtz59er5
,小泉病院,018-833-6371,秋田市中通四丁目1-28,https://maps.app.goo.gl/4pd9v1rxSRwRfjbF8
,市立秋田総合病院 小児科,018-823-4171,秋田市川元松丘町4-40,https://maps.app.goo.gl/r9UGQQcqS2kmKust6
,秋田県立循環器・脳脊髄センター,018-833-0115,秋田市千秋久保田町6-10,https://maps.app.goo.gl/fdb3NPxkwcTXWPv9A
,,,,
診療所,秋田こどもの心と発達クリニック,018-853-6675,秋田市土崎港中央4ｰ10ｰ18,https://maps.app.goo.gl/jHFhfYfLKSeDE4sz7
,長谷山内科医院,018-832-2248,秋田市中通3丁目3-43,https://maps.app.goo.gl/ZjBTVkqG9fxBVkd8A
,山王胃腸科,018-862-5211,秋田市山王二丁目１番49号,https://maps.app.goo.gl/A8VVbDVWHcjSQX936
,小川内科医院,018-832-4013,秋田市中通三丁目三丁目3番55号,https://maps.app.goo.gl/Y1f4n3SHXmPXAmLU6
,豊島医院,018-892-2211,大仙市協和境字野田86,https://maps.app.goo.gl/h8z1LBpRYoe1QDrE7
,真崎耳鼻咽喉科医院,0184-845-0234,秋田市土崎港中央6丁目8番3号,https://maps.app.goo.gl/YpjL6Qvb4agykm4f8
,濱島医院,018-823-5252,秋田市保戸野すわ町15-20,https://maps.app.goo.gl/fnnmLUuw3wKT8udX6
,医療法人 水沢医院,018-866-5533,秋田市茨島４丁目6-37,https://maps.app.goo.gl/Qw219eZRGkQUGjp67
,岩渕内科胃腸科クリニック,018-896-7270,秋田市保戸野中町7-16,https://maps.app.goo.gl/banhLQrCTT2xBFxCA
,木村内科クリニック,018-828-1112,秋田市新屋田尻沢東町10-5,https://maps.app.goo.gl/4CmdukBgS91FmzfY8
,熊谷内科医院,018-833-0308,秋田市中通5丁目5-8,https://maps.app.goo.gl/1XBt3fvNTENw3ZFX9
,平野いたみのクリニック,018-896-5644,秋田市川尻御休町5-40,https://maps.app.goo.gl/gCkdwVYwB9HAZ9hw8
,湊小児科医院,018-834-5621,秋田市中通５丁目7番34号,https://maps.app.goo.gl/nxFwqeQGPokNkVKX7
,元町形成外科,018-829-3003,秋田市御所野元町五丁目12番1号,https://maps.app.goo.gl/5ponM4Wvp8tpAn2x7
,森川内科・呼吸器科クリニック,018-888-8363,秋田市新屋表町3番18号,https://maps.app.goo.gl/tk64eejA9aRBfXDv5
,,,,
歯科診療所,あんどう矯正歯科クリニック,018-837-6480,秋田市東通4丁目4-18,https://maps.app.goo.gl/oRjkH6aABdNBwRY28
,いしかわ歯科・矯正歯科,018-887-3988,秋田市広面字野添181,https://maps.app.goo.gl/LdFWqNWZxWVQhCC47
,(医)慶尊会 玉木デンタルクリニック,018-827-5427,秋田市旭南三丁目2-73,https://maps.app.goo.gl/AeHcJ8oGQeQ9x8Gu5
,深川歯科医院,018-827-4182,秋田市中通２丁目５－１ クッロセ秋田 ４階,https://maps.app.goo.gl/7dtGehTw68hM7hgm6
,安倍歯科医院,018-874-8390,秋田市中通三丁目3-28,https://maps.app.goo.gl/42mzdYRpQpvvJeNXA
,石田歯科医院,018-832-5482,秋田市南通亀の町5-7,https://maps.app.goo.gl/wYqa85dA6t5SSLQs7
,さとう歯科医院,018-835-2255,秋田市広面蓮沼104-1 クリーンセラミックビル2F,https://maps.app.goo.gl/PMvmNwbGVo2XFZ4x6
,旭北歯科医院,018-865-2931,秋田市旭北栄町1-4,https://maps.app.goo.gl/dQtwqFUzRYaM47w48
,ささき歯科クリニック,018-884-0333,秋田市広面字大袋38-1,https://maps.app.goo.gl/RmuJnbPXYVKaXMgc9
,清水歯科クリニック,018-863-3443,秋田市保戸野千代田町２－４１,https://maps.app.goo.gl/xLGDHkapsjquxz736
,タクシカ,018-867-8255,秋田市寺内字三千刈325-3,https://maps.app.goo.gl/nEcwjMaCDKHwC8tx5
,田中歯科医院,018-857-1133,秋田市寺内油田3-15-3,https://maps.app.goo.gl/YYgpoqdgsUFseFFo9
,みやがた駅東歯科,018-825-1150,秋田市手形字西谷池182-3,https://maps.app.goo.gl/xE9BJaZ3BZfo5kqK6
,,,,
薬局関連施設,アルヴェいわま薬局,018-884-0911,秋田市東通仲町4番Ⅰ号  秋田拠点センターアルヴェ1F,https://maps.app.goo.gl/HHJWF81GpgELXWA17
,秋田みなみ会営薬局,018-833-2334,秋田市上北手猿田字苗代沢207-3,https://maps.app.goo.gl/FB7XgsCztFyPUbc57
,追分佐野薬局,018-873-7002,秋田市下新城中野字琵琶沼226番1号,https://maps.app.goo.gl/86if1y4CrLP2zN6j6
,大町いわま薬局,018-893-5777,秋田市大町三丁目4-40,https://maps.app.goo.gl/xMJwaGjR9uexFyt29
,河畔薬局秋田店,018-836-7517,秋田市南通亀の町3番18号,https://maps.app.goo.gl/1fif65Ao8tDs2pZAA
,サンパティオいわま薬局,018-874-8878,秋田市大町一丁目2番7号サンパティオ大町A棟2階,https://maps.app.goo.gl/wv5WVTD3MkXb5QCM7
,山王薬局,018-863-6132,秋田市山王２丁目1-49,https://maps.app.goo.gl/3GLio14usyRgvHr1A
,佐野薬局御野場店,018-853-6722,秋田市仁井田本町5-7-6,https://maps.app.goo.gl/csiLpDQ8P2fZHkad8
,なごみ調剤薬局,018-874-7016,秋田市広面字家ノ下35-1,https://maps.app.goo.gl/aD7JbAidhD8BTz2T6
,山田相談薬局,018-863-0063,秋田市大町1-6-21,https://maps.app.goo.gl/4Fb5nPcV5jpggV9a6
,旭南いわま薬局,018-893-5677,秋田市旭南一丁目1-6,https://maps.app.goo.gl/myMr8Y3j9qAUnLKC8
,金星堂薬局,018-892-7377,秋田市南ヶ丘二丁目9-１,https://maps.app.goo.gl/Yf5mSUpStu2M3z8v5
,佐野薬局 原の町店 ,018-896-5568,秋田市保戸野鉄砲町10ｰ6,https://maps.app.goo.gl/6oxbi99X74jButJr7
,佐野薬局広面店,018-831-8001,秋田市広面字堤敷25-1,https://maps.app.goo.gl/Xj4rYxo3ewyGhJAA7
,佐野薬局 四小前店,0185-74-5121,能代市字藤山112‐2,https://maps.app.goo.gl/q8amJmvUUzUsQWoM9
,山王佐野薬局,018-823-3371,秋田市山王中園町３-１１ カメイビル１階,https://maps.app.goo.gl/wVqtTPdgxV2ThiMUA
,将軍野いわま薬局,018-847-1218,秋田市将軍野南四丁目6-24-2,https://maps.app.goo.gl/MXEysR37xfsHwJvQ8
,外旭川いわま薬局,018-893-6555,秋田市外旭川字待合13-6,https://maps.app.goo.gl/FQiQdtJ8HmVDkx5b8
,通町いわま薬局,018-853-4381,秋田市大町一丁目2-26,https://maps.app.goo.gl/KvRLRtuuX5iZPTJh9
,中通きょうや薬局,018-887-4554,秋田市中通５丁目5-10,https://maps.app.goo.gl/G4ufhf4iJHj6eMZx6
,東通いわま薬局,018-827-3300,秋田市東通一丁目6-16,https://maps.app.goo.gl/5EWtj1Qr9oSqJWnX8
,雄和薬局,018-886-5027,秋田市雄和妙法字上大部133-3,https://maps.app.goo.gl/Crs36D8py9F3W62NA
,ラベンダー薬局,018-867-8338,秋田市泉南３丁目18-11,https://maps.app.goo.gl/xwZTjLoAmqzcj3WN7
,,,,
看護関連施設,医師会訪問看護ステーション,018-864-1551,秋田市八橋南一丁目8番5号,https://goo.gl/maps/BG17XAj7uLo2MDAf7
,ごてんまりZ,018-829-1611,秋田市御所野湯元２丁目1-２秋田物流センターA2,https://maps.app.goo.gl/DUrmgw5cSQAZrHum8
,パリケアあきた訪問看護ステーション,018-838-0828,秋田市大町2-5-1,https://maps.app.goo.gl/FqHJ1bC6Hbim999SA
,プロケアあきた,018-853-7905,秋田市川尻町字大川反170-40 2FA,https://maps.app.goo.gl/RBFfx4FrsPGgkni47
,訪問看護ステーションあきた,018-853-4120,秋田市保戸野千代田町16-16,https://maps.app.goo.gl/PUiLZmrgzV5gCLHu5
,らいく訪問看護ステーション,018-827-4250,秋田市新屋扇町12-49,https://maps.app.goo.gl/cuvVdaLy1dJQR2M86
,,,,
福祉・介護関連施設,アースサポート 秋田,018-836-6811,秋田市広面字家の下98番地3号,https://goo.gl/maps/jvfN4hYDbVA2QDqr6
,医師会居宅介護支援事業所,018-896-7707,秋田市八橋南一丁目8番5号,https://maps.app.goo.gl/E2CLfXr7Nt1KDCpe8
,第二聖和,018-874-9616,秋田市外旭川字野村20-1,https://maps.app.goo.gl/jM2VUBsYhdYARVLM9
,ひかり居宅介護支援センター,018-874-8885,秋田市秋田市桜二丁目17番30号,https://maps.app.goo.gl/xfZ1ReUZYx8PG8149
,ケアセンターきらら,018-889-7272,秋田市太平山大町二丁目5−1 きららアーバンパレス,https://goo.gl/maps/cwky92QcFECpwM1SA]
,合同会社グレイス,018-881-1355,秋田市河辺諸井字下諸井32-2,https://maps.app.goo.gl/gYbytChJJnCfmbiM9
,コリウス居宅,018-853-1556,秋田市四ツ小屋城下当場２５3－４,https://goo.gl/maps/fbT3ENVAyJSushVE6
,秋田聖徳会相談支援事業所,018-874-9888,秋田市川元小川町１番８号,https://maps.app.goo.gl/tFLvxGoRhqde63RW7
,サポートセンター聖和,018-874-8415,秋田市川元小川町１番８号,https://maps.app.goo.gl/srEDboiQGNL1J16z8
,支援センターほくと,018-873-7804,秋田市下新城中村字街道端西11-1,https://maps.app.goo.gl/y4LwJjSUTr8YEkvD8
,相談支援事業所十人十色,018-811-3698,秋田市南ヶ丘２丁目３－１７,https://maps.app.goo.gl/hYPvEXMnFuccS1DF9
,デイサービスにのに,018-838-6125,秋田市御野場1丁目2-2,https://maps.app.goo.gl/Qqfx17p1Tx1MHPoy5
,特別養護老人ホーム中通,018-874-8277,秋田市中通４丁目3-23,https://maps.app.goo.gl/DQHgykkZEp6cPk1K6
,特別養護老人ホーム中通アネックス,018-853-1090,秋田市中通５丁目8-15,https://maps.app.goo.gl/52NXq8HPQ6sM7JEQ6
,放課後デイGranny秋田,018-827-3822,秋田市釜ノ町17-18,https://maps.app.goo.gl/5XDXmf8DGiLZ3dVs5
,放課後デイGranny秋田２号店,018-827-4076,秋田市仁井田緑町5-8,https://maps.app.goo.gl/z8WsFmixyyZs2XYy7
,秋田在宅介護サービスセンター,018-833-7073,秋田市横森一丁目20-30,https://maps.app.goo.gl/W8taUjhH9azLg8jU8
,やさしい手秋田,018-864-1113,秋田市川元むつみ町7-13,https://maps.app.goo.gl/YRcnB1igCTFPEMpF7
,グループホーム国見ノ里,018-828-9811,秋田市豊岩小山字前田表158の3,https://maps.app.goo.gl/QMqP1Dgknh8EyWsH8
,有料老人ホーム あらや,018-838-7096,秋田市新屋鳥木町4ｰ2,https://maps.app.goo.gl/GxRDQuPjQm5AH5at8
,,,,
その他の事業所,連携センター,018-827-3636,秋田市八橋南一丁目8-5,https://maps.app.goo.gl/4XN5cGRRJRa3ABcF7
,竹生寮相談支援事業所,018-834-2577,秋田市柳田字竹生168番地,https://maps.app.goo.gl/2kw46nn6FxnfPDPa6
,,,,
【大曲仙北支部】,,,,
病院,大曲厚生医療センター 小児科,0187-63-2111,大仙市大曲通町８−６５,https://maps.app.goo.gl/Y4VGpS2YRR7vqMw18
,,,,
診療所,荒井医院,0187-63-2670,大仙市大曲上大町6-22,https://maps.app.goo.gl/9WrFq5rRy75A8NYj9
,池田医院,0187-63-3338,大仙市大曲中通町4-20,https://maps.app.goo.gl/Zhe7PgCpdWJgMcgF7
,伊藤内科,0187-65-3200,大仙市角間川町字町頭178-2,https://maps.app.goo.gl/2FRWbFJoCLfE88eZ9
,佐々木内科医院,0187-63-1410,大仙市大曲上栄町1番28号,https://maps.app.goo.gl/GCg1heSS8L23YmDQA
,まっこいしゃ高橋醫院,0187-84-7070,仙北郡三郷町六郷字馬町64,https://maps.app.goo.gl/GikRs4qW4tLM9wcb9
,佐藤内科クリニック,018-895-2730,大仙市協和峰吉川半仙114,https://maps.app.goo.gl/PbP18AJxN81VyHnw6
,大仙ごとう整形外科クリニック,0187-73-5106,大仙市大曲田町28番33号,https://maps.app.goo.gl/1139ciRd1PJChGkt9
,医療法人道真会 太田診療所,0187-88-2233,大仙市太田町横沢窪関南505番1,https://maps.app.goo.gl/2KTVBB6B9EmVPAA98
,ささき脳神経外科・内科クリニック,0187-63-1010,大仙市大曲日の出町二丁目7番4号,https://maps.app.goo.gl/SE1eAoyYwe3EnxUcA
,木村内科医院,0187-63-2070,大仙市大曲中通町3番3号,https://maps.app.goo.gl/uzfZJ3uCVhzXPfzy5
,コスモスペインクリニック,0187-88-8333,大仙市大曲中通町1-8-7,https://maps.app.goo.gl/fSf9ociaaBNx3ADx9
,仙北市西明寺診療所,018-47-2123,仙北市西木町門屋道目木165-6,https://maps.app.goo.gl/caomWkWcbkRX6b6r7
,滑川医院,0187-56-3121,大仙市長野字九日町15-1 ,https://maps.app.goo.gl/cCqVE3u7yPNWVV1j9
,ミウラクリニック,0187-86-3280,大仙市幸町4-23-2,https://maps.app.goo.gl/Sc3SussNzAkWpYjk6
,三浦クリニック,0187-86-3280,大仙市幸町4-23-2,https://maps.app.goo.gl/Sc3SussNzAkWpYjk6
,みさと在宅診療所,0187-88-8639,仙北郡美郷町六郷字新町50-1,https://goo.gl/maps/sP2v4PsoXWLESPef6
,,,,
歯科診療所,山下歯科医院,018-763-0418,大仙市大曲黒瀬町3-45,https://maps.app.goo.gl/UajYbkpTbVDh7RqL6
,富永歯科医院,0184-84-0123,仙北郡美郷町六郷字荒町117-5,https://maps.app.goo.gl/XMsMU4GnzsquKX2y8
,畠山歯科医院,0187-74-3115,大仙市南外字落合24-3,https://maps.app.goo.gl/477EEPRAj7BPrZNQA
,,,,
薬局関連施設,中央薬局,0187-63-2800,大仙市大曲中通町3-4,https://maps.app.goo.gl/5n1HVd4zYnGuAek96
,ライフ薬局,0187-73-6408,大仙市飯田字堰東151番1,https://maps.app.goo.gl/SLyUp9bwn5mhKRki6
,,,,
看護関連施設,虹の街訪問看護ステーション大曲,0187-88-8773,大仙市大花町3番60-6,https://maps.app.goo.gl/wDLpgoJvFy199j9x6
,訪問看護ステーション ひだまりの郷,0187-73-6332,仙北郡美郷町野中字宮崎58番地1,https://goo.gl/maps/zAtLs3JSTDDSgS7q7
,ごてんまりα,0187-73-6866,仙北郡美郷町六郷字古舘南６ サテライト六郷,https://maps.app.goo.gl/ZMEHsdGnq1kYbtZ86
,訪問看護ステーションかくのだて,0187-42-8668,仙北市角館町勝楽123-1,https://maps.app.goo.gl/7Gbs65E1raXDKPqDA
,,,,
福祉・介護関連施設,なごみのさと,0187-86-0511,大仙市大曲船場町一丁目1-4,https://maps.app.goo.gl/hUpBpRRBmBqXgHeJA
,株式会社えがお,0187-86-3030,大仙市花園町21-19,https://maps.app.goo.gl/Lkb7yb9UEjMyErKR9
,ディサービスセンター木のした,0187-42-8630,仙北市角館町川原町後11-1,https://maps.app.goo.gl/EZhtzBJbMmX2rPzK8
,虹の街看護小規模多機能おおまがり,0187-88-8623,大仙市大花町3番60-6,https://maps.app.goo.gl/UBefkfUNodYXJw5j8
,虹の街おおまがりケアプランセンター,0187-66-2322,大仙市大曲花園町3-7,https://maps.app.goo.gl/cGsXAJ4crNCYwba98
,ケアプランもとまち,0187-52-4627,仙北市田沢湖小松字本町100番地,https://maps.app.goo.gl/iYCBrKVHhqmwBkxNA
,グループホーム大仙大曲,0187-62-7333,大仙市大曲福辺内3-1,https://maps.app.goo.gl/NbU45nhhP8q78PTs7
,ケアプランセンターあんり,0187-88-8940, 大仙市大曲福住町1-10,https://maps.app.goo.gl/xRmpbasofPtRreZW7
,特別養護老人ホームこもれびの杜,0187-63-6646,大仙市飯田字堰東235番地,https://maps.app.goo.gl/9rCTTp25ZWjzPjRk9
,介護老人保健施設 八乙女荘,0187-56-2888,大仙市北長野字野口前23番地,https://maps.app.goo.gl/HqgEiJryBcUTvxXP7
,特別養護老人ホーム ありすの街,0187-87-7310,大仙市強首字上野台23-18,https://maps.app.goo.gl/g7mimaT8dogeeQ42A
,にしき園,0187-47-3211,仙北市西木町門屋字屋敷田100番地,https://maps.app.goo.gl/YECSndV7XE8sFEkW6
,大仙市基幹相談支援センターかのん,0187-65-3676,大仙市角間川町字八幡前286-２,https://maps.app.goo.gl/tWSiU6juqsYHEeXs9
,相談支援事業所 かくまがわ,0187-65-3676,大仙市角間川字八幡前286番地2,https://maps.app.goo.gl/tWSiU6juqsYHEeXs9
,特別養護老人ホーム なごみの家,0187-88-8889,大仙市大曲船場町一丁目1-43,https://maps.app.goo.gl/CW1mpUBWoRqhghng6
,特養 清流苑,0187-58-2100,仙北市西木町桧木内松葉232番地,https://maps.app.goo.gl/afV757jZpgnZRVbC6
,特別養護老人ホーム なごみの家とまき,0187-73-7670,大仙市戸蒔字谷池添63番地1,https://maps.app.goo.gl/Qghf1a2SxSfHpBxv6
,なごみ居宅介護支援センター,0187-86-0511,大仙市大曲船場町一丁目1-4,https://maps.app.goo.gl/cWD5fwYJ5fmadQim6
,なごみ訪問リハビリテーション,0187-86-0511,大仙市大曲船場町一丁目1-4,https://maps.app.goo.gl/cWD5fwYJ5fmadQim6
,歩行と言葉のリハビリ空間なごみ,0187-88-8116,大仙市大曲栄町13番地38号,https://maps.app.goo.gl/KZ3qtxQZKJ8hbcfW9
,サポートハウス仙北,0187-52-1001,仙北市角館町勝楽123-1,https://maps.app.goo.gl/D3XqJ5Lx17wUDCKJ6
,グループホーム角館,0187-49-6636,仙北市角館町勝楽122-1,https://maps.app.goo.gl/izYViyPTf21xVA2z5
,グループホーム福寿草,0187-69-2910,大仙市福田字川原道下55-1,https://maps.app.goo.gl/bbfSHbggsBaP1CXs7
,グループホーム星宮,0187-69-3351,大仙市横堀字星宮66-3,https://maps.app.goo.gl/1sYZoQRpDR8uoJUh8
,ケアサポート神岡,0187-73-6888,大仙市神宮寺荒屋入6-28,https://maps.app.goo.gl/U9DjrjoLBubsoJ2P6
,ケアライフ心都,0187-49-6177,仙北市角館町勝楽122-1,https://maps.app.goo.gl/FWu2VXEBDpZkcBJA7
,サポートハウス高砂,0187-69-3005,大仙市横堀字星宮661,https://maps.app.goo.gl/8eetELusUumFZEwLA
,介護支援センターきたうら,0187-89-1585,大仙市太田町斉内字中田201-2,https://maps.app.goo.gl/9iRAxvPp9MugoF8m9
,グループホームおおた,0187-89-1066,大仙市太田町斉内字中田201,https://maps.app.goo.gl/tFkSKoQd2c7ykB7n8
,元気ハウスきたうら,0187-88-8862,大仙市太田町斉内字中田201,https://maps.app.goo.gl/nL4hXTxiUtRxBPN5A
,,,,
公共機関,大曲支援学校,0187-68-4123,大仙市大曲西根字下成沢122,https://maps.app.goo.gl/SjYL7kd9jecANWmk6
,,,,
【大館北秋田支部】,,,,
病院,大館市立総合病院,0186-42-5370,大館市豊町3番1号,https://maps.app.goo.gl/6DjTEmRZ13SZa3Zz9
,大館市立総合病院 小児科,0186-42-5370,大館市豊町3番1号,https://maps.app.goo.gl/AaWf5E4WukWdc1vaA
,北秋田市民病院,0186-62-7001,北秋田市下杉字上清水沢16-29,https://maps.app.goo.gl/s11PzGmCrXsPs2DZ7
,,,,
診療所,医療法人 石塚医院,0186-42-2020,大館市字向町23番地,https://maps.app.goo.gl/Qw219eZRGkQUGjp67
,北秋田市国民健康保険合川診療所,0186-78-3161,北秋田市李岱字下豊田20,https://maps.app.goo.gl/AkAyZiaHP5EM6a7Z6
,北秋田市立阿仁診療所,0186-82-2351,北秋田市阿仁銀山字下新町128,https://maps.app.goo.gl/ub7VkiRFAWyLyNos6
,北秋田市立米内沢診療所,0186-72-4501,北秋田市米内沢字林ノ腰3番地,https://maps.app.goo.gl/HTCTTqcFSGpKq48r6
,くまがい診療所,0186-59-7700,大館市池内田中180-1,https://maps.app.goo.gl/ZjAQz68xyeHVRGuz6
,さくらば医院,0186-49-3968,大館市東台5丁目1番44号,https://maps.app.goo.gl/rz2aqJU3wQvhqnHq5
,ふじた耳鼻科,0186-49-3384,大館市観音堂424‐1,https://maps.app.goo.gl/KJhYezEYpLHEKsFX7
,,,,
薬局関連施設,あい薬局中町店,0186-44-5678,大館市中町29,https://maps.app.goo.gl/api7SsX9zTrCN9GPA
,クローバー薬局 池内店,0186-59-7290,大館市池内字田中176ｰ1,https://maps.app.goo.gl/dAmF2mBK2cT3Q6oX6
,クローバー薬局 片山店,0186-59-7880,大館市住吉町3-14,https://maps.app.goo.gl/vcfWDhtieuhMLAsr7
,シオン薬局,0186-43-2866,大館市有浦2丁目4-18,https://maps.app.goo.gl/wwcTKS8V4QB6aEW8A
,ホテヤ調剤薬局,0186-43-6400,大館市幸町3-44,https://maps.app.goo.gl/cpJTpaJQQT1UQave8
,ホテヤ薬局 いとく店,0186-49-1179,大館市御成町３丁目7-58,https://maps.app.goo.gl/L7NWG6AA4cFTo6Cj8
,ホテヤ薬局 東台店,0186-49-9225,大館市赤館町9-59,https://maps.app.goo.gl/ZVuMBWrBjMtsBpJi9
,森吉薬局,0186-72-4301,北秋田市米内沢字林の腰14-25,https://maps.app.goo.gl/dDtgbfiLw57UMxmJ8
,さくら調剤薬局,0186-44-6633,大館市豊町9番46号,https://maps.app.goo.gl/x31Htbaw5pp2DY4w7
,さくら調剤薬局 常盤木店,0186-57-8301,大館市常盤木町17番11号,https://maps.app.goo.gl/4wzYEDpMjZnzrsmY8
,わかば薬局,0186-45-1660,大館市赤館町9-50,https://maps.app.goo.gl/KeJBCbznLbU8j38d7
,,,,
看護関連施設,訪問看護リハビリステーションまごころ,0186-59-6238,大館市釈迦内字稲荷山下181番1号,https://maps.app.goo.gl/Nf9jMyD1FJgv5xNJ8
,訪問看護ステーション一心堂,0186-59-6668,大館市秋田県大館市赤館町146-4,https://maps.app.goo.gl/qxbZ5FQV8YW8mvbv7
,看護小規模多機能「まるっと一心堂」,0186-59-5357,大館市赤館町146-4,https://maps.app.goo.gl/qxbZ5FQV8YW8mvbv7
,,,,
福祉・介護関連施設,ケアプランセンターはぎのだい,0186-59-8040,大館市小館花字萩野台8-1,https://maps.app.goo.gl/h6x4ETKnFWx6BfXP6
,ケアプランセンターおおたき,0186-47-7222,大館市十二所字大水口4-5,https://maps.app.goo.gl/ch5oiSiD6dJddkYB8
,nijico,0186-62-2227,北秋田市宮前町9-68（北秋田市地域福祉センター2階）,https://maps.app.goo.gl/TR1k2GxRoSSvyUNj9
,ケア・プラザかんきょう 大館店,0186-59-7855,大館市住吉町1番20号,https://maps.app.goo.gl/nN6QBFiamHpZmNmn6
,ショートステイ縁,0186-57-8577,大館市東台２丁目6-43,https://maps.app.goo.gl/MvNVVx33ARkoekea8
,ケアセンター  一心堂,0186-49-3433,大館市東台2丁目1-75-2,https://maps.app.goo.gl/6D2MoFcKz9hsKK1T9
,,,,
公共機関,比内支援たかのす校,0186-66-2128,北秋田市七日市字家向４９の内,https://maps.app.goo.gl/NWP4MBDwHJgJDcpL8
,,,,
【鹿角支部】,,,,
病院,大湯リハビリ温泉病院,0186-37-3511,鹿角市十和田大湯字湯ノ岱16番地2,https://maps.app.goo.gl/hwvYBuEmsN9ZsF5LA
,,,,
歯科診療所,あんどう歯科,0186-23-3927,鹿角市花輪字八正寺7の2,https://maps.app.goo.gl/hXMMQuPnj5HfKRvn7
,,,,
薬局関連施設,黒沢薬局,0186-35-3200,鹿角市十和田毛馬内字古下タ33-3,https://maps.app.goo.gl/y4Wy3Qug5VbCyBLK8
`