<template>
  <div class="main-contents">
    <header class="top-bar">
      <img src="/img/teaser_top_logo.png" />
    </header>
    <div class="teaser-page">
      <section class="hero">
        <div class="hero-left">
          <img src="/img/teaser_hero_logo.png" />
        </div>
        <div class="hero-right">
          <h1>ナラティブブック秋田の<br/>活動の範囲が広がります</h1>
          <div class="hero-caption">
            秋田県医師会が進めてきた地域包括ケアシステムの支援活動は、<br/>全県での取り組みとなり、さらに活動の幅が広がります。
          </div>
        </div>
      </section>
      <section class="description">
        <div class="upper-decoration"></div>
        <h2>ナラティブブック秋田<span class="title-small">はどう変わる</span>？</h2>
        <div class="lower-decoration"></div>
        <div class="description-text">
          これまで、比較的ご高齢の方や在宅医療を受けておられる方々を対象とした地域包括ケアに係る活動を「ケアナラティブ秋田」と呼ぶことにしました。<br/>
          そして、お子さんやその保護者の方を対象として、医療的ケア児にかかわる活動「キッズナラティブ秋田」がはじまります。<br/>
          「ナラティブブック秋田」は、これからも家族や生活や治療にかかわる医療福祉介護の方々と自分の想いを繋ぎ、絆を深める活動を広めていきます。
        </div>
        <img class="description-figure" src="/img/teaser_hero_bg_fig.png" />
      </section>
      <section class="division">
        <div class="akita-division">
          <div class="akita-division-frame">
            <h3><a href='/narrativebook_akita'><img src='/img/teaser_akita_division_logo.png' /></a></h3>
            <div class="divisions">
              <div class="division-item">
                <div class="division-frame care">
                  <h4><a href='/narrativebook_akita/care_narrative'><img src='/img/teaser_division_care_logo.png' /></a></h4>
                  <img src="/img/teaser_division_care_figure.png" />
                  <div class="division-caption">
                    地域包括ケア、特に訪問診療やACPなどにかかわる方々を中心とした見守りの活動です。
                  </div>
                </div>
              </div>
              <div class="division-item">
                <div class="division-frame kids">
                  <h4><a href='/narrativebook_akita/kids_narrative'><img src='/img/teaser_division_kids_logo.png' /></a></h4>
                  <img src="/img/teaser_division_kids_figure.png" />
                  <div class="division-caption">
                    医療的ケア児を中心とした、新しい見守りの活動です。
                  </div>
                </div>
              </div>
              <div class="division-item">
                <div class="division-frame other">
                  <img src="/img/teaser_division_other_figure.png" />
                  <div class="division-caption">
                    オンライン診療や日常の記録などの利用でケアナラティブやキッズナラティブの枠に含まれない方。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="division-notify">今までナラティブブック秋田をご利用いただいている方は何も変わることなくご利用いただけます</div>
      </section>
      <section class="new-logo">
        <div class="upper-decoration"></div>
        <h2>新しいロゴマークへの想い</h2>
        <div class="lower-decoration"></div>
        <div class="new-logo-main">
          <div class="new-logo-main-left">
            <img src="/img/teaser_new_logo_main_figure.png" />
          </div>
          <div class="new-logo-main-right">
            <h3>より良い人生を歩む足取り</h3>
            <div class="new-logo-main-caption">
              人々の歩く脚の部分をモチーフにしました。<br/>
              軽快なリズムで歩いている足元は明るい気持ちになります。
            </div>
          </div>
        </div>
        <div class="new-logo-origin">
          <div class="new-logo-ideas">
            <div class="new-logo-idea">
              <div class="new-logo-idea-image">
                <img src="/img/teaser_n_figure.png"/>
              </div>
              <div class="new-logo-idea-caption">
                ナラティブのN
              </div>
            </div>
            <div class="new-logo-idea">
              <div class="new-logo-idea-image">
                <img src="/img/teaser_a_figure.png"/>
              </div>
              <div class="new-logo-idea-caption">
                秋田のA
              </div>
            </div>
            <div class="new-logo-idea">
              <div class="new-logo-idea-image">
                <img src="/img/teaser_yama_figure.png"/>
              </div>
              <div class="new-logo-idea-caption">
                秋田の山々
              </div>
            </div>
          </div>
          <div class="equal"><img src='/img/teaser_new_logo_origin_equal.png' /></div>
          <div class="new-logo-origin-final">
            <img src="/img/teaser_new_logo_origin_final.png" />
            <div class="new-logo-origin-caption">
              新しいロゴマークも<br />
              これまで以上に皆さんに覚えていただければ幸いです
            </div>
          </div>
        </div>
      </section>
    </div>
    <coco-narrative-footer
      :currentStyle="'akita'"/>
  </div>
</template>

<script>
import CocoNarrativeFooter from '@/components/narrativebook/CocoNarrativeFooter.vue'

export default {
  name: 'TeaserPage',

  components: {
    CocoNarrativeFooter
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
@import '~@/assets/css/narrative_akita_title_style.scss';

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: $content-base-color;
  color: $content-base-color;
  font-family: $basic-font-family;
  .main-contents {
    height: 100%;
    overflow: auto;
    background-color: $frame-background-color;
  }
  .top-bar {
      height: 90px;
      display: flex;
      align-items: center;
      background-color: #fff;
      img {
        margin: 0 26px;
      }
    }
  .teaser-page {
    background-color: $frame-background-color;
    color: $content-text-color;
    .hero {
      display: flex;
      padding: 130px 0;
      background-color: $content-text-color;
      .hero-left {
        margin-left: 78px;
        margin-right: 42px;
      }
      .hero-right {
        color: #fff;
        h1 {
          margin: 0;
          margin-bottom: 30px;
          padding: 0;
          font-size: 38px;
          letter-spacing: 0.25em;
        }
        .hero-caption {
          font-size: 14px;
        }
      }
      @include mediaQuery('phone') {
        flex-direction: column;
        align-items: center;
        padding: 40px 10px;
        .hero-left {
          margin: 0;
          padding-bottom: 30px;
          // margin-left: 78px;
          // margin-right: 42px;
        }
        .hero-right {
          color: #fff;
          h1 {
            font-size: 24px;
            letter-spacing: 0.25em;
            text-align: center;
          }
          .hero-caption {
            font-size: 14px;
          }
        }
      }
    }
    .description {
      position: relative;
      margin: 63px;
      padding: 30px 70px;;
      border-radius: 10px;
      background-color: $frame-background-light-color;
      .description-text {
        padding: 30px 0;
        width: 480px;
      }
      .description-figure {
        position: absolute;
        width: 500px;
        top: 130px;
        left: 475px;
      }
      @include mediaQuery('phone') {
        margin: 20px 10px;
        padding: 10px;
        .description-text {
          padding: 30px 10px 0 10px;
          width: auto;
        }
        .description-figure {
          display: none;
        }
      }
    }
    .division {
      position: relative;
      margin: 63px;
      .akita-division {
        padding: 60px 0;
        .akita-division-frame {
          border: 4px solid $content-text-color;
          border-radius: 10px;
          h3 {
            margin: 0 auto;
            margin-top: -30px;
            width: 370px;
            background-color: $frame-background-color;
            text-align: center;
          }
          .divisions {
            display: flex;
            justify-content: center;
            gap: 0 40px;
            padding: 40px;
            color: $care-text-color;
            .division-item {
              margin: 0;
              padding-top: 30px;
              width: 250px;
              .division-frame {
                border: 4px solid $care-main-color;
                border-radius: 10px;
                padding: 20px;
                text-align: center;
                &.care {
                  border-color: $care-main-color;
                }
                &.kids {
                  border-color: $kids-main-color;
                }
                &.other {
                  border: none;
                }
                h4 {
                  margin: 0 auto;
                  margin-top: -40px;
                  margin-bottom: 10px;
                  width: 180px;
                  text-align: center;
                  background-color: $frame-background-color;
                }
                .division-caption {
                  text-align: left;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .division-notify {
        text-align: center;
        font-size: 18px;
      }
      @include mediaQuery('phone') {
        position: relative;
        margin: 10px;
        .akita-division {
          padding: 30px 0;
          .akita-division-frame {
            border: 4px solid $content-text-color;
            border-radius: 10px;
            h3 {
              margin: 0 auto;
              margin-top: -30px;
              width: 260px;
              background-color: $frame-background-color;
              text-align: center;
              img {
                width: 240px;
              }
            }
            .divisions {
              padding: 20px;
              flex-direction: column;
              align-items: center;
              gap: 40px 0;
              color: $care-text-color;
            }
          }
        }
      }
    }
    .new-logo {
      margin: 63px;
      .new-logo-main {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0 50px;
        margin: 40px 0;
        padding: 20px;
        background-color: $frame-background-light-color;
        border-radius: 10px;
        .new-logo-main-right {
          h3 {
            display: inline-block;
            width: auto;
            font-size: 30px;
            font-weight: normal;
            border-bottom: 2px solid $content-text-color;
          }
        }
      }
      .new-logo-origin {
        .new-logo-ideas {
          display: flex;
          justify-content: space-between;
          .new-logo-idea {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            background-color: $frame-background-light-color;
            border-radius: 10px;
            padding: 40px;
            width: 180px;
            height: 180px;
            text-align: center;
            .new-logo-idea-caption {
              padding: 0 10px;
              font-size: 23px;
              border-bottom: 3px solid $content-text-color;
            }
          }
        }
        .equal {
          margin: 60px;
          text-align: center;
        }
        .new-logo-origin-final {
          text-align: center;
          .new-logo-origin-caption {
            padding: 40px;
            font-size: 24px;
            font-weight: bold;
          }
        }
      }
      @include mediaQuery('phone') {
        margin: 10px;
        .new-logo-main {
          gap: 0 10px;
        }
        .new-logo-origin {
          .new-logo-ideas {
            gap: 0 10px;
            .new-logo-idea {
              padding: 10px;
              height: 130px;
              text-align: center;
              .new-logo-idea-image {
                img {
                  width: 50px;
                }
              }
              .new-logo-idea-caption {
                padding: 0 10px;
                font-size: 14px;
                border-bottom: 2px solid $content-text-color;
              }
            }
          }
          .equal {
            margin: 30px;
          }
          .new-logo-origin-final {
            text-align: center;
            img {
              width: 100%;
            }
            .new-logo-origin-caption {
              padding: 20px;
              font-size: 20px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
