<template>
  <main>
    <article>
      <section class="title">
        <h1><span>ナラティブブック秋田</span><span>ソーシャルメディアポリシー</span></h1>
      </section>
      <section class="policy-content">
        <div class="content-box">
          <div class="text-content">
            <div class="chapter">
              <p class="align-right">
                制定年月日&nbsp;2022年11月24日<br/>
                最終改正年月日&nbsp;2022年11月24日<br/>
                一般社団法人地域医療介護連携推進機構<br/>
                代表理事 岡﨑光洋<br/>
              </p>
            </div>
            <div class="chapter">
              <div class="policy-title">1.  目的</div>
              <p>
                本方針は、ナラティブブック秋田のTwitter(@narrativeakita)のアカウント（以下、「ナラティブブック秋田公式Twitter」という。）の運用に関する事項について定める。
              </p>
            </div>
            <div class="chapter">
              <div class="policy-title">2. 基本方針</div>
              <p>
                ナラティブブック秋田公式Twitterは、ナラティブブック秋田の業務、取組み、行事の更新情報等を発信することを通じ、利用者にナラティブブック秋田の理解を深めていただくとともに、利用者の利便性を高めることを目的とする。<br/>
                また、ナラティブブック秋田公式Twitterは、専ら情報発信を行うものとし、原則として、返信等は行わず、意見・問い合わせについては、「ご意見・ご要望の受付(お問い合わせURL)」において受け付ける。
              </p>
            </div>
            <div class="chapter">
              <div class="policy-title">3.  運用方法</div>
              <p>
                ナラティブブック秋田公式Twitterは、一般社団法人秋田県医師会 医療福祉介護連携推進に関する運営委員会（以下、「当委員会」という。）が以下のとおり運用することとする。<br/>
                （1）発信する情報<br/>
                ナラティブブック秋田公式Twitterでは次の情報を発信することとする。
              </p>
              <ul>
                <li>ナラティブブック秋田公式サイトの掲載内容（報道資料等）</li>
                <li>ナラティブブック秋田公式Twitterに関する情報</li>
                <li>ナラティブブック秋田公式Youtubeチャンネルの掲載内容</li>
                <li>ナラティブブック秋田の公式イベントの内容</li>
                <li>その他ナラティブブック秋田で周知する必要のある情報</li>
              </ul>
            </div>
            <div class="chapter">
              <div class="policy-title">4. 免責事項</div>
              <ul>
                <li>ナラティブブック秋田公式Twitterの掲載情報の正確性については万全を期しておりますが、当委員会は利用者がナラティブブック秋田公式Twitterの情報を用いて行う一切の行為について何ら責任を負うものではありません。</li>
                <li>当委員会は、ユーザーにより投稿されたナラティブブック秋田公式Twitterに対する、「リプライ」、「リツイート」、「コメント」等につきまして一切責任を負いません。</li>
                <li>当室は、ナラティブブック秋田公式Twitterに関連して、ユーザー間又はユーザーと第三者間でトラブルや紛争が発生した場合であっても、一切責任を負いません。</li>
                <li>コメント等の投稿にかかる著作権等は、当該投稿を行ったユーザー本人に帰属しますが、投稿されたことをもって、ユーザーは当委員会に対し、投稿コンテンツを全世界において無償で非独占的に使用する権利を許諾したものとし、かつ、当委員会に対して著作権等を行使しないことに同意したものとします。</li>
              </ul>
            </div>
            <div class="chapter">
              <div class="policy-title">5. 利用者による書き込みの削除等</div>
              <p>
                以下の各項に該当する場合、予告なく削除又はアカウントのブロック等を行う場合がありますので、あらかじめご了承ください。
              </p>
              <ul>
                <li>法律、法令等に違反する内容、または違反するおそれがあるもの</li>
                <li>特定の個人・団体等を誹謗中傷するもの</li>
                <li>政治、宗教活動を目的とするもの</li>
                <li>著作権、商標権、肖像権など当省または第三者の知的所有権を侵害するもの</li>
                <li>広告、宣伝、勧誘、営業活動、その他営利を目的とするもの</li>
                <li>人種・思想・信条等の差別または差別を助長させるもの</li>
                <li>公の秩序または善良の風俗に反するもの</li>
                <li>虚偽や事実と異なる内容及び単なる風評や風評を助長させるもの</li>
                <li>本人の承諾なく個人情報を特定・開示・漏えいする等プライバシーを害するもの</li>
                <li>他のユーザー、第三者等になりすますもの</li>
                <li>有害なプログラム等</li>
                <li>わいせつな表現などを含む不適切なもの</li>
                <li>当委員会の発信する内容の一部又は全部を改変するもの</li>
                <li>当委員会の発信する内容に関係ないもの</li>
                <li>その他、当委員会が不適切と判断した情報及びこれらの内容を含むリンク等</li>
              </ul>
            </div>
            <div class="chapter">
              <div class="policy-title">6. 著作権について</div>
              <p>
                ナラティブブック秋田公式Twitterの内容について、私的使用又は引用等著作権法上認められた行為を除き、当委員会に無断で転載等を行うことはできません。 引用等を行う際は適宜の方法により、必ず出所を明示してください。
              </p>
            </div>
            <div class="chapter">
              <div class="policy-title">7. 運用方針の周知・変更等</div>
              <p>
                本方針の内容は当委員会HPに掲載する。また、本方針は必要に応じて事前に告知なく変更するものとする。
              </p>
            </div>
            <div class="end-of-content">以上</div>
          </div>
        </div>
      </section>
    </article>
  </main>
</template>

<script>
export default {
  name: 'SocialMediaPolicy',
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

div.outer-frame {
  height: 100%;
  background-color: $frame-background-color;
  color: $content-text-color;

  a {
    color: $content-text-color;
  }

  main {
    background-image: none;
    background-color: $frame-background-color;
    color: $content-text-color;
    height: auto;
    padding-bottom: 1px;
  }
  section.title {
    margin: 0;
    padding: 0;
    height: auto;
    background-color: $content-text-color;
    background-image: url('~@/assets/css/images/nb_akita_sub_hero.png');
    color: #fff;
    text-align: center;
    h1 {
      margin: 0;
      padding: 10px 0;
      text-align: center;
      font-size: 30px;
      font-weight: 500;
      text-shadow: 1px 1px 3px #000;
      span {
        display: inline-block;
      }
      @include mediaQuery('phone') {
        font-size: 24px;
      }
    }
  }
  .policy-content {
    margin: 10px 50px;
    background-color: transparent;
    @include mediaQuery('phone') {
      margin: 10px;
    }
    .content-box {
      background-color: transparent;
      color: $box-content-color;
      @include mediaQuery('phone') {
        border: none;
      }
      .chapter {
        .policy-title {
          font-weight: bold;
          margin: 10px 10px 5px 10px;
        }
        p {
          margin: 0;
          &.align-right {
            text-align: right;
            font-size: 14px;
          }
        }
      }
      .end-of-content {
        margin: 30px 0 50px;
        text-align: right;
      }
    }
  }

  @include mediaQuery('phone') {
    section.title {
      h1 {
        font-size: 24px;
      }
    }
  }
}

</style>
