<template>
  <span class="top-index" :class="[{open: currentSubMenu === 'manual'}, styleName]" @click="openSubMenu">マニュアル</span>
  <ul class="sub-menu" :class="[{open: currentSubMenu === 'manual'}, styleName]">
    <li v-for="(manualContent, index) in manualMovieList" :key="'mmc' + index" :class="{index: manualContent.type === 'index'}">
      <a :href="manualContent.url" v-if="manualContent.url" target="_blank">
        {{ manualContent.name }}
        <span class="video-jump-mark">▶</span>
      </a>
      <span class="category" v-if="!manualContent.url" v-html="manualContent.name"></span>
    </li>
  </ul>
</template>

<script>
import * as ManualSource from '@/assets/data/manual_movie_list.js'

export default {
  name: 'ManualMovieSubMenu',

  props: {
    currentSubMenu: String,
    styleName: String
  },

  emits: ['openSubMenuEvent'],

  data () {
    return {
      manualMovieList: ManualSource.manualMovieList
    }
  },

  methods: {
    openSubMenu () {
      this.$emit('openSubMenuEvent', 'manual')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
.top-index {
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &.open {
    color: $content-text-color;
  }
  &.care.open {
    color: $care-main-color;
  }
  &.kids.open {
    color: $kids-main-color;
  }
  &.care-content {
    display: none;
  }
  &.kids-content {
    display: none;
  }
}
ul.sub-menu {
  z-index: 1000;
  display: none;
  position: absolute;
  top: 31px;
  left: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-block-start: 0;
  margin-inline-start: 0;
  background-color: $frame-background-color;
  width: 350px;
  max-height: calc(100vh - 150px);
  max-height: calc(100dvh - 150px);
  overflow: auto;
  border: 1px solid $box-content-color;
  border-bottom: none;
  &.tail {
    left: auto;
    right: 0;
  }
  &.open {
    display: block;
  }
  li {
    padding: 5px;
    text-align: left;
    border: none;
    border-bottom: 1px solid $box-content-color;
    .index {
      font-weight: bold;
    }
    a {
      display: inline-block;
      // align-items: center;
      width: calc(100% - 20px);
      height: 100%;
      padding: 5px 10px;
      text-decoration: none;
      color: $box-content-color;
      &.image-link {
        display: inline-flex;
        align-items: center;
      }
      .file-style {
        display: inline-block;
        margin-right: 5px;
        padding: 3px;
        background-color: $content-text-color;
        color: #fff;
        font-size: 10px;
        font-weight: bold;
      }
      .video-jump-mark {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        width: 20px;
        height: 15px;
        font-size: 10px;
        text-align: center;
        border-radius: 2px;
        background-color: $content-text-color;
        color: #fff;
      }
    }
  }
  &.care {
    border: 1px solid $care-text-color;
    li {
      border-bottom: 1px solid $care-text-color;
      a {
        color: $box-content-color;
        .video-jump-mark {
          background-color: $care-main-color;
          color: #fff;
        }
      }
    }
  }
  &.kids {
    background-color: $kids-bg-color;
    border: 1px solid $kids-text-color;
    li {
      border-bottom: 1px solid $kids-text-color;
      a {
        color: $kids-text-color;
        .video-jump-mark {
          background-color: $kids-main-color;
        }
      }
    }
  }
  &.akita-content {
    z-index: auto;
    position: relative;
    top: auto;
    left: auto;
    max-height: none;
    width: auto;
    background-color: transparent;
    border: none;
    li {
      margin-left: 30px;
      padding: 0;
      border: none;
      &.index {
        margin-top: 10px;
        margin-left: 10px;
      }
      a {
        padding: 5px 10px;
        color: $care-text-color;
        .video-jump-mark {
          background-color: $care-main-color;
          color: #fff;
        }
      }
    }
  }
  &.kids-content {
    z-index: auto;
    position: relative;
    top: auto;
    left: auto;
    max-height: none;
    width: auto;
    background-color: transparent;
    border: none;
    li {
      margin-left: 30px;
      padding: 0;
      border: none;
      &.index {
        margin-top: 10px;
        margin-left: 10px;
      }
      a {
        padding: 5px 10px;
        color: $kids-text-color;
        .video-jump-mark {
          background-color: $kids-main-color;
        }
      }
    }
  }
  &.care-content {
    z-index: auto;
    position: relative;
    top: auto;
    left: auto;
    max-height: none;
    width: auto;
    background-color: transparent;
    border: none;
    li {
      margin-left: 30px;
      padding: 0;
      border: none;
      &.index {
        margin-top: 10px;
        margin-left: 10px;
      }
      a {
        padding: 5px 10px;
        color: $care-text-color;
        .video-jump-mark {
          background-color: $care-main-color;
          color: #fff;
        }
      }
    }
  }
}

</style>