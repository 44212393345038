<template>
  <div class="contact-content-block" :class="category">
    <section class="contact inline-scroll">
      <div class="upper-decoration"></div>
      <h2 class="title">お問合せ先</h2>
      <div class="lower-decoration"></div>
      <div class="content">
        <h3>ナラティブブック秋田事業について</h3>
        <p>
          秋田県医師会&nbsp;ナラティブブック秋田&nbsp;<span class="tel">018-833-7401</span>（代表）<br/>
          〒010-0874&nbsp;秋田県秋田市千秋久保田町6番6号（秋田県総合保健センター4階）
        </p>
        <h3>ナラティブブックの使い方について</h3>
        <p>ナラティブブックサポートセンター&nbsp;<span class="tel">050-3188-3610</span></p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ContactContentBlock',

  props: {
    category: String
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
@import '~@/assets/css/narrative_akita_title_style.scss';
.contact-content-block {
  section.contact {
    margin-bottom: 37px;
    .content {
      padding-bottom: 160px;
      background-image: url('~@/assets/css/images/nb_akita_contact_bg.png');
      background-repeat: no-repeat;
      background-position: right bottom;
      h3 {
        margin-top: 24px;
        margin-bottom: 0;
        font-family: $bold-font-family;
        font-size: 18px;
        letter-spacing: 0.2em;
        @include mediaQuery('phone') {
          font-size: 16px;
        }
      }
      p {
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 27px;
        .tel {
          display: inline-block;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 24px;
          line-height: 27px;
        }
      }
    }
  }
  &.kids {
    section.contact {
      .upper-decoration {
        display: none;
      }
      h2 {
        color: $kids-main-color;
      }
      .lower-decoration {
        background-color: $kids-main-color;
      }
      .content {
        background-image: url('~@/assets/css/images/kids_contact_bg.png')
      }
    }
  }
}
</style>
