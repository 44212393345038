<template>
  <main>
    <article>
      <section class="title">
        <h1><span>ナラティブブック秋田</span><span>参加医療介護施設一覧</span></h1>
        <div class="splitter"><img src="/img/white_splitter.png" /></div>
        <div class="sub-title">
          <span>各支部のナラティブブック秋田参加施設一覧です。</span><br />
          <span>それぞれの支部をクリックすると、</span><span>支部のリストに移動します。</span>
        </div>
      </section>
      <section class="navigations">
        <div>
          <span class="area-category" v-for="(area, index) in csvList" :key="'area' + index" @click="displayArea(area)" :class="{current: currentArea === area}">
            {{ area.name }}&nbsp;▶
          </span>
        </div>
      </section>
      <section class="participants block-section" v-show="currentArea.name">
        <h2>{{ currentArea.name }}</h2>
        <div class="splitter">&nbsp;</div>
        <div class="type-selection">
          <span class="org-type" v-for="(orgType, index) in currentArea.middle" :key="'orgType' + currentArea.index + index" @click="displayOrgType(orgType)" :class="{current: currentOrgType === orgType}">
            {{ orgType.name }}
          </span>
        </div>
        <div class="selected-org-type" v-show="currentOrgType.name">
          <h3>{{ currentOrgType.name }}</h3>
          <div class="org-list">
            <div class="organization" v-for="(org, index) in currentOrgType.list" :key="'org' + currentArea.index + currentOrgType.name + index">
              <h4>{{ org.name }}</h4>
              <div class="address">
                <span class="tel">電話 : {{ org.tel }}</span>
                <span>住所 : </span><span>{{ org.address }}</span>&nbsp;<a class="map-link" :href="org.mapURL" target="_blank">マップを表示<img src="/img/link_icon.png" /></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </main>  
</template>

<script>
import * as CSV from '@/assets/data/participant_list.js'

export default {
  name: 'ParticipantList',

  data () {
    return {
      csvList: [],
      currentArea: {},
      currentOrgType: {}
    }
  },

  created () {
    this.readSource()
  },

  methods: {
    readSource () {
      // console.log(CSV.csvData)
      if (CSV.csvData.length > 0) {
        var lines = CSV.csvData.split(/\r?\n/)
        var bigTitles = []
        var bigTitleIndex = 0
        var currentBigTitle = {}
        var currentMiddleTitle = {}
        lines.forEach(line => {
          // console.log(line)
          var row = line.split(',')
          // console.log(row)
          // 基本5列のデータ
          // 1列目: 見出し
          var title = row[0]
          // 2列目: 病院名
          var name = row[1]
          // 3列目: 電話番号
          var tel = row[2]
          // 4列目: 住所
          var address = row[3]
          // 5列目: googleMapURL
          var mapURL = row[4]
          // 1列目に入っていて、2列目以降が空の時は大見出し
          if (!this.isBlank(title) && this.isBlank(name) && this.isBlank(tel) && this.isBlank(address) && this.isBlank(mapURL)) {
            // htmlSource += this.makeBigTitle(title, bigTitleIndex)
            if (currentBigTitle.index > -1) {
              currentBigTitle.middle.push(currentMiddleTitle)
              currentMiddleTitle = {}
              bigTitles.push(currentBigTitle)
            }
            currentBigTitle = { index: bigTitleIndex, name: title, middle: [] }
            bigTitleIndex++
          } else if (!this.isBlank(title) && !this.isBlank(name) && !this.isBlank(tel) && !this.isBlank(address) && !this.isBlank(mapURL)) {
            // 1列目が入っていて、2列目以降も入っているときは1列目を中見出しに
            // console.log(currentMiddleTitle)
            if (currentMiddleTitle.name) {
              currentBigTitle.middle.push(currentMiddleTitle)
            }
            currentMiddleTitle = { name: title, list: [] }
            currentMiddleTitle.list.push({ name: name, tel: tel, address: address, mapURL: mapURL })
          } else if (this.isBlank(title) && !this.isBlank(name) && !this.isBlank(tel) && !this.isBlank(address) && !this.isBlank(mapURL)) {
            // 2列目以降が入っているときは普通のセル
            currentMiddleTitle.list.push({ name: name, tel: tel, address: address, mapURL: mapURL })
          }
        })
        currentBigTitle.middle.push(currentMiddleTitle)
        bigTitles.push(currentBigTitle)
        this.csvList = bigTitles
        // console.log(this.csvList)
      }
    },

    isBlank (str) {
      if (str && str.length > 0) {
        return false
      } else {
        return true
      }
    },

    displayArea (area) {
      this.currentArea = area
      this.currentOrgType = {}
    },

    displayOrgType (orgType) {
      this.currentOrgType = orgType
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

div.outer-frame {
  height: 100%;
  background-color: $frame-background-color;
  color: $content-text-color;

  a {
    color: $content-text-color;
  }

  main {
    background-image: none;
    background-color: $frame-background-color;
    color: $content-text-color;
    height: auto;
    padding-bottom: 1px;
  }
  section.title {
    margin: 0;
    padding: 0;
    height: auto;
    background-color: $content-text-color;
    background-image: url('~@/assets/css/images/nb_akita_sub_hero.png');
    color: #fff;
    text-align: center;
    h1 {
      margin: 0;
      padding: 10px 0 0;
      text-align: center;
      font-size: 30px;
      font-weight: 500;
      text-shadow: 1px 1px 3px #000;
      span {
        display: inline-block;
      }
    }
    div.splitter {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      height: 2px;
    }
    div.sub-title {
      padding-bottom: 10px;
      font-size: 14px;
      color: #fff;
      text-align: center;
      text-shadow: 1px 1px 3px #000;
      span {
        display: inline-block;
      }
    }
  }

  section.block-section {
    margin: 32px 67px;
    padding: 32px 46px;
    background-color: #fff;
    h2 {
      text-align: center;
    }
    h3 {
      text-align: center;
    }
  }
  section.navigations {
    margin: 0 67px;
    padding: 0;
    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .area-category {
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 222px;
        height: 48px;
        margin-top: 24px;
        background-color: $content-text-color;
        color: #fff;
        font-size: 17px;
        &.current {
          border: 1px solid $content-text-color;
          background-color:  #fff;
          color: $content-text-color;
        }
        &.none-area {
          background-color: transparent;
          color: transparent;
        }
      }
    }
  }
  section.participants {
    h2 {
      margin: 0;
      margin-bottom: 5px;
      font-size: 32px;
      font-weight: 300;
      letter-spacing: 0.5em;
      transform: scale(0.7, 1);
    }
    .splitter {
      margin: 0 auto 20px;
      height: 2px;
      width: 100px;
      border-bottom: 2px solid $content-text-color;
    }
    .type-selection {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      .org-type {
        display: inline-block;
        margin: 10px;
        padding: 5px 20px;
        border: 1px solid $content-text-color;
        background-color: #fff;
        color: $content-text-color;
        &.current {
          background-color: $content-text-color;
          color: #fff;
        }
      }
    }
    h3 {
      margin: 0;
      margin-top: 20px;
      text-align: left;
      font-size: 17px;
      font-weight: 600;
      border-bottom: 1px solid $content-text-color;
    }
    .org-list {
      height: calc(100vh - 200px);
      height: calc(100dvh - 200px);
      overflow: auto;
    }
    .organization {
      margin-bottom: 35px;
    }
    h4 {
      margin: 10px 0 5px;
      font-size: 17px;
      font-weight: 300;
    }
    .address {
      span.tel {
        display: inline-block;
        margin-right: 20px;
      }
      a {
        color: $content-text-color;
        text-decoration: none;
        border-bottom: 1px solid $content-text-color;
        font-size: 13px;
      }
    }
  }
  @include mediaQuery('phone') {
    section.title {
      h1 {
        font-size: 24px;
      }
    }

    section.block-section {
      margin: 10px;
      padding: 0;
      background-color: #fff;
      h2 {
        text-align: center;
      }
      h3 {
        text-align: center;
      }
    }
    section.navigations {
      margin: 0;
      padding: 10px 20px 0;
      div {
        .area-category {
          width: auto;
          height: auto;
          margin: 0 0 10px;
          padding: 5px 10px;
          font-size: 15px;
        }
      }
    }
    section.participants {
      h2 {
        padding-top: 10px;
        font-size: 20px;
        transform: scale(1, 1.5);
      }
      .type-selection {
        .org-type {
          margin: 5px;
          padding: 3px 10px;
        }
      }
      .selected-org-type {
        padding: 0 10px;
      }
    }
  }
}
</style>
@/assets/data/participant_list_old.js