<template>
  <div class="youtube-content-block" :class="category">
    <div class="upper-decoration"></div>
    <h2>ナラティブブック秋田公式Youtubeチャンネル</h2>
    <div class="lower-decoration"></div>
    <div class="caption">
      ナラティブブック秋田公式Youtubeチャンネルでは、ナラティブブック秋田とは何なのか、その活動や取り組みについての動画を公開しています。
    </div>
    <youtube-content-list
      :youtubePlaylists="typedPlaylists('promotion')"
      :youtubeMovies="youtubeMovies"
      :category="category"
      />
    <h2>活動記録</h2>
    <youtube-content-list
      :youtubePlaylists="typedPlaylists('report')"
      :youtubeMovies="youtubeMovies"
      :category="category"
      />
    <h2>マニュアル</h2>
    <youtube-content-list
      :youtubePlaylists="typedPlaylists('manual')"
      :youtubeMovies="youtubeMovies"
      :category="category"
      />
  </div>
</template>

<script>
import YoutubeContentList from '@/components/narrativebook/commonContent/YoutubeContentList.vue'
// import * as YoutubeContents from '@/assets/data/youtube_contents.js'
// import * as testcsv from '@/assets/data/content_list.js'

export default {
  name: 'YoutubeContentBlock',

  components:{
    YoutubeContentList
  },

  props: {
    category: String
  },

  data () {
    return {
      youtubePlaylists: [],
      youtubeMovies: [],
      openMovieLists: [],
      csv_content: null
    }
  },

  mounted ()  {
    this.readCSV()
  },

  methods: {
    readCSV () {
      fetch(window.location.origin + '/data/displayContent/content_list.csv').then(res => {
        res.text().then(result => {
          // console.log(result)
          this.parseCSV(result)
        })
      }).catch(err => {
        console.error(err)
      })
    },

    parseCSV (csvText) {
      this.csv_content = this.$papa.parse(csvText)
      var csvArray = this.$papa.parse(csvText)
      console.log(csvArray.data)
      csvArray.data.forEach(line => {
        if (line[3] === 'playlist') {
          this.youtubePlaylists.push({
            type: line[4],
            thumbnailFileName: line[2],
            category: line[5],
            url: line[6],
            playListID: line[8],
            embedSource: line[9],
            title: line[10],
            description: line[11],
            state: line[13]
          })
        } else if (line[3] === 'movie') {
          this.youtubeMovies.push({
            type: line[4],
            thumbnailFileName: line[2],
            category: line[5],
            url: line[6],
            contentID: line[7],
            playListID: line[8],
            title: line[10],
            description: line[11],
            duration: line[12],
            state: line[13]
          })
        }
      })
    },

    typedPlaylists (type) {
      return this.youtubePlaylists.filter(playlist => { return (playlist.type === type) })
    },

    playlistMovies (playlist) {
      return this.youtubeMovies.filter(movie => { return (movie.playListID === playlist.playListID) })
    },

    wrapedText (text) {
      return text.replace(/\n/gi, '<br />')
    },

    movieURL (content) {
      return 'https://youtu.be/' + content.contentID
    },

    embedMovieHTML (content) {
      return '<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' + content.contentID + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
    },

    movieListOpen (playListIndex) {
      if (this.openMovieLists.indexOf(playListIndex) < 0) {
        return false
      } else {
        return true
      }
    },

    toggleMovieList (playListIndex) {
      var index = this.openMovieLists.indexOf(playListIndex)
      if (index < 0) {
        this.openMovieLists.push(playListIndex)
      } else {
        this.openMovieLists.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
@import '~@/assets/css/narrative_akita_title_style.scss';
.youtube-content-block {
  margin: 0;
}
</style>
