<template>
  <main>
    <article>
      <section class="title">
        <h1>ABOUT COCO</h1>
        <h2>COCOについて</h2>
        <nav class="inpage-navigation">
          <ul>
            <li><a href="#about-vision">VISION(目標)</a></li>
            <li><a href="#about-business-content">Business Content(COCOについて)</a></li>
            <li><a href="#about-mission">MISSION(使命)</a></li>
            <li><a href="#about-business-details">Business Details(業務)</a></li>
            <li><a href="#about-organization-overview">Organization Overview(組織概要)</a></li>
          </ul>
        </nav>
      </section>
      <section class="about-vision" id="about-vision">
        <div class="content-box">
          <h2>VISION</h2>
          <h3>目標</h3>
          <div class="splitter-accent">&nbsp;</div>
          <div class="about-vision-content">
            住民が<span class="vision-accent">安心して生きる</span>ことができる<br />
            地域包括ケアシステムの構築
          </div>
        </div>
      </section>
      <section class="about-business-content left-content" id="about-business-content">
        <div class="content-box">
          <h2>Business contents</h2>
          <h3>COCOについて</h3>
          <div class="splitter-accent">&nbsp;</div>
          <div class="text-content">
            <p>
              我が国では団塊の世代が75歳（後期高齢者）になる2025年以降に超高齢化を迎えることから、“地域包括ケアシステム“の構築が進められています。地域包括ケアシステムとは、高齢者が重度な要介護状態となっても住み慣れた地域で自分らしい暮らしを最期まで送ることができるよう、地域（日常生活圏域）一体で支援する体制のことです。そして、“自分らしい暮らし“をするためには、その方の尊厳の保持と自立支援が重要だと考えます。<br/>
              一般社団法人地域医療介護連携推進機構（略称：COCO（ココ））は、そこに住む全ての方が、自分らしい暮らしを選択し、続けられるように、地域の包括的な支援・サービス提供体制を支援する活動を行います。<br/>
              特に、医療や介護従事者の連携活動や研修支援の支援などを通して、住民の皆様の安心した生活が継続できるよう事業に取り組んでまいります。
            </p>
            <a class="back-to-top" href="#navigation-header">TOPへ戻る</a>
          </div>
        </div>
      </section>
      <section class="about-mission right-content" id="about-mission">
        <div class="content-box">
          <h2>MISSION</h2>
          <h3>使命</h3>
          <div class="splitter-accent">&nbsp;</div>
          <div class="text-content">
            <ul>
              <li>住民とその家族が安心して生活できる医療や介護サービスの連携の支援</li>
              <li>地域の特性に合わせて、医療や介護サービスが提供されるために必要な環境の整備</li>
              <li>地域の医療及び介護体制が継続できる体制作りの支援</li>
            </ul>
          </div>
          <a class="back-to-top" href="#navigation-header">TOPへ戻る</a>
        </div>
      </section>
      <section class="about-business-details left-content" id="about-business-details">
        <div class="content-box">
          <h2>Business details</h2>
          <h3>業務</h3>
          <div class="splitter-accent">&nbsp;</div>
          <div class="text-content">
            <ul>
              <li>秋田県医師会が進める“地域医療介護連携事業「秋田県モデル」”の運営支援</li>
              <li>地域包括ケアシステム支援活動「ナラティブブック秋田」運用支援</li>
              <li>災害時医療介護連携支援システムの構築と運用支援</li>
              <li>医療・介護従事者の研修、セミナー等開催及びその支援</li>
              <li>外国人採用の支援</li>
            </ul>
          </div>
          <a class="back-to-top" href="#navigation-header">TOPへ戻る</a>
        </div>
      </section>
      <section class="about-overview" id="about-organization-overview">
        <div class="content-box">
          <h2>Organization overview</h2>
          <h3>組織概要</h3>
          <div class="splitter-accent">&nbsp;</div>
          <div class="text-content">
            <table>
              <tr>
                <th>組織名</th>
                <td>
                  一般社団法人地域医療介護連携推進機構<br/>
                  【英語表記】Cooperation promotion organization for Community medical and care<br/>
                  【略称（呼称）】COCO（ココ）<br/>
                </td>
              </tr>
              <tr>
                <th>理事</th>
                <td>
                  <span class="title">代表理事</span><span class="person">岡﨑光洋</span><br/>
                  <span class="title">理事</span><span class="person">伊藤慎一</span><br/>
                  <span class="title">理事</span><span class="person">齊藤勝俊</span>
                </td>
              </tr>
              <tr><th>設立</th><td>2021年7月1日</td></tr>
              <tr><th>定款</th><td><a href="data/teikan_210525.pdf">一般社団法人地域医療介護連携推進機構 定款</a></td></tr>
              <tr><th>事業所</th><td>〒010-8572 秋田県秋田市山王三丁目１番１号 秋田県庁第二庁舎 3階B-5</td></tr>
              <tr><th>電話</th><td>080-4209-7029</td></tr>
              <tr><th>主要取引銀行</th><td>秋田銀行、北都銀行</td></tr>
              <tr><th>有料職業紹介<br/>許可番号</th><td> 05 - ユ - 300076</td></tr>
            </table>
          </div>
          <a class="back-to-top" href="#navigation-header">TOPへ戻る</a>
        </div>
      </section>
      <inside-footer/>
    </article>
  </main>
</template>

<script>
import InsideFooter from '@/components/CocoCommon/InsideFooter.vue'

export default {
  name: 'AboutContent',

  components: {
    InsideFooter
  },

}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

section.about-vision {
  background-color: transparent;
  background-image: url('~@/assets/css/images/about_vision_bg.png');
  background-repeat: no-repeat;
  padding-top: 107px;
  padding-left: 63px;
  height: calc(532px - 107px);
  .content-box {
    background-color: transparent;
    h2 {
      font-size: 33px;
    }
    h3 {
      margin-top: 2px;
      font-size: 12px;
    }
    .splitter-accent {
      margin-top: 40px;
      margin-bottom: 23px;
    }
    .about-vision-content {
      font-family: "Hiragino Mincho ProN","ヒラギノ明朝 ProN","Hiragino Mincho Pro","ヒラギノ明朝 Pro", "Yu Mincho", "YuMincho", serif;
      font-size: 45px;
      font-weight: 600;
      letter-spacing: 2px;
      .vision-accent {
        color: $accent-color;
      }
    }
  }
  @include mediaQuery('phone') {
    // background-color: transparent;
    background-image: none;
    // background-repeat: no-repeat;
    padding-top: 20px;
    padding-left: 20px;
    height: auto;
    .content-box {
      background-color: transparent;
      border: none;
      h2 {
        font-size: 18px;
      }
      h3 {
        margin-top: 2px;
        font-size: 12px;
      }
      .splitter-accent {
        margin: 10px 0;
      }
      .about-vision-content {
        font-size: 20px;
      }
    }
  }
}

section.about-business-content {
  background-image: url('~@/assets/css/images/about_business_bg.png');
  @include mediaQuery('phone') {
    background-image: none;
  }
}

section.about-mission {
  background-image: url('~@/assets/css/images/about_mission_bg.png');
  @include mediaQuery('phone') {
    background-image: none;
  }
}

section.about-business-details {
  background-image: url('~@/assets/css/images/about_detail_bg.png');
  @include mediaQuery('phone') {
    background-image: none;
  }
}

section.about-overview {
  .content-box {
    padding: 46px 162px 0 60px;
    background-color: transparent;
    h2 {
      font-size: 33px;
      letter-spacing: 2px;
    }
    h3 {
      margin-top: 5px;
    }
    .splitter-accent {
      margin-top: 38px;
      margin-bottom: 87px;
    }
    .text-content {
      table {
        margin: 0;
        margin-bottom: 10px;
        width: 100%;
        border-collapse: collapse;
        border-top: 1px solid $content-base-color;
        th {
          width: 160px;
          text-align: left;
          vertical-align: middle;
          font-size: 20px;
          font-weight: 600;
          border-bottom: 1px solid $content-base-color;
        }
        td {
          padding: 25px 0;
          font-size: 15px;
          font-weight: 200;
          line-height: 26px;
          border-bottom: 1px solid $content-base-color;
          .title {
            display: inline-block;
            width: 5em;
            text-align-last: justify;
          }
          .person {
            display: inline-block;
            margin-left: 20px;
            width: 5em;
            text-align-last: justify;
          }
        }
      }
    }
  }
  @include mediaQuery('phone') {
    .content-box {
      padding: 20px;
      background-color: transparent;
      border: none;
      h2 {
        font-size: 22px;
        letter-spacing: 2px;
      }
      h3 {
        margin-top: 5px;
        font-size: 15px;
      }
      .splitter-accent {
        margin: 20px 0;
      }
      .text-content {
        table {
          margin: 0;
          margin-bottom: 10px;
          width: 100%;
          border-collapse: collapse;
          border-top: 1px solid $content-base-color;
          background-color: rgba($color: #fff, $alpha: 0.5);
          th {
            width: 140px;
            text-align: left;
            vertical-align: middle;
            font-size: 16px;
            font-weight: 600;
            border-bottom: 1px solid $content-base-color;
          }
          td {
            padding: 10px 0;
            font-size: 15px;
            font-weight: 200;
            line-height: 20px;
            border-bottom: 1px solid $content-base-color;
            .title {
              display: inline-block;
              width: 5em;
              text-align-last: justify;
            }
            .person {
              display: inline-block;
              margin-left: 20px;
              width: 5em;
              text-align-last: justify;
            }
          }
        }
      }
    }
  }
}

</style>
