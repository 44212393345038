export const downloadContents = [
  { type: 'index', name: 'パンフレット等' },
  { type: 'content', category: 'care', url: '/data/compressed/care_panf_20221117_ol.pdf', filetype: 'PDF', name: 'ケアナラティブパンフレット(一般利用者) 3.4MB' },
  { type: 'content', category: 'care', url: '/data/compressed/care_panf_org_20221118_ol.pdf', filetype: 'PDF', name: 'ケアナラティブパンフレット(施設) 4MB' },
  { type: 'content', category: 'care', url: '/data/compressed/care_panf_20221117_ol_print_p.pdf', filetype: '印刷用', name: 'ケアナラティブパンフレット(一般利用者) 4.2MB' },
  { type: 'content', category: 'care', url: '/data/compressed/care_panf_org_20221119_ol_print_p.pdf', filetype: '印刷用', name: 'ケアナラティブパンフレット(施設) 4MB' },
  { type: 'content', category: 'care', url: '/data/care_starting_leaf_221013_ol.pdf', filetype: 'PDF', name: 'ケアナラティブスターティングリーフ(一般利用者) 1.7MB' },
  { type: 'content', category: 'kids', url: '/data/compressed/kids_panf_20221118_ol.pdf', filetype: 'PDF', name: 'キッズナラティブパンフレット(一般利用者) 4.6MB' },
  { type: 'content', category: 'kids', url: '/data/compressed/kids_panf_org_20221119_ol.pdf', filetype: 'PDF', name: 'キッズナラティブパンフレット(施設) 4MB' },
  { type: 'content', category: 'kids', url: '/data/compressed/kids_panf_20221118_ol_print_p.pdf', filetype: '印刷用', name: 'キッズナラティブパンフレット(一般利用者) 5.6MB' },
  { type: 'content', category: 'kids', url: '/data/compressed/kids_panf_org_20221119_ol_print_p.pdf', filetype: '印刷用', name: 'キッズナラティブパンフレット(施設) 5MB' },
  { type: 'content', category: 'kids', url: '/data/kids_starting_leaf_221013_ol.pdf', filetype: 'PDF', name: 'キッズナラティブスターティングリーフ(一般利用者) 2.1MB' },
  { type: 'content', category: 'common', url: '/data/org_starting_leaf_221013_ol.pdf', filetype: 'PDF', name: 'スターティングリーフ(施設) 1.6MB' },
  { type: 'index', name: '利用に関する書類' },
  { type: 'content', category: 'common', url: '/data/narrativebook_rule_180323.pdf', filetype: 'PDF', name: 'ナラティブブック秋田 システム利用規約 (593KB)' },
  { type: 'content', category: 'common', url: '/data/narrativebook_akita_operation_rule-ver2.0.pdf', filetype: 'PDF', name: 'ナラティブブック秋田 運用管理規約 (196KB)' },
  { type: 'content', category: 'common', url: '/data/narrativebook_akita_personal_info_1901_190116.pdf', filetype: 'PDF', name: 'ナラティブブック秋田 個人情報保護方針 (183KB)' },
  { type: 'content', category: 'common', url: '/data/narrativebook_organization_request_document221014.docx', filetype: 'MS Word', name: 'ナラティブブック秋田 施設利用申込書 (168KB)' },
  { type: 'content', category: 'common', url: '/data/narrativebook_organization_request_document221014.pdf', filetype: 'PDF', name: 'ナラティブブック秋田 施設利用申込書 (252KB)' },
  { type: 'content', category: 'common', url: '/data/narrativebook_akita_proxy_document.docx', filetype: 'MS Word', name: 'ナラティブブック秋田 委任状 (18KB)' },
  { type: 'content', category: 'common', url: '/data/narrativebook_akita_proxy_document.pdf', filetype: 'PDF', name: 'ナラティブブック秋田 委任状 (213KB)' },
  { type: 'content', category: 'common', url: '/data/personal_information_disclosure_10_190116.pdf', filetype: 'PDF', name: '保有個人情報開示請求書 (151KB)' },
  { type: 'content', category: 'common', url: '/data/personal_information_fixer_10_190116.pdf', filetype: 'PDF', name: '保有個人情報訂正等請求書 (148KB)' },
  { type: 'index', name: 'マニュアル等' },
  { type: 'content', category: 'care', url: '/data/how_to_start_care_narrative_a420230706.pdf', filetype: '印刷用', name: 'ケアナラティブ秋田の始め方 13MB'},
  { type: 'content', category: 'kids', url: '/data/how_to_start_kids_narrative_a420230705.pdf', filetype: '印刷用', name: 'キッズナラティブ秋田の始め方 7.2MB'},
  { type: 'content', category: 'common', url: '/data/user_manual1_create_user_20230629.pdf', filetype: 'PDF', name: '新規利用者登録の流れ（一般利用者）1.1MB'},
  { type: 'content', category: 'common', url: '/data/user_manual2_send_request_20230629.pdf', filetype: 'PDF', name: 'かかりつけ依頼を送信する方法（一般利用者）606KB'},
  { type: 'content', category: 'common', url: '/data/user_manual3_accept_request_20230629.pdf', filetype: 'PDF', name: 'かかりつけ案内承認の流れ（一般利用者）843KB'},
  { type: 'content', category: 'common', url: '/data/user_manual4_usesage_20230615.pdf', filetype: 'PDF', name: 'ナラティブブックの使い方（一般利用者）1.2MB'},
  { type: 'content', category: 'common', url: '/data/user_manual1_create_user_hi_20230725.pdf', filetype: '印刷用', name: '新規利用者登録の流れ（一般利用者）高解像度版 9.6MB'},
  { type: 'content', category: 'common', url: '/data/user_manual2_send_request_hi_20230710.pdf', filetype: '印刷用', name: 'かかりつけ依頼を送信する方法（一般利用者）高解像度版 5.1MB'},
  { type: 'content', category: 'common', url: '/data/user_manual3_accept_request_hi_20230706.pdf', filetype: '印刷用', name: 'かかりつけ案内承認の流れ（一般利用者）高解像度版 6.5MB'},
  { type: 'content', category: 'common', url: '/data/user_manual4_usesage_hi_20230713.pdf', filetype: '印刷用', name: 'ナラティブブックの使い方（一般利用者）高解像度版 1.9MB'},
  { type: 'content', category: 'common', url: '/data/org_manual1_create_flow_20230629.pdf', filetype: 'PDF', name: '施設及び職員登録の流れ 1.1MB'},
  { type: 'content', category: 'common', url: '/data/org_manual2_send_invitation_20230629.pdf', filetype: 'PDF', name: 'かかりつけ案内を送信する方法（施設）659KB'},
  { type: 'content', category: 'common', url: '/data/org_manual3_relation_easy_qr_20230629.pdf', filetype: 'PDF', name: 'かんたんQRによるかかりつけ設定（施設）684KB'},
  { type: 'content', category: 'common', url: '/data/org_manual4_relation_accept_flow_20230629.pdf', filetype: 'PDF', name: 'かかりつけ依頼承認の流れ（施設）657KB'},
  { type: 'content', category: 'common', url: '/data/org_manual5_usage_20230615.pdf', filetype: 'PDF', name: 'ナラティブブックの使い方（施設）1.6MB'},
  { type: 'content', category: 'common', url: '/data/org_manual1_create_flow_hi_20230720.pdf', filetype: '印刷用', name: '施設及び職員登録の流れ 高解像度版 5.7MB'},
  { type: 'content', category: 'common', url: '/data/org_manual2_send_invitation_hi_20230725.pdf', filetype: '印刷用', name: 'かかりつけ案内を送信する方法（施設）高解像度版 4.2MB'},
  { type: 'content', category: 'common', url: '/data/org_manual3_relation_easy_qr_hi_20230726.pdf', filetype: '印刷用', name: 'かんたんQRによるかかりつけ設定（施設）高解像度版 4.7MB'},
  { type: 'content', category: 'common', url: '/data/org_manual4_relation_accept_flow_hi_20230725.pdf', filetype: '印刷用', name: 'かかりつけ依頼承認の流れ（施設）高解像度版 4.2MB'},
  { type: 'content', category: 'common', url: '/data/org_manual5_usage_hi_20230728.pdf', filetype: '印刷用', name: 'ナラティブブックの使い方（施設）高解像度版 3.4MB'},
]
