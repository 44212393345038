<template>
  <section v-if="category == 'kids'">
    <kids-participant-list-content-block />
  </section>
  <div class="participant-list-content-block" :class="category">
    <div class="upper-decoration"></div>
    <h2><span>ナラティブブック秋田</span><span>参加施設一覧</span></h2>
    <div class="lower-decoration"></div>
    <div class="caption">
      <span>各支部のナラティブブック秋田参加施設一覧です。</span><br />
      <span>それぞれの支部をクリックすると、</span><span>支部のリストに移動します。</span>
    </div>
    <div class="list-controlls">
      <h3><span>参加施設</span><span class="info">表示中: {{ displayOrgCount }}件(全{{ organizations.length }}件中)</span></h3>
      <div class="display-type-controll">
        <span class="display-type" :class="{ on: displayType === 'branch' }" @click="switchDisplayType('branch')">支部別</span>
        <span class="display-type" :class="{ on: displayType === 'category' }" @click="switchDisplayType('category')">種別</span>
      </div>
      <div class="visible-switch">
        <h4>表示する支部</h4>
        <span class="visible-elements"
          v-for="(branch, index) in branches" 
          :key="'br' + index" 
          :class="{on: visibleBranches.indexOf(branch) >= 0}"
          @click="toggleVisibleBranch(branch)">
          <img src="/img/check_on_icon.png" class="check-on-img" />
          <img src="/img/check_off_icon.png" class="check-off-img" />
          {{ branch }}
        </span>
        <h4>表示する種別</h4>
        <span class="visible-elements"
          v-for="(category, index) in categories"
          :key="'ctg' + index"
          :class="{on: visibleCategories.indexOf(category) >= 0}"
          @click="toggleVisibleCategory(category)">
          <img src="/img/check_on_icon.png" class="check-on-img" />
          <img src="/img/check_off_icon.png" class="check-off-img" />
          {{ category }}
        </span>
      </div>
    </div><!-- list controll -->
    <div class="organization-list">
      <h3>ナラティブブック参加施設</h3>
      <div class="top-group" v-for="(group, topIndex) in displayOrganizations" :key="'tg' + topIndex">
        <h4 v-show="group.subGroups.length > 0">{{ group.name }}</h4>
        <div class="sub-group" v-for="(subGroup, subIndex) in group.subGroups" :key="'tg' + topIndex + 'sg' + subIndex">
          <div class="sub-group-name" v-show="subGroup.organizations.length > 0">{{ subGroup.name }}</div>
          <div class="organization" v-for="(org, orgIndex) in subGroup.organizations" :key="'tg' + topIndex + 'sg' + subIndex + 'org' + orgIndex">
            <div class="name">{{ org.name }}</div>
            <div class="address">
              <span class="tel">電話 : {{ org.tel }}</span>
              <span>住所 : </span>
              <span>{{ org.address }}</span>&nbsp;<a class="map-link" :href="org.mapURL" target="_blank">Googleマップを表示
                <img src="/img/link_icon.png" v-if="category === 'akita'" />
                <img src="/img/care_link_icon.png" v-if="category === 'care'" />
                <img src="/img/kids_link_icon.png" v-if="category === 'kids'" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as CSV from '@/assets/data/participant_list.js'

import KidsParticipantListContentBlock from '@/components/narrativebook/commonContent/KidsParticipantListContentBlock.vue'

export default {
  name: 'ParticipantListContentBlock',

  components: {
    KidsParticipantListContentBlock
  },

  props: {
    category: String
  },

  data () {
    return {
      // csvList: [],
      displayType: 'branch',
      currentArea: {},
      currentOrgType: {},
      branches: [],
      categories: [],
      visibleBranches: [],
      visibleCategories: [],
      organizations: [],
      displayOrganizations: []
    }
  },

  created () {
    this.readSource()
  },

  computed: {
    displayOrgCount () {
      var count = 0
      this.displayOrganizations.forEach(group => {
        group.subGroups.forEach(subG => {
          count += subG.organizations.length
        })
      })
      return count
    }
  },

  methods: {
    readSource () {
      fetch(window.location.origin + '/data/displayContent/participant_list.csv').then(res => {
        res.text().then(result => {
          // console.log(result)
          this.parseCSV(result)
        })
      }).catch(err => {
        console.error(err)
      })
    },

    parseCSV (csvText) {
      if (csvText.length > 0) {
        var lines = CSV.csvData.split(/\r?\n/)
        // var bigTitles = []
        // var bigTitleIndex = 0
        var currentBigTitle = {}
        var currentMiddleTitle = {}
        lines.forEach(line => {
          // console.log(line)
          var row = line.split(',')
          // console.log(row)
          // 基本5列のデータ
          // 1列目: 見出し
          var title = row[0]
          // 2列目: 病院名
          var name = row[1]
          // 3列目: 電話番号
          var tel = row[2]
          // 4列目: 住所
          var address = row[3]
          // 5列目: googleMapURL
          var mapURL = row[4]
          // 1列目に入っていて、2列目以降が空の時は大見出し
          if (!this.isBlank(title) && this.isBlank(name) && this.isBlank(tel) && this.isBlank(address) && this.isBlank(mapURL)) {
            currentBigTitle = title
            if (this.branches.indexOf(title) < 0) {
              this.branches.push(title)
              this.visibleBranches.push(title)
            }
          } else if (!this.isBlank(title) && !this.isBlank(name) && !this.isBlank(tel) && !this.isBlank(address) && !this.isBlank(mapURL)) {
            // 1列目が入っていて、2列目以降も入っているときは1列目を中見出しに
            currentMiddleTitle = title
            if (this.categories.indexOf(title) < 0) {
              this.categories.push(title)
              this.visibleCategories.push(title)
            }
            this.organizations.push({ name: name, tel: tel, address: address, mapURL: mapURL, branch: currentBigTitle, category: currentMiddleTitle })
          } else if (this.isBlank(title) && !this.isBlank(name) && !this.isBlank(tel) && !this.isBlank(address) && !this.isBlank(mapURL)) {
            // 2列目以降が入っているときは普通のセル
            this.organizations.push({ name: name, tel: tel, address: address, mapURL: mapURL, branch: currentBigTitle, category: currentMiddleTitle })
          }
        })
      }
      this.updateVisibleList()
    },

    isBlank (str) {
      if (str && str.length > 0) {
        return false
      } else {
        return true
      }
    },

    switchDisplayType (type) {
      this.displayType = type
      this.updateVisibleList()
    },

    toggleVisibleBranch (branch) {
      var visibleBranchIndex = this.visibleBranches.indexOf(branch)
      if (visibleBranchIndex < 0) {
        this.visibleBranches.push(branch)
      } else {
        this.visibleBranches.splice(visibleBranchIndex, 1)
      }
      this.updateVisibleList()
    },

    toggleVisibleCategory (category) {
      var visibleCategoryIndex = this.visibleCategories.indexOf(category)
      if (visibleCategoryIndex < 0) {
        this.visibleCategories.push(category)
      } else {
        this.visibleCategories.splice(visibleCategoryIndex, 1)
      }
      this.updateVisibleList()
    },

    updateVisibleList () {
      if (this.displayType === 'branch') {
        this.displayOrganizations = []
        this.branches.forEach(branch => {
          if (this.visibleBranches.indexOf(branch) >= 0) {
            var branchData = { name: branch, subGroups: [] }
            this.categories.forEach(category => {
              if (this.visibleCategories.indexOf(category) >= 0) {
                var categoryData = { name: category, organizations: [] }
                categoryData.organizations = this.organizations.filter(org => { return (org.branch === branch && org.category === category) })
                branchData.subGroups.push(categoryData)
              }
            })
            this.displayOrganizations.push(branchData)
          }
        })
      } else {
        this.displayOrganizations = []
        this.categories.forEach(category => {
          if (this.visibleCategories.indexOf(category) >= 0) {
            var categoryData = { name: category, subGroups: []}
            this.branches.forEach(branch => {
              if (this.visibleBranches.indexOf(branch) >= 0) {
                var branchData = { name: branch, organizations: [] }
                branchData.organizations = this.organizations.filter(org => { return (org.branch === branch && org.category === category) })
                categoryData.subGroups.push(branchData)
              }
            })
            this.displayOrganizations.push(categoryData)
          }
        })
      }
    },

    displayArea (area) {
      this.currentArea = area
      this.currentOrgType = {}
    },

    displayOrgType (orgType) {
      this.currentOrgType = orgType
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
@import '~@/assets/css/narrative_akita_title_style.scss';
.participant-list-content-block {
  .list-controlls {
    h3 {
      font-size: 16px;
      font-weight: 400;
      transform: scale(1, 1.5);
      letter-spacing: 0.3em;
      border-bottom: 1px solid $content-text-color;
      span {
        display: inline-block;
      }
      .info {
        margin-left: 10px;
        font-size: 14px;
      }
    }
    .display-type-controll {
      margin: 20px 0;
      .display-type {
        padding: 5px 10px;
        border: 1px solid $content-text-color;
        width: 150px;
        background-color: #fff;
        color: $content-text-color;
        &.on {
          background-color: $content-text-color;
          color: #fff;
        }
      }
    }
    .visible-switch {
      h4 {
        font-size: 12px;
        font-weight: 400;
        transform: scale(1, 1.5);
        letter-spacing: 0.3em;
        border-bottom: 1px solid $content-text-color;
      }
      .visible-elements {
        display: inline-flex;
        align-items: center;
        margin-right: 30px;
        .check-on-img {
          display: none;
          height: 20px;
          width: 23px;
          margin-right: 5px;
        }
        .check-off-img {
          display: inline;
          height: 20px;
          width: 23px;
          margin-right: 5px;
        }
        &.on {
          .check-on-img {
            display: inline;
          }
          .check-off-img {
            display: none;
          }
        }
      }
    }
  }
  .organization-list {
    h3 {
      margin: 30px 0 20px;
      font-size: 16px;
      font-weight: 400;
      transform: scale(1, 1.5);
      letter-spacing: 0.3em;
      border-bottom: 1px solid $content-text-color;
    }
    .top-group {
      h4 {
        padding: 5px 10px;
        font-size: 13px;
        font-weight: 400;
        transform: scale(1, 1.5);
        letter-spacing: 0.3em;
        background-color: $content-text-color;
        color: #fff;
      }
      .sub-group {
        background-color: #fff;
        .sub-group-name {
          margin: 10px 0;
          padding: 5px 10px;
          background-color: #fff;
          color: $content-text-color;
          border-bottom: 1px solid $content-text-color;
          font-size: 13px;
          font-weight: 400;
          transform: scale(1, 1.5);
          letter-spacing: 0.3em;
        }
        .organization {
          padding: 5px 10px;
          color: $care-text-color;
          border-bottom: 1px solid $care-text-color;
          .name {
            font-weight: 500;
            font-size: 16px;
          }
          .address {
            font-size: 14px;
            span {
              display: inline-block;
            }
            .tel {
              margin-right: 20px;
            }
            a.map-link {
              display: inline-flex;
              align-items: center;
              margin-left: 10px;
              color: $content-text-color;
            }
          }
        }
      }
    }
  }
  &.care {
    .list-controlls {
      h3 {
        border-bottom: 1px solid $care-main-color;
      }
      .display-type-controll {
        margin: 20px 0;
        .display-type {
          border: 1px solid $care-main-color;
          color: $care-main-color;
          &.on {
            background-color: $care-main-color;
            color: #fff;
          }
        }
      }
      .visible-switch {
        h4 {
          border-bottom: 1px solid $care-main-color;
        }
      }
    }
    .organization-list {
      h3 {
        margin: 30px 0 20px;
        font-size: 16px;
        font-weight: 400;
        transform: scale(1, 1.5);
        letter-spacing: 0.3em;
        border-bottom: 1px solid $care-main-color;
      }
      .top-group {
        h4 {
          background-color: $care-main-color;
          color: #fff;
        }
        .sub-group {
          .sub-group-name {
            color: $care-main-color;
            border-bottom: 1px solid $care-main-color;
          }
          .organization {
            color: $care-text-color;
            border-bottom: 1px solid $care-text-color;
            .address {
              a.map-link {
                color: $care-main-color;
              }
            }
          }
        }
      }
    }
  }
  &.kids {
    .list-controlls {
      h3 {
        border-bottom: 1px solid $kids-main-color;
      }
      .display-type-controll {
        margin: 20px 0;
        .display-type {
          border: 1px solid $kids-main-color;
          color: $kids-main-color;
          &.on {
            background-color: $kids-main-color;
            color: #fff;
          }
        }
      }
      .visible-switch {
        h4 {
          border-bottom: 1px solid $kids-main-color;
        }
      }
    }
    .organization-list {
      h3 {
        margin: 30px 0 20px;
        font-size: 16px;
        font-weight: 400;
        transform: scale(1, 1.5);
        letter-spacing: 0.3em;
        border-bottom: 1px solid $kids-main-color;
      }
      .top-group {
        h4 {
          background-color: $kids-main-color;
          color: #fff;
        }
        .sub-group {
          .sub-group-name {
            color: $kids-main-color;
            border-bottom: 1px solid $kids-main-color;
          }
          .organization {
            color: $kids-text-color;
            border-bottom: 1px solid $kids-text-color;
            .address {
              a.map-link {
                color: $kids-main-color;
              }
            }
          }
        }
      }
    }
  }
}
</style>
@/assets/data/participant_list_old.js