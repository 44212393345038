<template>
  <main>
    <article>
      <section class="main-navigation">
        <narrative-akita-main-navigation
          :navigationContents="slideContents"
          v-on:contentSelectEvent="slideContentSelected"
          />
      </section>
      <div class="main-content">
        <section class="what-nb-akita inline-scroll" v-show="currentContent == 'main'">
          <h2 class="title"><img src="/img/nbakita_title_what.png" alt="ナラティブブック秋田とは" /></h2>
          <p>
            <span>ナラティブブック秋田は、</span><span>あなたがどのように暮らしたいかを</span><br/>
            <span>あなたを見守る人たちと</span><span>共有するための仕組みです。</span><br/>
            <span>身近に、あるいは遠くにいるご家族。</span><br/>
            <span>病気のことを看てくれている医療に関係する人。</span><br/>
            <span>身の回りのことを手伝ってくれる</span><span>介護に関係する人。</span><br/>
            <span>そのほかにもさまざまな人々が</span><span>あなたを見守っているでしょう。</span><br/>
            <span>あなたと、あなたを見守る人たちが</span><br/>
            <span>あなたにかかわる様々なことを</span><span>ナラティブブックに書き込みます。</span><br/>
            <span>体調のこと、病気のこと、</span><span>お薬のこと、嬉しかったこと、</span><br/>
            <span>楽しかったこと、つらかったこと、</span><span>何気ない日々の暮らしのこと。</span><br/>
            <span>その書き込みのつながりこそが</span><br/>
            <span>ナラティブ＝あなたのものがたりです。</span><br/>
          </p>
          <div class="caption-figure">
            <img src="/img/what-nb-fig1.png" alt="「日々の暮らしのこと」をナラティブブックに書き込む" />
            <img src="/img/what-nb-fig2.png" alt="「体調・病気のこと」をナラティブブックに書き込む" />
            <img src="/img/what-nb-fig3.png" alt="「楽しかったこと、つらかったこと、何気ない日々の暮らしのこと」をナラティブブックに書き込む" />
          </div>
          <p>
            <span>あなたのものがたりを、</span><span>あなたを見守る人に見ていただき</span><br/>
            <span>あなたが何を望み、</span><span>どう暮らしていきたいかを知って</span><br/>
            <span>あなたの思いに寄り添ってもらう事ができます。</span><br/>
            <span>ナラティブブック秋田は、</span><span>あなたが、あなたを見守る人たちとともに</span><br/>
            <span>あなたらしく暮らしていくお手伝いをします。</span><br/>
          </p>
        </section>
        <section class="activities-of-nb-akita inline-scroll" v-show="currentContent == 'main'">
          <h2 class="title"><img src="/img/nbakita_title_activity.png" alt="ナラティブブック秋田のできること" /></h2>
          <div class="part">
            <img class="activities-fig1" src="/img/activities-fig1.png" alt="ささえる人たち"/>
            <p>
              ナラティブブック秋田は、情報共有ツールである「ナラティブブック」を利用した、地域に暮らす人々と、それを見守る人をつなぐ、情報共有の仕組みです。<br/>
              情報共有ツール「ナラティブブック」は、利用する当事者本人の情報を、本人管理のもと、家族、医療介護従事者の間で共有する事ができます。
            </p>
          </div>
          <div class="part">
            <img class="activities-fig2" src="/img/activities-fig2.png" alt="ナラティブブック秋田でつながる人々"/>
          </div>
          <div class="part">
            <p>スマホやパソコンから投稿した情報は、本人が許可した家族・医療福祉介護関係者など、本人を見守る人々とタイムリーに共有できます。病気や治療のことだけでなく、日頃の生活や考え、想いを、みんなで確認し、本人の想いに寄り添う最適な医療や介護サービスにつなげることができるようになります。</p>
            <img class="activities-fig3" src="/img/activities-fig3.png" alt="ともに歩む人々" />
          </div>
        </section>
        <section class="groups inline-scroll" v-show="currentContent == 'main'">
          <h2 class="title">ナラティブブック秋田の活動</h2>
          <p>
            秋田の土地で、みなさんが暮らしていくお手伝いをさせていただいているナラティブブック秋田ですが、お手伝いの幅を広げるために、活動を区分けしました。<br/>
            比較的ご高齢の方や在宅医療を受けておられる方を対象とした、地域包括ケアやACPに係る活動を「ケアナラティブ秋田」、お子さんやその保護者の方を対象として、医療的ケア児にかかわる活動を「キッズナラティブ秋田」としてはじめます。<br/>
            「ナラティブブック秋田」は、ケアナラティブ秋田とキッズナラティブ秋田を統括するものとして、全体に関わってゆきます。<br/>
            今までナラティブブック秋田をご利用いただいている方は、何も変わることなくご利用いただけます。
          </p>
          <section class="care-narrative-akita">
            <h2><img src="/img/care_narrative_box_logo.png" alt="ケアナラティブ秋田" /></h2>
            <p>ケアナラティブ秋田は、ナラティブブック秋田の取り組みのうち、地域包括ケア、特に訪問診療やACPなどにかかわる方々を中心とした見守りの活動です。</p>
            <div class="link-buttons">
              <router-link to="/narrativebook_akita/care_narrative" class="information-link-btn">詳しくはこちら&nbsp;▶</router-link>
            </div>
          </section>
          <section class="kids-narrative-akita">
            <h2><img src="/img/kids_narrative_box_logo.png" alt="キッズナラティブ秋田" /></h2>
            <p>キッズナラティブ秋田は、ナラティブブック秋田の取り組みのうち、医療的ケア児を中心とした、新しい見守りの活動です。</p>
            <div class="link-buttons">
              <router-link to="/narrativebook_akita/kids_narrative" class="information-link-btn">詳しくはこちら&nbsp;▶</router-link>
            </div>
          </section>
          <section class="online-medical-care">
            <h2><span>秋田県オンライン診療</span><span>実証事業</span></h2>
            <p>秋田県におけるへき地・無医地区等における医療のかかり方について、オンラインでの診療を活用したモデルの実証事業を行っています。</p>
            <div class="link-buttons">
              <router-link to="/narrativebook_akita/online_medical" class="information-link-btn">詳しくはこちら&nbsp;▶</router-link>
            </div>
          </section>
        </section>
        <section v-show="currentContent == 'main'">
          <how-to-start-content-block
            :category="'akita'"
            />
        </section>
        <section v-show="currentContent == 'documents'">
          <document-content-block
            :category="'akita'"
            />
        </section>
        <section v-show="currentContent == 'documents'">
          <manual-movie-content-block
            :category="'akita'"
            />
        </section>
        <section v-show="currentContent == 'signin'">
          <phone-app-content-block
            :category="'akita'"
            />
        </section>
        <section v-show="currentContent == 'event'">
          <event-content-block
            :category="'akita'"
            />
        </section>
        <section v-show="currentContent == 'orglist'">
          <participant-list-content-block
            :category="'akita'"
            />
        </section>
        <section v-if="currentContent == 'youtube'">
          <youtube-content-block
            :category="'akita'"
            />
        </section>
      </div>
    </article>
  </main>
</template>

<script>
import NarrativeAkitaMainNavigation from '@/components/narrativebook/NarrativeAkitaMainNavigation.vue'
import DocumentContentBlock from '@/components/narrativebook/commonContent/DocumentContentBlock.vue'
import ManualMovieContentBlock from '@/components/narrativebook/commonContent/ManualMovieContentBlock.vue'
import EventContentBlock from '@/components/narrativebook/commonContent/EventContentBlock.vue'
import HowToStartContentBlock from '@/components/narrativebook/commonContent/HowToStartContentBlock.vue'
import ParticipantListContentBlock from '@/components/narrativebook/commonContent/ParticipantListContentBlock.vue'
import YoutubeContentBlock from '@/components/narrativebook/commonContent/YoutubeContentBlock.vue'
import PhoneAppContentBlock from '@/components/narrativebook/commonContent/PhoneAppContentBlock.vue'

export default {
  name: 'NarrativebookContent',

  components: {
    // NarrativeAkitaCarouselNavitation,
    NarrativeAkitaMainNavigation,
    DocumentContentBlock,
    ManualMovieContentBlock,
    EventContentBlock,
    HowToStartContentBlock,
    ParticipantListContentBlock,
    YoutubeContentBlock,
    PhoneAppContentBlock
  },

  watch: {
    '$route': { handler: 'switchContentByPath', immediate: true },
  },

  data () {
    return {
      currentContent: 'main',
      slideContents: [
        {
          title: 'ナラティブブック秋田とは',
          description: '秋田県医師会が進める『患者さんや生活者が、“自分らしい暮らし”を続けられるよう、医療や介護に係わる方々が連携して支援する活動』です。',
          img: '/img/carousel_imgs/carousel_na_top.png',
          thumbImg: '/img/carousel_imgs/carousel_na_top.png',
          phoneImg: '/img/carousel_imgs/carousel_na_top.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_na_top.png',
          linkURL: 'main'
        },
        {
          title: '資料・マニュアル',
          description: '秋田県医師会が進める『患者さんや生活者が、“自分らしい暮らし”を続けられるよう、医療や介護に係わる方々が連携して支援する活動』です。',
          img: '/img/carousel_imgs/carousel_kids_orgs.png',
          thumbImg: '/img/carousel_imgs/carousel_kids_orgs.png',
          phoneImg: '/img/carousel_imgs/carousel_kids_orgs.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_kids_orgs.png',
          linkURL: 'documents'
        },
        {
          title: 'イベントスケジュール',
          description: '秋田県医師会が進める『患者さんや生活者が、“自分らしい暮らし”を続けられるよう、医療や介護に係わる方々が連携して支援する活動』です。',
          img: '/img/carousel_imgs/carousel_kids_event.png',
          thumbImg: '/img/carousel_imgs/carousel_kids_event.png',
          phoneImg: '/img/carousel_imgs/carousel_kids_event.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_kids_event.png',
          linkURL: 'event'
        },
        {
          title: 'ログイン',
          description: '秋田県医師会が進める『患者さんや生活者が、“自分らしい暮らし”を続けられるよう、医療や介護に係わる方々が連携して支援する活動』です。',
          img: '/img/carousel_imgs/carousel_care_app.png',
          thumbImg: '/img/carousel_imgs/carousel_care_app.png',
          phoneImg: '/img/carousel_imgs/carousel_care_app.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_care_app.png',
          linkURL: 'signin'
        },
        {
          title: '施設リスト',
          description: '秋田県医師会が進める『患者さんや生活者が、“自分らしい暮らし”を続けられるよう、医療や介護に係わる方々が連携して支援する活動』です。',
          img: '/img/carousel_imgs/carousel_care_contact.png',
          thumbImg: '/img/carousel_imgs/carousel_care_contact.png',
          phoneImg: '/img/carousel_imgs/carousel_care_contact.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_care_contact.png',
          linkURL: 'orglist'
        },
        {
          title: '動画アーカイブ',
          description: '秋田県医師会が進める『患者さんや生活者が、“自分らしい暮らし”を続けられるよう、医療や介護に係わる方々が連携して支援する活動』です。',
          img: '/img/carousel_imgs/carousel_care_youtube.png',
          thumbImg: '/img/carousel_imgs/carousel_care_youtube.png',
          phoneImg: '/img/carousel_imgs/carousel_care_youtube.png',
          phoneThumbImg: '/img/carousel_imgs/carousel_care_youtube.png',
          linkURL: 'youtube'
        },
      ]
    }
  },

  methods: {
    slideContentSelected (selected) {
      console.log(selected)
      // this.currentContent = selected
      this.$router.push({path: '/narrativebook_akita/' + selected })
    },

    switchContentByPath () {
      console.log(this.$route.params)
      if (this.$route.params.content) {
        this.$route.params.content.forEach(pathContent => {
          console.log(pathContent)
          var content = this.slideContents.find((cont) => cont.linkURL == pathContent)
          if (content) {
            this.currentContent = content.linkURL
          }
        })
      } else {
        this.currentContent = 'main'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

div.outer-frame {
  height: 100%;
  background-color: $frame-background-color;
  color: $content-text-color;

  a {
    color: $content-text-color;
  }

  main {
    background-image: none;
    background-color: $frame-background-color;
    color: $content-text-color;
    height: auto;
    article {
      height: auto;
      section {
        padding: 0 132px;
        h2.title {
          margin: 55px auto;
          text-align: center;
        }
        p {
          font-size: 15px;
          line-height: 28px;
          span {
            display: inline-block;
          }
        }
      }
      section.main-navigation {
        margin: 0;
        padding: 0;
      }
      section.hero {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        background-image: url('~@/assets/css/images/nb_akita_hero_bg.png');
        background-color: $content-text-color;
        height: 432px;
        .hero-text-column {
          h1.hero-title {
            margin: 0;
            padding: 72px 0 0 94px;
            color: #fff;
            font-size: 44px;
            line-height: 73px;
            letter-spacing: 0.14em;
            font-weight: 600;
            text-shadow: 1px 1px 3px #000;
          }
          div.hero-caption {
            margin-left: 94px;
            font-size: 16px;
            color: #fff;
            text-shadow: 1px 1px 3px #000;
            span {
              display: inline-block;
            }
          }
        }
      }
      .main-content {
        // height: calc(100vh - 80px - 139px);
        // height: calc(100dvh - 80px - 139px);
        // height: 100vh;
        // height: 100dvh;
        overflow: auto;
        margin: 0;
        padding: 0;
        // padding-bottom: 55px;
      }
      section.what-nb-akita {
        padding-bottom: 151px;
        background-image: url('~@/assets/css/images/nb_akita_what_bg_l.png'), url('~@/assets/css/images/nb_akita_what_bg_r.png'), url('~@/assets/css/images/nb_akita_what_foot_bg.png');
        background-repeat: no-repeat, no-repeat, no-repeat;
        background-position: 0px 108px, right 151px, right bottom;
        p {
          font-size: 15px;
          line-height: 37px;
          text-align: center;
        }
        .caption-figure {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: inline-block;
            margin: 0 50px;
          }
        }
      }
      section.activities-of-nb-akita {
        .part {
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            font-size: 15px;
            line-height: 28px;
          }
          img {
            display: inline-block;
            &.activities-fig1 {
              margin-right: 82px;
            }
            &.activities-fig2 {
              margin: 65px 0 85px 0;
            }
            &.activities-fig3 {
              margin-left: 62px;
            }
          }
        }
      }
      section.other-information {
        h2.title {
          margin-bottom: 27px;
        }
        p {
          margin: 12px 0;
        }
        .link-buttons {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          &.app-link {
            justify-content: flex-start;
          }
          a.information-link-btn {
            padding: 0 24px;
            display: flex;
            align-items: center;
            height: 45px;
            background-color: $content-text-color;
            color: #fff;
            text-decoration: none;
            &.nb-login {
              background-color: #fff;
              border: 1px solid $content-text-color;
              color: $content-text-color;
              .nb-icon {
                margin-right: 5px;
                height: 25px;
                width: 25px;
              }
            }
          }
          a img {
            &.google-badge {
              height: 62px;
            }
            &.apple-badge {
              padding: 10px;
              height: 42px;
            }
          }
        }
        h3 {
          margin: 20px 0 16px;
          font-size: 20px;
          font-weight: 600;
        }
        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          margin-block-start: 0;
          margin-inline-start: 0;
          li {
            padding-bottom: 10px;
            span.file-style {
              display: inline-block;
              margin-left: 20px;
              padding: 2px 5px;
              border: 1px solid $content-text-color;
              font-size: 12px;
              background-color: $content-text-color;
              color: #fff;
            }
            span.caption {
              display: inline-block;
              font-size: 12px;
              line-height: 14px;
            }
            a.file-download {
              display: inline-block;
              margin: 0 10px;
              text-decoration: none;
              border-bottom: 1px solid $content-text-color;
            }
          }
        }
      }
      section.groups {
        p {
          font-size: 15px;
          line-height: 37px;
          // text-align: center;
        }
      }
      section.care-narrative-akita {
        // margin: 0 132px 37px;
        margin-bottom: 37px;
        padding: 38px 41px;
        background-image: 
          url('~@/assets/css/images/care_bg_tl.png'),
          url('~@/assets/css/images/care_bg_tm.png'),
          url('~@/assets/css/images/care_bg_tr.png'),
          url('~@/assets/css/images/care_bg_bl.png'),
          url('~@/assets/css/images/care_bg_bm.png'),
          url('~@/assets/css/images/care_bg_br.png'),
          url('~@/assets/css/images/care_bg_ml.png'),
          url('~@/assets/css/images/care_bg_mr.png');
        background-repeat: 
          no-repeat, 
          no-repeat, 
          no-repeat, 
          no-repeat, 
          no-repeat, 
          no-repeat,
          repeat-y, 
          repeat-y;
        background-position: 
          left top, 
          center top, 
          right top, 
          left bottom, 
          center bottom, 
          right bottom,
          center left, 
          center right; 
        background-color: #fff;
        h2 {
          margin: 0;
        }
        p {
          margin-top: 25px;
          margin-bottom: 8px;
          font-size: 15px;
          line-height: 27px;
          color: $box-content-color;
        }
        .link-buttons {
          display: flex;
          justify-content: flex-end;
          a.information-link-btn {
            padding: 0 24px;
            display: flex;
            align-items: center;
            height: 45px;
            background-color: $content-text-color;
            color: #fff;
            text-decoration: none;
          }
        }
      }
      section.kids-narrative-akita {
        // margin: 0 132px 37px;
        margin-bottom: 37px;
        padding: 38px 41px;
        background-image: 
          url('~@/assets/css/images/kids_bg_tl.png'),
          url('~@/assets/css/images/kids_bg_tm.png'),
          url('~@/assets/css/images/kids_bg_tr.png'),
          url('~@/assets/css/images/kids_bg_bl.png'),
          url('~@/assets/css/images/kids_bg_bm.png'),
          url('~@/assets/css/images/kids_bg_br.png'),
          url('~@/assets/css/images/kids_bg_ml.png'),
          url('~@/assets/css/images/kids_bg_mr.png');
        background-repeat: 
          no-repeat, 
          no-repeat, 
          no-repeat, 
          no-repeat, 
          no-repeat, 
          no-repeat,
          repeat-y, 
          repeat-y;
        background-position: 
          left top, 
          center top, 
          right top, 
          left bottom, 
          center bottom, 
          right bottom,
          center left, 
          center right; 
        background-color: #fff;
        h2 {
          margin: 0;
        }
        p {
          margin-top: 25px;
          margin-bottom: 8px;
          font-size: 15px;
          line-height: 27px;
          color: $box-content-color;
        }
        .link-buttons {
          display: flex;
          justify-content: flex-end;
          a.information-link-btn {
            padding: 0 24px;
            display: flex;
            align-items: center;
            height: 45px;
            background-color: $kids-main-color;
            color: #fff;
            text-decoration: none;
          }
        }
      }
      section.online-medical-care {
        // margin: 0 132px 37px;
        margin-bottom: 37px;
        padding: 38px 41px;
        background-image: 
          url('~@/assets/css/images/online_bg_tl.png'),
          url('~@/assets/css/images/online_bg_tm.png'),
          url('~@/assets/css/images/online_bg_tr.png'),
          url('~@/assets/css/images/online_bg_bl.png'),
          url('~@/assets/css/images/online_bg_bm.png'),
          url('~@/assets/css/images/online_bg_br.png'),
          url('~@/assets/css/images/online_bg_ml.png'),
          url('~@/assets/css/images/online_bg_mr.png');
        background-repeat: 
          no-repeat, 
          no-repeat, 
          no-repeat, 
          no-repeat, 
          no-repeat, 
          no-repeat,
          repeat-y, 
          repeat-y;
        background-position: 
          left top, 
          center top, 
          right top, 
          left bottom, 
          center bottom, 
          right bottom,
          center left, 
          center right; 
        background-color: #fff;
        h2 {
          margin: 0;
          color: $online-main-color;
          font-size: 27px;
          font-family: $basic-font-family;
          font-weight: 400;
        }
        p {
          margin-top: 25px;
          margin-bottom: 8px;
          font-size: 15px;
          line-height: 27px;
          color: $box-content-color;
        }
        .link-buttons {
          display: flex;
          justify-content: flex-end;
          a.information-link-btn {
            padding: 0 24px;
            display: flex;
            align-items: center;
            height: 45px;
            background-color: $online-main-color;
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }
  }
  footer.content-footer {
    padding: 20px 0;
    border-top: 1px solid $content-text-color;
    text-align: center;
    font-size: 13px;
    address {
      font-size: 11px;
      font-style: normal;
    }
    nav {
      display: flex;
      justify-content: center;
      ul.footer-menu {
        margin: 0 auto;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-block-start: 0;
        margin-inline-start: 0;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 6px;
          width: 100px;
          height: 26px;
          font-size: 11px;
          text-align: center;
          &.splitter {
            width: 1px;
            height: 26px;
            margin: 0;
            border-left: 1px solid $content-text-color;
          }
          a {
            color: $content-text-color;
            text-decoration: none;
          }
        }
      }
    }
  }
  @include mediaQuery('phone') {
    main {
      article {
        section {
          padding: 0 20px;
          h2.title {
            margin: 22px auto;
            text-align: center;
          }
          p {
            font-size: 15px;
            line-height: 18px;
          }
        }
        section.hero {
          flex-direction: column;
          background-position: right -175px top 0;
          height: auto;
          .hero-text-column {
            h1.hero-title {
              padding: 20px;
              font-size: 24px;
              line-height: 30px;
            }
            div.hero-caption {
              margin-left: 20px;
            }
          }
          .hero-nav-column {
            width: 100%;
            .inline-nav {
              ul {
                li {
                  background-color: rgba($color: #fff, $alpha: .7);
                }
              }
            }
          }
          &.compress {
            background-position: right -175px top 0;
            .hero-nav-column {
              .inline-nav {
                ul {
                  flex-direction: column;
                }
              }
            }
          }
        }
        // .main-content {
        //   height: 100vh;
        //   height: 100dvh;
        // }
        section.what-nb-akita {
          padding-bottom: 20px;
          background-image: none;
          p {
            margin-top: 0;
            font-size: 15px;
            line-height: 24px;
          }
          .caption-figure {
            display: none;
          }
        }
        section.activities-of-nb-akita {
          h2 img {
            width: 100%;
          }
          .part {
            display: flex;
            img {
              &.activities-fig1 {
                display: none;
              }
              &.activities-fig2 {
                margin: 30px 0 30px 0;
                width: 100%;
              }
              &.activities-fig3 {
                display: none;
              }
            }
          }
        }
        section.other-information {
          .link-buttons {
            flex-wrap: wrap;
            a.information-link-btn {
              margin-bottom: 10px;
              padding: 0 10px;
              font-size: 14px;
            }
          }
        }
        section.groups {
          p {
            font-size: 15px;
            line-height: 24px;
          }
        }
        section.care-narrative-akita {
          h2 {
            img {
              width: 100%;
            }
          }
        }
        section.kids-narrative-akita {
          margin-bottom: 37px;
          h2 {
            img {
              width: 100%;
            }
          }
        }
        section.online-medical-care {
          margin-bottom: 37px;
          h2 {
            font-size: 24px;
          }
        }
      }
    }
    footer.content-footer {
      padding: 20px 0;
      border-top: 1px solid $content-text-color;
      text-align: center;
      font-size: 13px;
      address {
        font-size: 11px;
        font-style: normal;
      }
      nav {
        display: flex;
        justify-content: center;
        ul.footer-menu {
          margin: 0 auto;
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          list-style-type: none;
          margin-block-start: 0;
          margin-inline-start: 0;
          li {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 6px;
            width: 100px;
            height: 26px;
            font-size: 11px;
            text-align: center;
            &.splitter {
              width: 1px;
              height: 26px;
              margin: 0;
              border-left: 1px solid $content-text-color;
            }
            a {
              color: $content-text-color;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

</style>
