<template>
  <main>
    <article>
      <section class="title">
        <h1>SUPPORT FOR FREIGN RECRUITMENT</h1>
        <h2>外国人採用の支援</h2>
      </section>
      <section class="top-content">
        <h2>アジアの人々と地域をつなぐ</h2>
        <div class="small-caption">
          ●外国人材採用の支援（有料職業紹介）<br/>
          ●有料職業紹介（厚生労働大臣許可番号 05-ユ-300076）
        </div>
        <div class="main-caption">
          優秀な人材を見つけ、確保することはこれまで以上に難しくなっています。
          地域医療介護連携推進機構は、医療機関、福祉・介護施設と質の高い人材との架け橋となり、経歴やスキルに沿った最適な仕事をご紹介しています。
          <div class="main-content-strong">外国人採用３つのメリット</div>
          <ol>
            <li>若い労働力が確保できる</li>
            <li>地方の職場でも採用しやすい</li>
            <li>離職率が低い</li>
          </ol>
          秋田県、東北地域の医療機関、福祉・介護施設で若い労働力を確保するために、ぜひ弊社にお問い合わせください。
        </div>
        <div class="hoge-content">
          <h3>地域に根ざした人材紹介サービスを展開しております。</h3>
          地域医療介護連携推進機構では、ANCジャパンと提携し、海外提携取次機関や学校と連携し、優秀な人材をご紹介します。<br/>
          <a href="/data/jinzai240401.pdf">●ちらし(202401版)</a>
          <h4>■パートナー</h4>
          <a href="https://anc-japan.com/">株式会社ANCジャパン</a><br/>
          インドネシアの看護学校とのコンソーシアムにより、2024年は180名の特定技能・介護人材を育成し、日本への就労を支援している会社です。
          <ol>
            <li>インドネシアの現地教育機関・送り出し機関であるKEBUNの知名度は現地トップクラス。</li>
            <li>KEBUNは現地の複数の看護大学との提携により、日本で就労を目指す人材が増えています。</li>
            <li>KEBUNはANCジャパンのグループ会社であり、シームレスな連携により日本からの求人情報に対し的確に、迅速に対応することができます。</li>
            <li>ANCジャパンは「登録支援機関」として、入社後のフォローも万全に対応することができます。</li>
            <li>ANCジャパンには日本国内にN1インドネシアスタッフが複数人在籍しており、日本に就職した人材との高いコミュニケーションを行います。</li>
            <li>キャリアパスのサポートも可能です。</li>
          </ol>
          <h4>■特定技能介護について</h4>
          <ul>
            <li>在留外国人にとっての人気・ニーズが高く、優秀な人材を採用</li>
            <li>フルタイムで就労可能</li>
            <li>シンプルな契約で、企業の負担が少ない</li>
            <li>すでに日本に在住の外国人も紹介可能</li>
          </ul>
          詳しくは、
          厚生労働省特定技能制度運用状況等について<a href="https://www.mhlw.go.jp/content/12000000/001090472.pdf">（リンク）</a>
          <h4>■お問合せ</h4>
          若い人材について相談したい、どのような方が紹介されるか聞きたい、費用について伺いたい、など、まずは<a href="/postmail/index.html">お問合せ</a>ください。<br/>
          問合せメールアドレス jinzai@coco.or.jp
        </div>

      </section>
    </article>
  </main>
</template>

<script>

export default {
  name: 'ForeignRecruitment',

}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

section.top-content {
  padding: 20px 64px;
  h2 {
    margin: 10px 0 5px;
  }
  h3 {
    margin: 20px 0 5px;
  }
  h4 {
    margin: 10px 0 5px;
  }
  .small-caption {
    font-size: 14px;
  }
  .main-caption {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 10px 0;
    padding: 20px;
  }
}
</style>