<template>
  <div class="manual-movie-content-block" :class="category">
    <div class="upper-decoration"></div>
    <h2>マニュアル動画</h2>
    <div class="lower-decoration"></div>
    <div class="caption">
        ナラティブブックの使い方を解説した動画を、ナラティブブック公式Youtubeチャンネルで公開しています。<br/>
        詳しい使い方は、動画を一時停止して見ながら実際に試してみてください。<br/>
    </div>
    <manual-movie-sub-menu
      :currentSubMenu="'manual'"
      :styleName="styleName"
      />
  </div>
</template>

<script>
import ManualMovieSubMenu from '@/components/narrativebook/ManualMovieSubMenu.vue';
export default {
  name: 'ManualMovieContentBlock',

  components: {
    ManualMovieSubMenu
  },

  props: {
    category: String
  },

  computed: {
    styleName () {
      if (this.category === 'care') {
        return 'care-content'
      } else if (this.category === 'kids') {
        return 'kids-content'
      } else {
        return 'akita-content'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
@import '~@/assets/css/narrative_akita_title_style.scss';
</style>