<template>
  <div class="contacts-page" :class="style">
    <article>
      <section class="head-img">
        <div class="display-content">
          <img src="/img/carousel_imgs/carousel_kids_contact.png" :alt="コンタクト" />
          <h2 class="title">コンタクト</h2>
        </div>
      </section>
      <div class="main-content">
        <section>
          <contact-content-block
            :category="style"
            />
        </section>
      </div>
    </article>
  </div>
</template>

<script>
import ContactContentBlock from './commonContent/ContactContentBlock.vue'

export default {
  name: 'ContactsPage',

  components: {
    ContactContentBlock
  },

  data () {
    return {
      style: ''
    }
  },

  watch: {
    '$attrs': { handler: 'initStyle', immediate: true }
  },

  methods: {
    initStyle () {
      this.style = this.$route.params.style[0]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
.contacts-page {
  // height: 100%;
  padding: 30px 0;
  background-color: $frame-background-color;
  color: $content-text-color;
  position: relative;
  article {
    position: relative;
    height: auto;
    section {
      padding: 0 132px;
      &.head-img {
        position: relative;
        padding: 0;
        .display-content {
          position:relative;
          min-height: 256px;
          img {
            height: 256px;
            width: 100%;
            &.pc {
              display: inline;
            }
            &.phone {
              display: none;
            }
          }
          .title {
            position: absolute;
            top: 5px;
            right: 15px;
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            text-shadow: 1px 1px 5px #000;
          }
          @include mediaQuery('phone') {
            min-height: calc(100vw / 4);
            img {
              height: calc(100vw / 4);
              width: 100vw;
              &.pc {
                display: none;
              }
              &.phone {
                display: inline;
              }
            }
          }
        }
      }
    }
  }
  @include mediaQuery('phone') {
    padding: 20px 0;
    article {
      section {
        padding: 0 20px;
      }
    }
  }
}
</style>