<template>
  <header class="navigation-header" id="navigation-header">
    <div class="header-logo"><img src="img/header_logo.png" /></div>
    <nav>
      <img class="navigation-menu" src="/img/menu_icon.png" @click="openMenu" />
      <ul class="header-menu" :class="{open: displayMenu}">
        <li :class="{active: currentPath === '/'}"><a @click="jumpTo('/')">HOME</a></li>
        <li class="splitter"></li>
        <li :class="{active: currentPath === '/about'}"><a @click="jumpTo('about')" >COCOについて</a></li>
        <li class="splitter"></li>
        <li :class="{active: currentPath === '/service'}"><a @click="jumpTo('service')" >COCOのサービス</a></li>
        <li class="splitter"></li>
        <li :class="{active: currentPath === '/narrativebook'}"><a @click="jumpTo('narrativebook_akita')" >ナラティブブック秋田</a></li>
        <li class="splitter"></li>
        <li :class="{active: currentPath === '/privacy'}"><a @click="jumpTo('privacy')" >個人情報保護方針</a></li>
        <li class="contact"><a href="postmail/index.html">お問い合わせ</a></li>
      </ul>
      <div class="contact-btn header-jump-btn"><a href="postmail/index.html">お問合せ</a></div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'NavigationHeader',

  data () {
    return {
      displayMenu: false
    }
  },

  computed: {
    currentPath () {
      console.log(this.$route)
      return this.$route.fullPath
    }
  },

  methods: {
    openMenu () {
      this.displayMenu = !this.displayMenu
    },

    jumpTo (path) {
      this.displayMenu = false
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

.navigation-header {
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .header-logo {
    display: flex;
    align-items: center;
    height: 68px;
    padding-left: 18px;
    img {
      width: 161px;
      height: 30px;
    }
  }
  nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .navigation-menu {
      display: none;
    }
    ul.header-menu {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style-type: none;
      margin-block-start: 0;
      margin-inline-start: 0;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 6px;
        width: 120px;
        height: 26px;
        font-size: 11px;
        text-align: center;
        &.active {
          background-color: $accent-bg-color;
          border-radius: 13px;
        }
        &.splitter {
          width: 1px;
          height: 26px;
          margin: 0;
          border-left: 1px solid $border-accent-color;
        }
        &.contact {
          display: none;
        }
        a {
          cursor: pointer;
          color: $content-base-color;
          text-decoration: none;
        }
      }
    }
    .header-jump-btn {
      margin: 0;
      padding: 0;
      width: 68px;
      height: 68px;
      background-color: $accent-color;
      color: #fff;
      font-size: 11px;
      border-left: 2px solid #fff;
      a {
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        width: 68px;
        height: 68px;
        text-decoration: none;
        color: #fff;
      }
    }
    @include mediaQuery('phone') {
      .navigation-menu {
        display: inline-block;
        margin-right: 10px;
        height: 36px;
        width: 40px;
      }
      ul.header-menu {
        display: none;
        &.open {
          z-index: 1000;
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 60px;
          right: 0;
          border: 1px solid #aaa;
          li {
            margin: 0;
            padding: 5px 10px;
            width: 200px;
            font-size: 13px;
            border-bottom: 1px solid #aaa;
            background-color: #fff;
            &.active {
              background-color: $accent-bg-color;
              border-radius: 0px;
            }
            &.splitter {
              display: none;
            }
            &.contact {
              display: flex;
              background-color: $accent-color;
              color: #fff;
              a {
                color: #fff;
              }
            }
          }
        }
      }
      .header-jump-btn {
        display: none;
      }
    }
  }
}
</style>