<template>
  <div class="kids-participant-list-content-block">
    <h2 class="title"><span>キッズナラティブ秋田</span><span>参加施設一覧</span></h2>
    <div class="lower-decoration"></div>
    <div class="caption">
      <span>キッズナラティブ秋田参加施設一覧です。</span>
    </div>
    <div class="kids-participant-list">
      <div class="list-line" v-for="org in participantList.data" :key="'kpl' + org[0]">
        <div class="line-main">
          <div class="number">{{ org[0] }}</div>
          <div class="type">{{ org[1] }}</div>
          <div class="name">{{ org[2] }}</div>
        </div>
        <div class="line-sub">
          <div class="zip">{{ org[3] }}</div>
          <div class="address">{{ org[4] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KidsParticipantListContentBlock',

  data () {
    return {
      participantList: []
    }
  },

  mounted ()  {
    this.readCSV()
  },

  methods: {
    readCSV () {
      fetch(window.location.origin + '/data/displayContent/kids_participant_list.csv').then(res => {
        res.text().then(result => {
          // console.log(result)
          this.parseCSV(result)
        })
      }).catch(err => {
        console.error(err)
      })
    },

    parseCSV (csvText) {
      this.participantList = this.$papa.parse(csvText)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
@import '~@/assets/css/narrative_akita_title_style.scss';
.kids-participant-list-content-block {
  h2.title {
    color: $kids-main-color;
  }
  .lower-decoration {
    background-color: $kids-main-color;
  }
  .kids-participant-list {
    background-color: #fff;
    padding: 10px;
    .list-line {
      margin-bottom: 10px;
      .line-main {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $kids-text-color;
        @include mediaQuery('phone') {
          flex-wrap: wrap;
        }
        .number {
          width: 2em;
          padding-right: 5px;
          // text-align: right;
          font-weight: bold;
        }
        .type {
          width: 10em;
        }
        .name {
          font-weight: bold;
        }
      }
      .line-sub {
        display: flex;
        align-items: center;
        .zip {
          padding-right: 10px;
        }
      }
    }
  }
}
</style>