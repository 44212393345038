export const manualMovieList = [
  { type: 'playlist', url: 'https://www.youtube.com/watch?v=0E24f58orFU&list=PLbYxMSJzPemDW9dPc9GSVpSDr3XcBPSfB', name: 'Youtubeのプレイリスト' },
  { type: 'index', name: 'ナラティブブックへの登録' },
  { type: 'content', url: 'https://youtu.be/AxiPKKb04wY', name: '#0 新規施設登録の流れ' },
  { type: 'content', url: 'https://youtu.be/0E24f58orFU', name: '#1 新規施設管理者登録' },
  { type: 'content', url: 'https://youtu.be/9keNY5RU584', name: '#2-1 職員登録 その１ 案内状の送信' },
  { type: 'content', url: 'https://youtu.be/HqWrRoSGu3Q', name: '#2-2 職員登録 その２ 案内状の承諾' },
  { type: 'content', url: 'https://youtu.be/-SoTxuyBIeA', name: '#3-1 ログイン設定 その１ 一般利用者編(2023/02版)' },
  { type: 'index', name: 'ログイン設定' },
  { type: 'content', url: 'https://youtu.be/dufRr9iBI5Y', name: '#3-2 ログイン設定 その２ 職員の切り替え(改訂版)' },
  { type: 'content', url: 'https://youtu.be/GjYX-ZOQckc', name: '#4 アカウント作成 ' },
  { type: 'index', name: 'QRコード登録' },
  { type: 'content', url: 'https://www.youtube.com/watch?v=PKgQrBDOUUU', name: '#5 ナラティブブック公式カードのQRコードを登録する' },
  { type: 'index', name: 'かかりつけの設定' },
  { type: 'content', url: 'https://youtu.be/z2OQi3QqnEA', name: '#6-1-1 かかりつけ設定 利用者から施設管理者への「かかりつけ依頼」の送信 ' },
  { type: 'content', url: 'https://youtu.be/06QLWZyOXGg', name: '#6-1-2 かかりつけ設定 利用者から施設管理者への「かかりつけ依頼」を承認する方法 ' },
  { type: 'content', url: 'https://youtu.be/D2pkL2BCN2o', name: '#6-2-1-1 かかりつけ設定 施設管理者からNBIDかメールアドレスで「かかりつけ案内」を送信する ' },
  { type: 'content', url: 'https://youtu.be/-orVslex3jY', name: '#6-2-1-2 かかりつけ設定 施設管理者から利用者へ公式カードのQRコードで「かかりつけ案内」を送信する ' },
  { type: 'content', url: 'https://youtu.be/xD99gKdW1rA', name: '#6-2-2 かかりつけ設定 利用者による承認 ' },
  { type: 'content', url: 'https://youtu.be/7UH4bwyZ7RI', name: '#6-3 かんたんQRでのかかりつけ登録 ' },
  { type: 'index', name: '家族設定' },
  { type: 'content', url: 'https://youtu.be/Ubl7q9edaZg', name: '#7-1 かんたんQRで家族登録 ' },
  { type: 'content', url: 'https://youtu.be/nBvLl2rAdI8', name: '#7-2-1 家族設定 NBIDかメールアドレスで依頼状を送信 ' },
  { type: 'content', url: 'https://youtu.be/UGLqK0fJMWs', name: '#7-2-2 家族設定 公式カードのQRコードで依頼状の送信 ' },
  { type: 'content', url: 'https://www.youtube.com/watch?v=rYK08u8fDI8&list=PLbYxMSJzPemDW9dPc9GSVpSDr3XcBPSfB&index=18&pp=iAQB', name: '#7-2-3 家族設定　公式カードのコード番号で依頼状の送信'},
  { type: 'content', url: 'https://youtu.be/Hn2_5Z3vHd8', name: '#7-3 家族設定 依頼状の承認 ' },
  { type: 'index', name: 'プロフィール' },
  { type: 'content', url: 'https://youtu.be/Fqp9yAqjt2s', name: '#8-1 プロフィール編集(一般) ' },
  { type: 'content', url: 'https://youtu.be/3aOwUYx2xuI', name: '#8-2 プロフィール編集(職員) ' },
  { type: 'index', name: '基本的な使い方' },
  { type: 'content', url: 'https://youtu.be/b5rkwHulKho', name: '#11-1-1 タイムラインへの投稿 ' },
  { type: 'content', url: 'https://youtu.be/yZjoD9gxlG0', name: '#11-1-2 ログイン情報 ' },
  { type: 'content', url: 'https://youtu.be/-FblbMZD1cY', name: '#11-2 タイムライン投稿の整理・抽出 ' },
  { type: 'content', url: 'https://youtu.be/58jXBQGPd9Q', name: '#11-3 コミュニケーションのための機能 ' },
  { type: 'content', url: 'https://youtu.be/Lw7hdRHK5gg', name: '#11-4 医療従事者向け機能 ' },
  { type: 'index', name: 'ビデオ通話機能' },
  { type: 'content', url: 'https://youtu.be/jg7deFX7rF0', name: '[NBマニュアル]#12-1-0 ビデオ通話機能 概要 ' },
  { type: 'content', url: 'https://youtu.be/YcEH0qZREIw', name: '[NBマニュアル]#12-1-1 ビデオ通話機能① 事前の設定 ' },
  { type: 'content', url: 'https://youtu.be/fHMF7k331UM', name: '[NBマニュアル]#12-1-2 ビデオ通話機能② 通話の開始（一般ユーザー） ' },
  { type: 'content', url: 'https://youtu.be/hSPvFRilC-w', name: '[NBマニュアル]#12-1-3 ビデオ通話機能③ 通話の開始（施設職員） ' },
  { type: 'content', url: 'https://youtu.be/-DBPdU5S8zM', name: '[NBマニュアル]#12-2-0 外部アプリを利用したビデオ通話機能 概要 ' },
  { type: 'content', url: 'https://youtu.be/jtg7kM4q_bM', name: '[NBマニュアル]#12-2-1 外部アプリを利用したビデオ通話機能① Zoomの設定 ' },
  { type: 'content', url: 'https://youtu.be/ljIzlZS8YV8', name: '[NBマニュアル]#12-2-2 外部アプリを利用したビデオ通話機能② ナラティブブックの設定 ' },
  { type: 'content', url: 'https://youtu.be/V1eaNTQCR8Q', name: '[NBマニュアル]#12-2-3 外部アプリを利用したビデオ通話機能③ 通話の開始 ' },
  { type: 'index', name: 'OMRON connect連携' },
  { type: 'content', url: 'https://youtu.be/aVipPqWUobQ', name: '[NBマニュアル]#13 OMRON connectを利用したバイタルの表示 ' },
  { type: 'index', name: '代理設定' },
  { type: 'content', url: 'https://youtu.be/7p-o2wkL-D0', name: '[NBマニュアル]#14-1 代理設定① ログインできない利用者アカウントの作成 ' },
  { type: 'content', url: 'https://youtu.be/F-AZYVUoSKw', name: '[NBマニュアル]#14-2 代理設定② ログインできない利用者アカウントのプロフィール編集 ' },
  { type: 'content', url: 'https://youtu.be/NgpXliAUTMg', name: '[NBマニュアル]#14-3 代理設定③ ログインできない利用者アカウントへの投稿 ' },
  { type: 'content', url: 'https://youtu.be/u53LGpr1khs', name: '[NBマニュアル]#14-4 代理設定④ ログインできない利用者を本人ログイン可能にする ' },
  { type: 'index', name: '設定代行' },
  { type: 'content', url: 'https://youtu.be/eYmvExjpHhE', name: '[NBマニュアル]#16-1 設定代行① 施設側の準備' },
  { type: 'content', url: 'https://youtu.be/q3l5_4zrBic', name: '[NBマニュアル]#16-2-1 設定代行② 利用者からの設定代行の申請' },
  { type: 'content', url: 'https://youtu.be/MU0dtNLCR6o', name: '[NBマニュアル]#16-2-2 設定代行② 利用者から施設管理者への「設定代行の申請」を承認する方法' },
  { type: 'content', url: 'https://youtu.be/SmS4Jq49sxY', name: '[NBマニュアル]#16-3-1 設定代行③ 設定代行の案内（施設からの送信）' },
  { type: 'content', url: 'https://youtu.be/M6O1GLQifDk', name: '[NBマニュアル]#16-3-2 設定代行② 施設から利用者への「設定代行の案内」を承認する方法' },
  { type: 'content', url: 'https://youtu.be/TmlqEudZG7s', name: '[NBマニュアル]#16-4 設定代行④ 設定代行による新規利用者の作成' },
  { type: 'content', url: 'https://youtu.be/TfUf1obURFY', name: '[NBマニュアル]#16-5 設定代行⑤ 代行作成したアカウントをログイン可能にする' },
  { type: 'content', url: 'https://youtu.be/Ur3dpWLwAFQ', name: '[NBマニュアル]#16-6 設定代行⑥ 「かんたんQR」による設定代行の設定' },
]