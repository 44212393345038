import { createApp } from 'vue'
// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VuePapaParse from 'vue-papa-parse'
// import Meta from 'vue-meta'

// const app = createApp(App)
// app.use(router)
// app.use(Meta)
// app.mount('#app')

createApp(App).use(router).use(VuePapaParse).mount('#app')
