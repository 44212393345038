<template>
  <main>
    <article>
      <section class="title">
        <h1><img src="/img/channel_logo_m.svg" alt="COCOチャンネル" /></h1>
      </section>
      <section class="top-content">
        <div class="content-title">
          <h2>PICK UP</h2>
          <h3>最新のセミナー</h3>
        </div>
        <div class="seminar-content">
          <div class="head-content">
            <div class="series-title"><span>秋田県地域医療介護</span><span>オンラインセミナー</span></div>
            <h2 class="seminar-title">
              <div class="sub-title"><span class="border">&nbsp;</span><span class="title">特別企画</span><span class="border">&nbsp;</span></div>
              <div class="title-head">
                <span>地域包括</span>
                <span>ケアシステムと</span>
                <span>支える仕組みづくり</span>
              </div>
              <div class="title-main">
                <span>人生の</span><span>最終段階における</span><span>医療とケアについて</span>
              </div>
            </h2>
            <div class="props">
              <span>主催 : 一般社団法人地域医療介護連携推進機構</span>
              <span>後援 : インフォコム株式会社</span>
            </div>
            <div class="date-column">
              <div class="date-title">講演日時</div>
              <div class="date-content">
                <span class="year">2022年</span>
                <div class="date-block">
                  <span class="date">12/09</span>
                  <span class="weekday">金</span>
                </div>
                <div class="time-content">19:00〜20:00</div>
              </div>
            </div>
            <div class="date-column">
              <div class="date-title">オンデマンド配信</div>
              <div class="date-content">
                <span class="year">2022年</span>
                <div class="date-block">
                  <span class="date">12/10</span>
                  <span class="weekday">土</span>
                  <div class="time">10:00</div>
                </div>
                <div class="date-splitter">&nbsp;</div>
                <div class="date-block">
                  <span class="date">12/23</span>
                  <span class="weekday">金</span>
                  <div class="time">20:00</div>
                </div>
              </div>
            </div>
            <div class="entry-column">
              <div class="entry-btn" :class="currentEntryState">
                <a href="https://www.infocom-science.jp/entry/" target="_blank">
                  <div class="accepting"><span>参加申込は</span><span>こちら</span></div>
                  <div class="ondemand"><span>視聴申込は</span><span>こちら</span></div>
                </a>
                <span class="closed"><span>視聴申込は</span><span>締切ました</span></span>
              </div>
              <div class="entry-notify">
                視聴するには、事前の参加登録が必要です。<br />
                メールアドレスの登録が必須となりますので、申込み用のメールアドレスをご用意ください。
              </div>
            </div>
          </div><!-- head -->
          <div class="body-content">
            <div class="description">
              我が国では高齢化に伴い、終末期においては患者の意思決定能力が低下する方が増えることから、ご本人の意向を推定して、ご家族等と医療従事者が相談の上で今後の医療・ケアの方針を決めることが多くなります。Advance Care Planning（ACP）とは、もしもの時のために本人が望む将来の医療及びケアについて、患者さんを主体にそのご親族や医療・ケアチームが話し合いを行い、患者さんの意思決定を支援するプロセスのことです。<br/>
              秋田県医師会では、住み慣れた地域で自分らしい暮らしを人生の最期まで続けるための仕組み（地域包括ケアシステム）を構築する為の仕組みとして、本人の想いを大切にして安心して暮らせる、地域医療・介護の連携を気づく取り組みをしてきました。今回の講演では、人生の最終段階における医療とケア、患者さんに寄り添う医療・介護の取り組みについて学び、患者さん一人ひとりが主人公となる ACPについて考えていきたいと思います。
            </div>
            <div class="speakers">
              <div class="speaker">
                <div class="portrate"><img src="/img/ito_shinichi_portrate.jpg" /></div>
                <div class="information">
                  <div class="act">講師</div>
                  <div class="position"><span>一般社団法人秋田県医師会副会長、</span><span>伊藤医院&nbsp;院長</span></div>
                  <div class="name">伊藤&nbsp;伸一<span class="honorific">先生</span></div>
                </div>
              </div>
            </div><!-- speakers -->
            <div class="notify">
              ■著作権<br/>
              講演会の録画／録音はご遠慮頂きますようお願い申し上げます。<br/>
              ■個人情報の取り扱い<br/>
              入力頂いた個人情報は地域連携医療介護の活性化目的のために使用します。個人情報は弊社と業務委託先以外の第3者に開示。提供することはありません。<br/>
              ■視聴要件<br/>
              動画を視聴するには、最新のバージョンのブラウザ、オペレーティング システムを使用し、インターネット接続が良好であることをご確認ください。<br/>
              最新バージョンの Google Chrome, Firefox, MS Edge, Safari, Opera<br/>
              500Kbps 以上のインターネット接続。<br/>
            </div>
          </div>
        </div><!-- seminar content -->
      </section>
      <section class="what">
        <div class="content-title">
          <h2>COCO CHANNEL</h2>
          <h3>COCOチャンネルとは？</h3>
        </div>
        <div class="content-box">
          <p>
            COCOチャンネルは、各種セミナーなどの動画コンテンツを提供するサービスです。<br/>
            ユーザーの属性/職種に合わせたコンテンツの提供とおすすめをセレクトして表示します。<br/>
            また、特定の会員のみに提供するコンテンツのコントロールや管理を行うこともできます。
          </p>
        </div>
      </section>
      <inside-footer/>
    </article>
  </main>
</template>

<script>
import InsideFooter from '@/components/CocoCommon/InsideFooter.vue'

export default {
  name: 'CocoChannnel',

  components: {
    InsideFooter
  },

  computed: {
    currentEntryState () {
      var now = new Date()
      var targetDate = new Date('2022/12/9 19:00')
      var closeDate = new Date('2022/12/23 20:00')
      if (now < targetDate) {
        return 'accepting'
      } else if (now > closeDate) {
        return 'closed'
      } else {
        return 'ondemand'
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

$seminar-title-color: #ec6946;

section.title {
  padding: 20px 0;
  border-top: 1px solid #aaa;
  height: auto;
  background-image: none;
  background-color: #fff;
  img {
    height: 50px;
  }
}

section.top-content {
  background-image: none;
  // background-color: $accent-bg-color;
  .content-title {
    display: flex;
    align-items: center;
    background-color: $accent-bg-color;
    h2 {
      margin: 10px 20px;
      font-size: 20px;
    }
    h3 {
      margin: 10px 0;
      font-size: 16px;
      font-weight: normal;
    }
  }
  .seminar-content {
    display: flex;
    flex-direction: row-reverse;
    background-image: url('~@/assets/css/images/channel_seminar_content_bg.png');
    background-position: left 50px;
    background-repeat: no-repeat;
    background-color: #fff;
    @include mediaQuery('phone') {
      flex-direction: column;
      background-image: none;
    }
    .head-content {
      margin-right: 50px;
      width: 250px;
      background-image: url('~@/assets/css/images/channel_seminar_title_bg.png');
      background-repeat: repeat;
      background-color: $accent-color;
      @include mediaQuery('phone') {
        width: 100%;
      }
      .series-title {
        margin-top: 60px;
        text-align: center;
        color: #fff;
        span {
          display: inline-block;
        }
      }
      h2.seminar-title {
        margin-top: 10px;
        background-color: #fff;
        padding: 20px 0;
        padding-top: 10px;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        line-height: 44px;
        color: $seminar-title-color;
        .sub-title {
          display: flex;
          align-items: center;
          font-size: 14px;
          span {
            display: inline-block;
          }
          .border {
            // width: 50%;
            flex-grow: 1;
            height: 1px;
            border-top: 1px solid $seminar-title-color;
          }
          .title {
            padding: 0 5px;
          }
        }
        .title-head {
          font-size: 14px;
          line-height: 20px;
        }
        .title-main {
          padding-top: 10px;
        }
        span {
          display: inline-block;
        }
        @include mediaQuery('phone') {
          margin-top: 20px;
          margin-bottom: 10px;
        }
      }
      .props {
        font-size: 11px;
        color: #fff;
        padding: 0 5px;
        span {
          display: inline-block;
        }
      }
      .date-column {
        padding: 20px;
        color: #fff;
        @include mediaQuery('phone') {
          padding: 10px;
        }
        .date-title {
          margin-bottom: 20px;
          padding: 3px;
          border: 1px solid #fff;
          text-align: center;
          @include mediaQuery('phone') {
            margin-bottom: 10px;
          }
        }
        .date-content {
          display: flex;
          flex-direction: column;
          @include mediaQuery('phone') {
            flex-direction: row;
            justify-content: space-evenly;
            flex-wrap: wrap;
          }
          .year {
            display: block;
            font-size: 12px;
            @include mediaQuery('phone') {
              align-self: flex-start;
            }
          }
          .date-block {
            display: flex;
            align-items: baseline;
            .date {
              font-family: Arial;
              font-size: 50px;
              font-weight: bold;
            }
            .weekday {
              margin-left: 10px;
              padding: 3px;
              height: 18px;
              width: 18px;
              font-size: 16px;
              background-color: #fff;
              color: $seminar-title-color;
            }
            .time {
              margin-left: 10px;
              font-size: 16px;
            }
          }
          .time-content {
            text-align: right;
            font-size: 18px;
            @include mediaQuery('phone') {
              align-self: flex-end;
            }
          }
        }
        .date-splitter {
          margin: 10px auto;
          height: 30px;
          width: 1px;
          border-left: 1px solid #fff;
          @include mediaQuery('phone') {
            border-left: none;
            height: 1px;
            width: 30px;
            border-bottom: 2px solid #fff;
            align-self: center;
          }
        }
      }
      .entry-column {
        padding: 10px;
        .entry-btn {
          border-radius: 10px;
          background-color: #fff;
          a {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 10px;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            text-align: center;
            text-decoration: none;
            font-size: 30px;
            font-weight: bold;
            color: $seminar-title-color;
            span {
              display: inline-block;
            }
          }
          span.closed {
            display: none;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 10px;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            text-align: center;
            text-decoration: none;
            font-size: 30px;
            font-weight: bold;
            color: $seminar-title-color;
            span {
              display: inline-block;
            }
          }
          &.accepting {
            div.accepting {
              display: block;
            }
            div.ondemand {
              display: none;
            }
          }
          &.ondemand {
            div.accepting {
              display: none
            }
            div.ondemand {
              display: block;
            }
          }
          &.closed {
            a {
              display: none;
            }
            span.closed {
              display: flex;
            }
          }
        }
        .entry-notify {
          margin: 10px;
          font-size: 12px;
          color: #fff;
        }
      }
    }
    .body-content {
      margin-top: 380px;
      padding: 20px;
      padding-left: 50px;
      width: calc(100% - 50px - 250px);
      @include mediaQuery('phone') {
        margin: 10px;
        width: calc(100% - 40px);
        padding: 10px;
      }
      .description {
        font-size: 16px;
        line-height: 30px;
      }
      .speakers {
        margin: 20px 0;
        padding: 20px;
        border-top: 4px solid $seminar-title-color;
        border-bottom: 4px solid $seminar-title-color;
        @include mediaQuery('phone') {
          padding: 20px 0;
        }
        .speaker {
          display: flex;
          align-items: center;
          margin: 10px 0 20px;
          .portrate {
            margin-right: 50px;
            width: 80px;
            height: 80px;
            background-color: #aaa;
            img {
              width: 80px;
              height: 80px;
            }
            @include mediaQuery('phone') {
              margin-right: 20px;
              flex-grow: 2;
            }
          }
          .information {
            .act {
              font-size: 14px;
            }
            .position {
              font-size: 14px;
              span {
                display: inline-block;
              }
            }
            .name {
              font-size: 24px;
              .honorific {
                font-size: 16px;
              }
            }
          }
        }
      }
      .notify {
        font-size: 14px;
      }
    }
  }
}

section.what {
  background-color: transparent;
  .content-title {
    display: flex;
    align-items: center;
    background-color: $accent-bg-color;
    h2 {
      margin: 10px 20px;
      font-size: 20px;
    }
    h3 {
      margin: 10px 0;
      font-size: 16px;
      font-weight: normal;
    }
  }
  .content-box {
    padding: 20px 50px;
  }
}
</style>
