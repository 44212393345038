<template>
  <narrative-navigation-header
    :currentStyle="currentStyle"/>
  <div class="main-contents">
    <router-view/>
    <coco-narrative-footer
      :currentStyle="currentStyle"/>
  </div>
</template>

<script>
import NarrativeNavigationHeader from '@/components/narrativebook/NarrativeNavigationHeader.vue'
import CocoNarrativeFooter from '@/components/narrativebook/CocoNarrativeFooter.vue'

export default {
  name: 'NarrativebookView',

  components: {
    NarrativeNavigationHeader,
    CocoNarrativeFooter
  },

  computed: {
    currentPath () {
      console.log(this.$route)
      return this.$route.fullPath
    },

    currentStyle () {
      if (this.$route.fullPath.match(/\/narrativebook_akita\/care_narrative*/)) {
        return 'care_narrative'
      } else if (this.$route.fullPath.match(/\/narrativebook_akita\/kids_narrative*/)) {
        return 'kids_narrative'
      } else if (this.$route.fullPath.match(/\/narrativebook_akita\/online_medical*/)) {
        return 'online'
      } else {
        return 'nb_akita'
      }
      // switch (this.$route.fullPath) {
      //   case '/narrativebook_akita':
      //     return 'nb_akita'
      //   case '/narrativebook_akita/care_narrative':
      //     return 'care_narrative'
      //   case '/narrativebook_akita/kids_narrative':
      //     return 'kids_narrative'
      //   case '/narrativebook_akita/online_medical':
      //     return 'online'
      //   default:
      //     return 'nb_akita'
      // }
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: $content-base-color;
  color: $content-base-color;
  font-family: $basic-font-family;
  .main-contents {
    height: calc(100vh - 80px);
    height: calc(100dvh - 80px);
    overflow: auto;
  }
}

</style>
