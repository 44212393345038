<template>
  <div class="article-content movie-list-page" v-if="targetPage">
    <div class="title" v-html="targetPage.headerSource" :style="{backgroundImage: 'url(' + targetPage.headerBackgroundImage + ')'}"></div>
    <div class="playlist-content">
      <h4>Youtubeプレイリストですべて通しで見ることができます。</h4>
      <h3>
        {{ targetPage.playlistTitle }}
        <a class="to-youtube" target="_blank" :href="targetPage.playlistURL">Youtubeで視聴<span class="video-jump-mark">▶</span></a>
      </h3>
      <div class="playlist-index">
        <ul>
          <li v-for="(indexName, index) in targetPage.playlistIndexes" :key="'playlistindex'+index">{{ indexName }}</li>
        </ul>
      </div>
    </div>
    <div class="contnet-movies">
      <div class="movie-list" v-for="(movieContent, index) in targetPage.movieContents" :key="'movieContent' + index">
        <div class="title">
          <span class="title-text">{{ movieContent.title }}</span>
          <span class="duration">({{ movieContent.duration }})</span>
          <a class="to-youtube" target="_blank" :href="movieContent.linkURL">Youtubeで視聴<span class="video-jump-mark">▶</span></a>
        </div>
        <div class="content">
          <div class="embeded-movie">
            <a :href="movieContent.linkURL" target="_blank">
              <img class="movie-thumbnail" :src="'/img/youtube_thumbnails/' + movieContent.thumbnail" />
            </a>
          </div>
          <div class="description" v-html="wrapedText(movieContent.description)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as PageSource from '@/assets/data/article_movie_list.js'

export default {
  name: 'MovieListPage',

  data () {
    return {
      pageList: PageSource.pageList,
      targetPageName: '',
      targetPage: null,
    }
  },

  watch: {
    '$route': { handler: 'loadPageName', immediate: true }
  },

  methods: {
    loadPageName () {
      this.targetPageName = ''
      this.targetPage = null
      if (this.$route.params.pagename) {
        this.targetPageName = this.$route.params.pagename
        this.pageList.forEach(page => {
          if (page.pageName === this.targetPageName) {
            this.targetPage = page
          }
        })
        if (!this.targetPage) {
          // redirect to top
          this.$router.push({ name: 'narrativebook_content' })
        }
      } else {
        this.$router.push({ name: 'narrativebook_content' })
      }
    },

    wrapedText (text) {
      return text.replace(/\n/gi, '<br />')
    },
  }

}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
// .article-content {
//   font-weight: bold;
// }
.movie-list-page {
  background-color: $frame-background-color;
  .title {
    margin: 0;
    padding: 10px 0;
    position: relative;
    background-position: bottom right;
  }
  .playlist-content {
    margin: 10px 20px 20px;
    padding: 10px;
    border: 1px solid $content-base-color;
    color: $content-base-color;
    background-color: #fff;
    h4 {
      margin: 0;
    }
    h3 {
      margin: 0;
      a {
        color: $content-base-color;
        text-decoration: none;
        margin-left: 30px;
        font-size: 16px;
      }
    }
    .video-jump-mark {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      width: 20px;
      height: 15px;
      font-size: 10px;
      text-align: center;
      border-radius: 2px;
      background-color: $content-text-color;
      color: #fff;
    }
  }
  .contnet-movies {
    margin: 0 20px;
    .movie-list {
      margin: 10px 0 20px;
      padding: 10px;
      border: 1px solid $content-base-color;
      color: $content-base-color;
      background-color: #fff;
      .title {
        margin: 10px 0;
        .title-text {
          display: inline-block;
          transform: scale(1, 1.5);
          font-size: 14px;
          letter-spacing: 0.2em;
          line-height: 16px;
        }
        .duration {
          display: inline-block;
        }
        a.to-youtube {
          margin-left: 10px;
          margin-top: 10px;
          display: inline-flex;
          align-items: center;
          color: $content-base-color;
          text-decoration: none;
          font-size: 14px;
          .video-jump-mark {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            width: 20px;
            height: 15px;
            font-size: 10px;
            text-align: center;
            border-radius: 2px;
            background-color: $content-text-color;
            color: #fff;
          }
        }
      }
      .content {
        display: flex;
        margin: 10px 0 20px;
        border-bottom: 1px solid #ddd;
        @include mediaQuery('phone') {
          flex-wrap: wrap;
          margin-bottom: 30px;
        }
        .embeded-movie {
          height: 135px;
          width: 240px;
          border: 1px solid #ddd;
        }
        .description {
          padding-left: 20px;
          width: calc(100% - 240px - 20px);
          font-size: 14px;
          color: $content-base-color;
          @include mediaQuery('phone') {
            width: 100%;
            padding-left: 0;
          }
        }
      }
    }
  }
}
</style>