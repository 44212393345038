<template>
  <div class="outer-frame">
    <router-view/>
  </div>
</template>

<script>
// import NavigationHeader from '@/components/NavigationHeader.vue'

// export default {
//   name: 'App',

//   metaInfo: {
//     title: 'COCO 一般社団法人 地域医療介護連携推進機構',
    
//   }
// }
</script>

<style lang="scss">
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #ddd;
  color: $total-background-color;
  font-family: "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
}

div.outer-frame {
  margin: 0 auto;
  width: 1024px;
  height: 100%;
  background-color: #fff;
  @include mediaQuery('phone') {
    width: 100%;
  }
}

</style>
