<template>
  <main>
    <article>
      <section class="top">
        <h1 class="hero-copy">
          住民が<span class="vision-accent">安心して生きる</span>ことができる<br />
          地域包括ケアシステムの構築
        </h1>
        <div class="content-box">
          <h2>NEWS</h2>
          <h3>新着情報</h3>
          <div class="splitter-accent">&nbsp;</div>
          <div class="text-content">
            <ul class="news-list">
              <li>
                <span class="date">2024/03/01</span>
                <span class="message">有料人材紹介事業の認可を受け、<br/><a href="/jinzai">外国人材の紹介</a>を始めました。</span>
              </li>
              <li>
                <span class="date">2022/10/10</span>
                <span class="message">ホームページの更新を行いました</span>
              </li>
              <li>
                <span class="date">2021/11/11</span>
                <span class="message">ホームページの更新を行いました</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section class="about-us-summary">
        <div class="content-box">
          <h2>ABOUT COCO</h2>
          <h3>COCOについて</h3>
          <div class="splitter-accent">&nbsp;</div>
          <div class="text-content">
            <p>
              我が国では団塊の世代が75歳（後期高齢者）になる2025年以降に超高齢化を迎えることから、“地域包括ケアシステム“の構築が進められています。地域包括ケアシステムとは、高齢者が重度な要介護状態となっても住み慣れた地域で自分らしい暮らしを最期まで送ることができるよう、地域（日常生活圏域）一体で支援する体制のことです。そして、“自分らしい暮らし“をするためには、その方の尊厳の保持と自立支援が重要だと考えます。<br/>
              一般社団法人地域医療介護連携推進機構（略称：COCO（ココ））は、そこに住む全ての方が、自分らしい暮らしを選択し、続けられるように、地域の包括的な支援・サービス提供体制を支援する活動を行います。<br/>
              特に、医療や介護従事者の連携活動や研修支援の支援などを通して、住民の皆様の安心した生活が継続できるよう事業に取り組んでまいります。
            </p>
            <div class="navigation-block">
              <router-link to="about" class="jump-to-content">
                詳しくみる
                <img src="img/jump-content-arrow.png"/>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <inside-footer/>
    </article>
  </main>
</template>

<script>
import InsideFooter from '@/components/CocoCommon/InsideFooter.vue'

export default {
  name: 'HomeContent',

  components: {
    InsideFooter
  },

  data () {
    return {
      newsContents: [
        { date: '2022/10/10', content: 'ホームページの更新を行いました' },
        { date: '2021/11/11', content: '正式ホームページを公開しました。' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

section {
  .content-box {
    background-color: #fff;
    h2 {
      margin: 0;
      padding: 0;
      font-size: 26px;
      font-weight: 600;
    }
    h3 {
      margin: 0;
      margin-top: -5px;
      padding: 0px;
      font-size: 14px;
      font-weight: 300;
    }
    .splitter-accent {
      width: 75px;
      height: 1px;
      margin-top: 21px;
      margin-bottom: 16px;
      border-top: 1px solid $accent-color;
    }
    .navigation-block {
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;
      a.jump-to-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 43px;
        width: 164px;
        padding: 0 20px;
        border-radius: 21px;
        background-color: $accent-color;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  @include mediaQuery('phone') {
    .content-box {
      background-color: #fff;
      border: 1px solid #ddd;
      h2 {
        font-size: 18px;
        font-weight: 600;
      }
      h3 {
        margin: 0;
        margin-top: -5px;
        padding: 0px;
        font-size: 13px;
        font-weight: 300;
      }
      .splitter-accent {
        width: 75px;
        height: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-top: 1px solid $accent-color;
      }
      .navigation-block {
        margin-top: 15px;
        display: flex;
        justify-content: flex-end;
        a.jump-to-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 43px;
          width: 164px;
          padding: 0 20px;
          border-radius: 21px;
          background-color: $accent-color;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}

section.top {
  // height: 537px;
  padding: 24px 0;
  background-image: url('~@/assets/css/images/hero_img.png');
  background-repeat: no-repeat;
  background-position: left;
  h1 {
    margin: 22px 98px;
    margin-top: 12px;
    margin-left: 74px;
    padding: 0;
    font-size: 33px;
    font-weight: 600;
    line-height: 57px;
    color: $accent-color;
  }
  .content-box {
    width: 526px;
    margin-right: 0;
    margin-left: auto;
    padding-top: 35px;
    padding-bottom: 45px;
    padding-left: 63px;
    padding-right: 63px;
    border-radius: 30px;
    ul.news-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      margin-block-start: 0;
      margin-inline-start: 0;
      border-top: 1px solid $border-accent-color;
      li {
        display: flex;
        align-items: center;
        min-height: 46px;
        border-bottom: 1px solid $border-accent-color;
        .date {
          display: inline-block;
          font-weight: 600;
          margin-right: 10px;
        }
        .message {
          display: inline-block;
        }
      }
    }
  }
  @include mediaQuery('phone') {
    padding: 12px 0;
    background-image: none;
    h1 {
      margin: 11px 24px;
      padding: 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      color: $accent-color;
    }
    .content-box {
      width: calc(100vw - 60px);
      margin: 0 auto;
      padding: 20px;
      border-radius: 20px;
      ul.news-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-block-start: 0;
        margin-inline-start: 0;
        border-top: 1px solid $border-accent-color;
        li {
          display: flex;
          align-items: center;
          min-height: 46px;
          border-bottom: 1px solid $border-accent-color;
          font-size: 13px;
          .date {
            display: inline-block;
            font-weight: 600;
            margin-right: 10px;
          }
          .message {
            display: inline-block;
          }
        }
      }
    }
  }
}

section.about-us-summary {
  margin-top: 127px;
  margin-bottom: 24px;
  padding-top: 24px;
  height: 565px;
  background-image: url('~@/assets/css/images/about_summary_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  .content-box {
    width: 521px;
    background-color: #fff;
    padding-top: 28px;
    padding-bottom: 16px;
    padding-left: 114px;
    padding-right: 62px;
    border-radius: 30px;
    .text-content {
      margin: 0;
      padding: 0;
      font-size: 16px;
      line-height: 25px;
      p {
        margin: 0;
        padding: 0;
      }
    }
  }
  @include mediaQuery('phone') {
    margin: 0;
    padding-top: 24px;
    background-image: none;
    height: auto;
    .content-box {
      width: calc(100vw - 60px);
      background-color: #fff;
      margin: 0 auto;
      padding: 20px;
      border-radius: 20px;
      .text-content {
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: 25px;
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

section.news {
  margin-top: 80px;
  margin-left: 60px;
  .content-box {
    width: 526px;
    padding-top: 55px;
    padding-bottom: 45px;
    padding-left: 63px;
    padding-right: 63px;
    ul.news-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      margin-block-start: 0;
      margin-inline-start: 0;
      border-top: 1px solid $border-accent-color;
      li {
        display: flex;
        align-items: center;
        min-height: 46px;
        border-bottom: 1px solid $border-accent-color;
        .date {
          display: inline-block;
          font-weight: 600;
          margin-right: 10px;
        }
        .message {
          display: inline-block;
        }
      }
    }
  }
  @include mediaQuery('phone') {
    margin-top: 80px;
    margin-left: 60px;
    .content-box {
      // width: 526px;
      padding-top: 55px;
      padding-bottom: 45px;
      padding-left: 63px;
      padding-right: 63px;
      ul.news-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-block-start: 0;
        margin-inline-start: 0;
        border-top: 1px solid $border-accent-color;
        li {
          display: flex;
          align-items: center;
          min-height: 46px;
          border-bottom: 1px solid $border-accent-color;
          .date {
            display: inline-block;
            font-weight: 600;
            margin-right: 10px;
          }
          .message {
            display: inline-block;
          }
        }
      }
    }
  }
}

</style>
