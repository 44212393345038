<template>
  <div class="phone-app-content-block" :class="category">
    <section>
      <div class="upper-decoration"></div>
      <h2 class="title">ナラティブブックへのログイン</h2>
      <div class="lower-decoration"></div>
      <div class="signin-content">
        <div class="caption">
          情報共有ツール「ナラティブブック」へのログインと新規ユーザー登録は下記リンクから行えます。<br/>
        </div>
        <div class="signin-link">
          <a href="https://narrativebook.jp/landing" class="image-link" target="_blank">
            <img src="/img/narrative_icon_r_32.png" class="nb-icon" />
            一般利用者(生活者)ログインページ<span class="jump-mark">▶</span>
          </a>
        </div>
        <div class="signin-link">
          <a href="https://narrativebook.jp/landing/member" class="image-link" target="_blank">
            <img src="/img/narrative_icon_g_32.png" class="nb-icon" />
            医療福祉介護従事者ログインページ<span class="jump-mark">▶</span>
          </a>
        </div>
      </div>
    </section>
    <section>
      <div class="upper-decoration"></div>
      <h2 class="title">ナラティブブックのアプリ</h2>
      <div class="lower-decoration"></div>
      <div class="caption">
        情報共有ツール「ナラティブブック」の専用アプリはこちらからダウンロードいただけます。<br/>
        iPhone/iPad用とAndroid用のアプリがあります。
      </div>
      <div class="app-content">
        <div class="apple">
          <div class="apple-store-link">
            <a href="https://apple.co/3ouEy6B" target="_blank">
              <img class="apple-badge" alt="App Storeからダウンロード" src="/img/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg">
            </a>
          </div>
          <div class="app-spec">
            <div class="spec">
              <div class="spec-title">販売元</div>
              <div class="spec-value">XCAREFIELD CO., LTD.</div>
            </div>
            <div class="spec">
              <div class="spec-title">サイズ</div>
              <div class="spec-value">42.3MB</div>
            </div>
            <div class="spec">
              <div class="spec-title">カテゴリ</div>
              <div class="spec-value">ソーシャルネットワーキング</div>
            </div>
            <div class="spec">
              <div class="spec-title">言語</div>
              <div class="spec-value">日本語、 英語</div>
            </div>
            <div class="spec">
              <div class="spec-title">年齢</div>
              <div class="spec-value">12+</div>
              <div class="spec-value">まれ／軽度な医療または治療情報</div>
            </div>
            <div class="spec">
              <div class="spec-title">互換性</div>
              <div class="spec-value">iPhone</div>
              <div class="spec-sub-value">iOS 12.1以降が必要です。</div>
              <div class="spec-value">iPad</div>
              <div class="spec-sub-value">iPadOS 12.1以降が必要です。</div>
              <div class="spec-value">iPod touch</div>
              <div class="spec-sub-value">iOS 12.1以降が必要です。</div>
              <div class="spec-value">Mac</div>
              <div class="spec-sub-value">macOS 11.0以降とApple M1以降のチップを搭載したMacが必要です。</div>
            </div>
          </div>
        </div>
        <div class="google">
          <div class="google-play-link">
            <a href="https://play.google.com/store/apps/details?id=jp.co.xcf.narrativebook" target="_blank">
              <img class="google-badge" alt="Google Playで手に入れよう" src="/img/google-play-badge.png" >
            </a>
          </div>
          <div class="app-spec">
            <div class="spec">
              <div class="spec-title">バージョン</div>
              <div class="spec-value">1.0.2</div>
            </div>
            <div class="spec">
              <div class="spec-title">最終更新日</div>
              <div class="spec-value">2021/01/22</div>
            </div>
            <div class="spec">
              <div class="spec-title">Android 要件</div>
              <div class="spec-value">8.0 以上</div>
            </div>
            <div class="spec">
              <div class="spec-title">ダウンロード数</div>
              <div class="spec-value">100+ 件</div>
            </div>
            <div class="spec">
              <div class="spec-title">コンテンツのレーティング</div>
              <div class="spec-value">12 歳以上 • 保護者の指導を推奨</div>
            </div>
            <div class="spec">
              <div class="spec-title">インタラクティブな要素</div>
              <div class="spec-value">ユーザー インタラクション</div>
            </div>
            <div class="spec">
              <div class="spec-title">リリース日</div>
              <div class="spec-value">2020/10/22</div>
            </div>
            <div class="spec">
              <div class="spec-title">提供元</div>
              <div class="spec-value">xCareField Co., Ltd.</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PhoneAppContentBlock',

  props: {
    category: String
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
@import '~@/assets/css/narrative_akita_title_style.scss';
.phone-app-content-block {
  section {
    padding-bottom: 20px;
  }
  .signin-content {
    .signin-link {
      margin: 10px;
      text-align: center;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-size: 21px;
        color: $care-text-color;
        img {
          margin-right: 10px;
        }
      }
    }
  }
  .apple-store-link {
    img {
      width: 134px;
      height: 62px;
    }
  }
  .google-play-link {
    img {
      width: 160px;
      height: 62px;
    }
  }
  .app-spec {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #aaa;
    color: $care-text-color;
    .spec {
      margin: 10px;
      min-width: 30%;
      .spec-title {
        font-size: 14px;
      }
      .spec-value {
        font-weight: bold;
      }
      .spec-sub-value {
        font-weight: normal;
      }
    }
  }
  &.kids {
    .app-spec {
      color: $kids-text-color;
    }
    .signin-content {
      .signin-link {
        a {
          color: $kids-text-color;
        }
      }
    }
  }
}
</style>
