<template>
  <div class="how-to-start-content-block" :class="category">
    <section>
      <div class="upper-decoration"></div>
      <h2 class="title" v-if="category === 'akita'">ナラティブブック秋田の始め方</h2>
      <h2 class="title" v-if="category === 'care'">ケアナラティブ秋田の始め方</h2>
      <h2 class="title" v-if="category === 'kids'">キッズナラティブ秋田の始め方</h2>
      <div class="lower-decoration"></div>
      <div class="how-to-start-content">
        <div class="target-controll">
          <span class="target-for" :class="{ active: readerType === 'user' }" @click="switchReader('user')">見守られる方(一般の方)</span>
          <span class="target-for" :class="{ active: readerType === 'org' }" @click="switchReader('org')">見守る方(医療福祉介護等の施設関係の方)</span>
        </div>
        <div class="common-contents" :class="{ active: readerType === 'user' }">
          <div class="common-content">
            <h3>相談できるところを探そう</h3>
            <div class="content-text">
              情報共有ツール「ナラティブブック」は、自分ひとりでも始まられますが、ナラティブブック秋田に参加している医療・福祉・介護などの施設の方に相談するところから始めるのが一番です。<br/>
              まずは、ステッカーを掲示している関係機関や、ナラティブブック参加施設検索などでナラティブブック秋田を始めている施設の方に相談してください。<br/>
              もちろん、現在かかりつけになっていて、まだナラティブブック秋田を始めていない医療・福祉・介護施設の方に相談して、一緒に始めるのも良いですね。
            </div>
            <div class="content-figures">
              <div class="content-fig">
                <img src="/img/sticker_img.png" />
                <span class="fig-caption"><span>このステッカーを</span><span>掲示しているところ</span></span>
              </div>
              <div class="content-fig">
                <a href="">
                  <img src="/img/org_search_screen.png" />
                  <span class="fig-caption"><span>ナラティブブック</span><span>参加施設検索</span></span>
                </a>
              </div>
              <div class="content-fig">
                <a href="https://coco-portal.org">
                  <img src="/img/coco_portal_map_screen.png" />
                  <span class="fig-caption"><span>COCOサポートマップ:</span><span>ナラティブブック参加施設</span></span>
                </a>
              </div>
            </div>
          </div>
          <div class="common-content">
            <h3>アカウントを登録してみよう</h3>
            <div class="content-fig">
              <img src="/img/narrativebook_logo.png" />
              <div class="content-text">
                ナラティブブックのログインページから無料で登録できます。ナラティブブックIDを作ってから、かかりつけなどの登録もできます。<br/>
                普段からナラティブブックを使うことで、いざというときにあなたを見守る人たちにあなたのことをわかってもらえるようになります。<br/>
                とりあえずかかりつけを作らずに、自分で初めてみることもできます。<br/>
                <a class="start-narrativebook" href="https://narrativebook.jp">ナラティブブックのアカウント登録はこちら</a>
              </div>
            </div>
            <div class="only-kids-content" v-if="category === 'kids'">
              <h3 class="kids-content">保護者の方へ:<span class="sub-title">あなたのお子さんのアカウントを作りましょう</span></h3>
              <div class="kids-content">
                <div class="content">
                  <div class="title">あなたのアカウントを作ったら、お子さんのアカウントを作りましょう</div>
                  <img src="/img/kids_create_child_account.png" />
                </div>
                <div class="content">
                  <div class="title">代理設定でお子さん用のアカウントを作ってかかりつけ</div>
                  <img src="/img/kids_mother_set_child_account.png" />
                </div>
                <div class="content">
                  <div class="title">自分のアカウントには自分の考えや想いを書こう</div>
                  <img src="/img/kids_mother_to_mothers.png" />
                </div>
                <div class="content">
                  <div class="title">お子さんが自分で使えるようになったらアカウントを独立させられる</div>
                  <img src="/img/kids_child_indipendent.png" />
                </div>
              </div>
            </div>
          </div>
          <div class="common-content">
            <h3>かかりつけを作ろう</h3>
            <div class="content">
              <div class="title">かかりつけになってほしい医療・福祉・介護施設等に相談する</div>
              <div class="content-figures">
                <img src="/img/care_start_find_org.png" v-if="category !== 'kids'" />
                <img src="/img/kids_start_find_org.png" v-if="category === 'kids'" />
              </div>
            </div>
            <div class="content">
              <div class="title">「かんたんQR」機能でその場でかかりつけ</div>
              <div class="content-figures">
                <img src="/img/care_start_easyQR.png" v-if="category !== 'kids'" />
                <img src="/img/kids_start_easyQR.png" v-if="category === 'kids'" />
                <div class="sub-content">
                  <div class="sub-caption">※互いにスマートフォンなどのカメラ付きでインターネットにつながる機材が必要です</div>
                </div>
              </div>
            </div>
            <div class="content">
              <div class="title">NBIDを伝えてかかりつけ</div>
              <div class="content-figures">
                <img src="/img/care_start_nbid.png" v-if="category !== 'kids'" />
                <img src="/img/kids_start_nbid.png" v-if="category === 'kids'" />
                <div class="sub-content">
                  <ul>
                    <li>NBIDを施設の担当者に伝える</li>
                    <li>施設に案内を送ってもらう</li>
                    <li>承認する</li>
                  </ul>
                  <div class="sub-caption">※NBIDは、ナラティブブックのログインに使うID</div>
                </div>
              </div>
            </div>
            <div class="content">
              <div class="title">施設番号を聞いてかかりつけ</div>
              <div class="content-figures">
                <img src="/img/care_start_org_number.png" v-if="category !== 'kids'" />
                <img src="/img/kids_start_org_number.png" v-if="category === 'kids'" />
                <div class="sub-content">
                  <ul>
                    <li>かかりつけしたい施設の施設番号を聞く</li>
                    <li>施設を検索</li>
                    <li>かかりつけ申請を送信</li>
                    <li>施設が申請を承認</li>
                  </ul>
                  <div class="sub-caption">※施設番号は、ナラティブブックで施設に発行される16桁の数字</div>
                </div>
              </div>
            </div>
          </div>
          <div class="common-content">
            <h3><span>詳しいやり方は</span><span>ナラティブブック公式Youtubeチャンネルの</span><span>マニュアル動画で</span></h3>
            <div class="content">
              <a href="https://www.youtube.com/watch?v=0E24f58orFU&list=PLbYxMSJzPemDW9dPc9GSVpSDr3XcBPSfB">ナラティブブックマニュアル動画プレイリスト<span class="video-jump-mark">▶</span></a>
            </div>
          </div>
        </div>
        <div class="common-contents" :class="{ active: readerType === 'org' }">
          <div class="common-content">
            <h3>患者さん/利用者さんにナラティブブック秋田を始めたいと言われたら・・・</h3>
            <ol class="start-step">
              <li>
                担当者を決めよう
              </li>
              <li>
                ナラティブブック秋田施設利用申込書を<a href="">ダウンロード<span class="jump-mark">▶</span></a>
              </li>
              <li>
                申込書に記入して事務局へ送る
                <span class="sub-content" v-if="category !== 'kids'">ケアナラティブは秋田県医師会郡市医師会事務局へ</span>
                <span class="sub-content" v-if="category !== 'care'">キッズナラティブは秋田県医師会事務局へ</span>
              </li>
              <li>事務局からの承認メールを待つ</li>
              <li>
                施設管理者アカウントを登録
                <span class="sub-content">承認メールのリンクから登録ページへ<br/>必要事項を入力したら完了</span>
              </li>
              <li>
                職員を登録する
                <span class="sub-content">施設管理者が職員案内を送信<br/>メールのリンクから、職員が自分でアカウント登録</span>
              </li>
              <li>準備完了</li>
            </ol>
          </div>
          <div class="common-content">
            <h3>患者さん/利用者さんのかかりつけになろう</h3>
            <div class="content">
              <div class="title">「かんたんQR」機能でその場でかかりつけ</div>
              <div class="content-figures">
                <img src="/img/care_start_easyQR.png" v-if="category !== 'kids'" />
                <img src="/img/kids_start_easyQR.png" v-if="category === 'kids'" />
                <div class="sub-content">
                  <div class="sub-caption">※互いにスマートフォンなどのカメラ付きでインターネットにつながる機材が必要です</div>
                </div>
              </div>
            </div>
            <div class="content">
              <div class="title">NBIDを聞いてかかりつけ案内を送る</div>
              <div class="content-figures">
                <img src="/img/care_start_nbid.png" v-if="category !== 'kids'" />
                <img src="/img/kids_start_nbid.png" v-if="category === 'kids'" />
                <div class="sub-content">
                  <ul>
                    <li>かかりつけ案内を送信</li>
                    <li>承認してもらう</li>
                  </ul>
                  <div class="sub-caption">※NBIDは、ナラティブブックのログインに使うID</div>
                </div>
              </div>
            </div>
            <div class="content">
              <div class="title">施設番号を教えてかかりつけ申請してもらう</div>
              <div class="content-figures">
                <img src="/img/care_start_org_number.png" v-if="category !== 'kids'" />
                <img src="/img/kids_start_org_number.png" v-if="category === 'kids'" />
                <div class="sub-content">
                  <ul>
                    <li>施設の施設番号を伝える</li>
                    <li>施設を検索してもらう</li>
                    <li>かかりつけ申請を送信してもらう</li>
                    <li>申請を承認</li>
                  </ul>
                  <div class="sub-caption">※施設番号は、ナラティブブックで施設に発行される16桁の数字</div>
                </div>
              </div>
            </div>
            <div class="content">
              <div class="title">かかりつけチームを作ろう</div>
              <div class="content-figures">
                <img src="/img/care_team.png" v-if="category !== 'kids'" />
                <img src="/img/kids_team.png" v-if="category === 'kids'" />
                <div class="sub-content">
                  <div class="sub-title">本人の希望や想いを共有できる</div>
                  <div class="sub-caption">同じ患者さん/利用者さんを見守る他の施設にこのホームページやパンフレット、リーフレットを紹介して、ナラティブブック秋田を始めてもらう</div>
                </div>
              </div>
            </div>
          </div>
          <div class="common-content">
            <h3>詳しいやり方はナラティブブック公式Youtubeチャンネルのマニュアル動画で</h3>
            <div class="content">
              <a href="https://www.youtube.com/watch?v=0E24f58orFU&list=PLbYxMSJzPemDW9dPc9GSVpSDr3XcBPSfB">ナラティブブックマニュアル動画プレイリスト<span class="video-jump-mark">▶</span></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="category == 'kids'">
      <kids-how-to-start-content-block />
    </section>
  </div>
</template>

<script>
import KidsHowToStartContentBlock from '@/components/narrativebook/commonContent/KidsHowToStartContentBlock.vue'

export default {
  name: 'HowToStartContentBlock',

  components: {
    KidsHowToStartContentBlock
  },

  props: {
    category: String
  },

  data () {
    return {
      readerType: 'user'
    }
  },

  methods: {
    switchReader (type) {
      this.readerType = type
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
@import '~@/assets/css/narrative_akita_title_style.scss';
.how-to-start-content-block {
  .how-to-start-content {
    .target-controll {
      display: flex;
      .target-for {
        padding: 5px 20px;
        color: #a16d5d;
        background-color: #f9f9f9;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid #f3f3f3;
        cursor: pointer;
        &.active {
          color: $care-text-color;
          background-color: #fff;
          border-bottom: 1px solid #fff;
        }
      }
    }
    .common-contents {
      display: none;
      background-color: #fff;
      &.active {
        display: block;
      }
      .common-content {
        padding: 20px;
        h3 {
          margin: 20px 0 10px;
          transform: scale(1, 1.5);
          font-weight: 400;
          font-size: 16px;
          letter-spacing: .5em;
          border-bottom: 1px solid $content-text-color;
          span {
            display: inline-block;
          }
        }
        .content {
          padding: 10px 0;
          .title {
            margin: 15px 0 10px;
            transform: scale(1, 1.5);
            letter-spacing: .3em;
            font-size: 14px;
            border-bottom: 1px solid $care-text-color;
            color: $care-text-color;
          }
          .sub-caption {
            color: $care-text-color;
            font-size: 13px;
          }
          ul {
            margin: 0;
            margin-block-start: 0;
            padding-inline-start: 1em;
            font-size: 14px;
            color: $care-text-color;
          }
          a {
            display: inline-flex;
            align-items: center;
            color: $content-text-color;
            .video-jump-mark {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              margin-left: 5px;
              width: 20px;
              height: 15px;
              font-size: 10px;
              text-align: center;
              border-radius: 2px;
              background-color: $content-text-color;
              color: #fff;
            }
          }
        }
        .content-text {
          padding: 10px;
          border: 1px solid $care-text-color;
          font-size: 14px;
          color: $care-text-color;
          a {
            color: $content-text-color;
          }
        }
        .content-figures {
          margin-top: 10px;
          display: flex;
          justify-content: space-around;
          @include mediaQuery('phone') {
            flex-wrap: wrap;
          }
          .sub-content {
            margin-left: 10px;
          }
          .content-fig {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: auto;
            a {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: $content-text-color;
            }
            img {
              display: inline-block;
              width: 150px;
              margin-bottom: 10px;
            }
            .fig-caption {
              display: inline-block;
              text-align: center;
              width: 200px;
              span {
                display: inline-block;
              }
            }
          }
        }
        ol.start-step {
          color: $care-text-color;
          li {
            a {
              color: $content-text-color;
            }
          }
        }
      }
    }
  }
  &.care {
    .how-to-start-content {
      .target-controll {
        .target-for {
          color: #f6ad49;
          &.active {
            color: $care-main-color;
          }
        }
      }
      .common-contents {
        .common-content {
          h3 {
            border-bottom: 1px solid $care-main-color;
          }
          .content {
            padding: 10px 0;
            .title {
              border-bottom: 1px solid $care-text-color;
              color: $care-text-color;
            }
            .sub-caption {
              color: $care-text-color;
            }
            ul {
              color: $care-text-color;
            }
            a {
              color: $care-main-color;
              .video-jump-mark {
                background-color: $care-main-color;
              }
            }
          }
          .content-text {
            padding: 10px;
            border: 1px solid $care-text-color;
            font-size: 14px;
            color: $care-text-color;
            a {
              color: $care-main-color;
            }
          }
          .content-figures {
            .content-fig {
              a {
                color: $care-main-color;
              }
            }
          }
          ol.start-step {
            color: $care-text-color;
            li {
              a {
                color: $care-main-color;
              }
            }
          }
        }
      }
    }
  }
  &.kids {
    .how-to-start-content {
      .target-controll {
        .target-for {
          color: #eb6ea5;
          &.active {
            color: $kids-main-color;
          }
        }
      }
      .common-contents {
        .common-content {
          h3 {
            border-bottom: 1px solid $kids-main-color;
          }
          .content {
            .title {
              border-bottom: 1px solid $kids-text-color;
              color: $kids-text-color;
            }
            .sub-caption {
              color: $kids-text-color;
            }
            ul {
              color: $kids-text-color;
            }
            a {
              color: $kids-main-color;
              .video-jump-mark {
                background-color: $kids-main-color;
              }
            }
          }
          .content-text {
            border: 1px solid $kids-text-color;
            color: $kids-text-color;
            a {
              color: $kids-main-color;
            }
          }
          .content-figures {
            .content-fig {
              a {
                color: $kids-main-color;
              }
            }
          }
          ol.start-step {
            color: $kids-text-color;
            li {
              a {
                color: $kids-main-color;
              }
            }
          }
        }
      }
    }
  }
}
</style>
