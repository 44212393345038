<template>
  <span class="top-index" :class="[styleName, {open: currentSubMenu === 'documents'}]" @click="openSubMenu">資料</span>
  <ul class="sub-menu" :class="[{open: currentSubMenu === 'documents'}, styleName]">
    <li v-for="(downloadContent, index) in displayDownloadContents" :key="'dlc' + index" :class="{index: downloadContent.type === 'index'}">
      <a class="file-download" v-if="downloadContent.url" :href="downloadContent.url" target="_blank">
        <span class="file-style" v-if="downloadContent.filetype">{{ downloadContent.filetype }}</span>
        {{ downloadContent.name }}
        <span class="jump-mark">▶</span>
      </a>
      <span class="category" v-if="!downloadContent.url">{{ downloadContent.name }}</span>
    </li>
  </ul>
</template>

<script>
import * as DownloadContents from '@/assets/data/download_contents.js'

export default {
  name: 'DownloadContentSubMenu',

  props: {
    currentSubMenu: String,
    styleName: String
  },

  emits: ['openSubMenuEvent'],

  computed: {
    displayDownloadContents () {
      if (this.styleName === 'care' || this.styleName === 'care-content') {
        return this.downloadContents.filter((c) => { return (!c.category || c.category === 'common' || c.category === 'care') } )
      } else if (this.styleName === 'kids' || this.styleName === 'kids-content') {
        return this.downloadContents.filter((c) => { return (!c.category || c.category === 'common' || c.category === 'kids') } )
      } else {
        return this.downloadContents
      }
    }
  },

  data () {
    return {
      downloadContents: DownloadContents.downloadContents,
    }
  },

  methods: {
    openSubMenu () {
      this.$emit('openSubMenuEvent', 'documents')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
.top-index {
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &.open {
    color: $content-text-color;
  }
  &.care.open {
    color: $care-main-color;
  }
  &.kids.open {
    color: $kids-main-color;
  }
  &.akita-content {
    display: none;
  }
  &.care-content {
    display: none;
  }
  &.kids-content {
    display: none;
  }
}
ul.sub-menu {
  z-index: 1000;
  display: none;
  position: absolute;
  top: 31px;
  left: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-block-start: 0;
  margin-inline-start: 0;
  background-color: $frame-background-color;
  width: 350px;
  max-height: calc(100vh - 150px);
  max-height: calc(100dvh - 150px);
  overflow: auto;
  border: 1px solid $box-content-color;
  border-bottom: none;
  &.tail {
    left: auto;
    right: 0;
  }
  &.open {
    display: block;
  }
  li {
    padding: 5px;
    text-align: left;
    border: none;
    border-bottom: 1px solid $box-content-color;
    .index {
      font-weight: bold;
    }
    a {
      display: inline-block;
      // align-items: center;
      width: calc(100% - 20px);
      height: 100%;
      padding: 5px 10px;
      text-decoration: none;
      color: $box-content-color;
      &.image-link {
        display: inline-flex;
        align-items: center;
      }
      .file-style {
        display: inline-block;
        margin-right: 5px;
        padding: 3px;
        background-color: $content-text-color;
        color: #fff;
        font-size: 10px;
        font-weight: bold;
      }
      .jump-mark {
        margin-left: 5px;
        color: $content-text-color;
      }
    }
  }
  &.care {
    border: 1px solid $care-text-color;
    li {
      border-bottom: 1px solid $care-text-color;
      a {
        color: $box-content-color;
        .file-style {
          background-color: $care-main-color;
          color: #fff;
        }
        .jump-mark {
          color: $care-main-color;
        }
      }
    }
  }
  &.kids {
    background-color: $kids-bg-color;
    border: 1px solid $kids-text-color;
    li {
      border-bottom: 1px solid $kids-text-color;
      a {
        color: $kids-text-color;
        .file-style {
          background-color: $kids-main-color;
          color: #fff;
        }
        .jump-mark {
          color: $kids-main-color;
        }
      }
    }
  }
  &.akita-content {
    z-index: auto;
    position: relative;
    top: auto;
    left: auto;
    max-height: none;
    width: auto;
    background-color: transparent;
    border: none;
    li {
      margin-left: 30px;
      padding: 0;
      border: none;
      &.index {
        margin-top: 10px;
        margin-left: 10px;
        border-bottom: 1px solid $content-text-color;
        .category {
          font-size: 18px;
        }
      }
      a {
        padding: 5px 10px;
        color: $care-text-color;
        .file-style {
          background-color: $content-text-color;
          color: #fff;
        }
        .jump-mark {
          margin-left: 5px;
          color: $content-text-color;
        }
      }
    }
  }
  &.kids-content {
    z-index: auto;
    position: relative;
    top: auto;
    left: auto;
    max-height: none;
    width: auto;
    background-color: transparent;
    border: none;
    li {
      margin-left: 30px;
      padding: 0;
      border: none;
      &.index {
        margin-top: 10px;
        margin-left: 10px;
      }
      a {
        padding: 5px 10px;
        color: $kids-text-color;
        .file-style {
          background-color: $kids-main-color;
          color: #fff;
        }
        .jump-mark {
          margin-left: 5px;
          color: $kids-main-color;
        }
      }
    }
  }
  &.care-content {
    z-index: auto;
    position: relative;
    top: auto;
    left: auto;
    max-height: none;
    width: auto;
    background-color: transparent;
    border: none;
    li {
      margin-left: 30px;
      padding: 0;
      border: none;
      &.index {
        margin-top: 10px;
        margin-left: 10px;
      }
      a {
        padding: 5px 10px;
        color: $care-text-color;
        .file-style {
          background-color: $care-main-color;
          color: #fff;
        }
        .jump-mark {
          margin-left: 5px;
          color: $care-main-color;
        }
      }
    }
  }
}

</style>