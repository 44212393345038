<template>
  <div class="event-content-block" :class="category">
    <div class="upper-decoration"></div>
    <h2>イベントスケジュール</h2>
    <div class="lower-decoration"></div>
    <div class="caption">
        ナラティブブック秋田の活動について、各種イベントや動画公開などのお知らせとスケジュールをご覧いただけます。<br/>
    </div>
    <h3>これからのイベント</h3>
    <div class="event-list">
      <div class="event" v-for="(schedule, index) in futureEvents" :key="'fev' + index">
        <div class="target-date">{{ schedule.target_date }}<span class="type" :class="schedule.category" >{{ schedule.type }} ({{ schedule.category }})</span></div>
        <div class="title">
          {{ schedule.title }}
          <span class="content-link" v-if="schedule.url"><a :href="schedule.url" target="_blank">リンク</a></span>
        </div>
        <div class="description">
          <div class="image" v-if="schedule.image"><img :src="imagePath(schedule)" /></div>
          <div class="text" v-html="schedule.wrapedDescription"></div>
        </div>
      </div>
    </div>
    <h3>過去のイベント</h3>
    <div class="event-list">
      <div class="event" v-for="(schedule, index) in pastEvents" :key="'pev' + index">
        <div class="target-date">{{ schedule.target_date }}<span class="type" :class="schedule.category" >{{ schedule.type }} ({{ schedule.category }})</span></div>
        <div class="title">
          {{ schedule.title }}
          <span class="content-link" v-if="schedule.url"><a :href="schedule.url" target="_blank">開く<img src="/img/outer_link_icon.svg"/></a></span>
        </div>
        <div class="description">
          <div class="image" v-if="schedule.image"><img :src="imagePath(schedule)" /></div>
          <div class="text" v-html="schedule.wrapedDescription"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as CSV from '@/assets/data/event_schedules.js'

export default {
  name: 'EventContentBlock',

  props: {
    category: String
  },

  data () {
    return {
      scheduleEvents: [],
      pastEvents: [],
      futureEvents: []
    }
  },

  mounted () {
    this.readSource()
  },

  methods: {
    readSource () {
      fetch(window.location.origin + '/data/displayContent/event_schedules.csv').then(res => {
        res.text().then(result => {
          // console.log(result)
          this.parseSourceCSV(result)
        })
      }).catch(err => {
        console.error(err)
      })
    },

    parseSourceCSV (csvText) {
      var csvArray = this.$papa.parse(csvText)
      // console.log(csvArray.data)
      // 0日時
      // 1"区分・研修会、講演会、説明会など・動画配信・HP更新（コンテンツ追加など）"
      // 2"対象（情報を受け取って欲しい）・地域、対象者区分など"
      // 3タイトル
      // 4詳細
      // 5リンクURL
      // 6画像
      // 7"カテゴリakita,care,kids,other"
      // 8公開FLG
      var now = new Date()
      csvArray.data.forEach(line => {
        var sEvent = {
          target_date: line[0],
          targetDate: new Date(line[0]),
          type: line[1],
          target: line[2],
          title: line[3],
          description: line[4],
          wrapedDescription: line[4].replace(/\n/gi, '<br />'),
          url: line[5],
          image: line[6],
          category: line[7],
          displayFlg: line[8]
        }
        if (sEvent.targetDate < now) {
          this.pastEvents.push(sEvent)
        } else if (sEvent.targetDate > now) {
          this.futureEvents.push(sEvent)
        }
      })
      this.pastEvents.sort((f,s) => {
        if (f.target_date > s.target_date) {
          return -1
        } else if (f.target_date < s.target_date) {
          return 1
        } else {
          return 0
        }
      })
      this.futureEvents.sort((f,s) => {
        if (f.target_date > s.target_date) {
          return 1
        } else if (f.target_date < s.target_date) {
          return -1
        } else {
          return 0
        }
      })
    },

    imagePath (schedule) {
      return '/img/' + schedule.image
    }

  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
@import '~@/assets/css/narrative_akita_title_style.scss';
.event-content-block {
  h3 {
    margin: 15px 0;
    padding: 0;
    transform: scale(1, 1.5);
    letter-spacing: 0.3em;;
    font-weight: 400;
    font-size: 16px;
    border-bottom: 1px solid $content-text-color;
  }
  .event-list {
    padding: 10px 0;
    .event {
      margin-bottom: 20px;
      color: $care-text-color;
      .target-date {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        .type {
          display: inline-block;
          margin-left: 15px;
          padding: 2px 5px;
          font-size: 11px;
          font-weight: normal;
          background-color: $content-text-color;
          color: #fff;
          &.care {
            background-color: $care-main-color;
          }
          &.kids {
            background-color: $kids-main-color;
          }
        }
      }
      .title {
        padding: 0;
        font-size: 14px;
        font-weight: 500px;
        transform: scale(1, 1.5);
        .content-link {
          margin-left: 20px;
          display: inline-flex;
          align-items: center;
          a {
            color: $kids-text-color;
            transform: scale(1, 0.7);
            img {
              margin-left: 5px;
              width: 12px;
              height: 12px;
            }
          }
        }
      }
      .description {
        margin: 10px 0;
        padding-top: 10px;
        border-top: 1px solid $care-text-color;
        display: flex;
        font-size: 14px;
        @include mediaQuery('phone') {
          flex-wrap: wrap;
        }
        .image {
          margin-right: 20px;
          max-width: 300px;
          max-height: 200px;
          img {
            max-width: 300px;
            max-height: 200px;
          }
        }
      }
    }
  }
  &.care {
    h3 {
      border-bottom: 1px solid $care-main-color;
    }
    .event-list {
      padding: 10px 0;
      .event {
        margin-bottom: 20px;
        color: $care-text-color;
        .description {
          border-top: 1px solid $care-text-color;
        }
      }
    }
  }
  &.kids {
    h3 {
      border-bottom: 1px solid $kids-main-color;
    }
    .event-list {
      padding: 10px 0;
      .event {
        margin-bottom: 20px;
        color: $kids-text-color;
        .description {
          border-top: 1px solid $kids-text-color;
        }
      }
    }
  }
}
</style>
