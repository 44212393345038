<template>
  <main>
    <article>
      <section class="title">
        <h1><span>ナラティブブック</span><span>動画マニュアル一覧</span></h1>
        <div class="splitter"><img  src="/img/white_splitter.png" /></div>
        <div class="inline-nav">
          <nav>
            <ul>
              <li :class="{current: currentContent === 'register'}" @click="displayContent('register')">登録</li>
              <li :class="{current: currentContent === 'signin'}" @click="displayContent('signin')">ログイン設定</li>
              <li :class="{current: currentContent === 'relation'}" @click="displayContent('relation')">かかりつけ設定</li>
              <li :class="{current: currentContent === 'family'}" @click="displayContent('family')">家族設定</li>
              <li :class="{current: currentContent === 'profile'}" @click="displayContent('profile')">プロフィール</li>
              <li :class="{current: currentContent === 'usage'}" @click="displayContent('usage')">基本的な使い方</li>
            </ul>
          </nav>
        </div>
      </section>
      <section class="narrative-manuals block-section" v-show="currentContent == ''">
        <div class="preamble">
          ナラティブブックは、複数の立場の人々が特定の誰かを見守るために利用するシステムです。<br/>
          このような特性上、利用し始める前に様々な設定などを行う必要があり、操作が複雑に感じてしまいがちです。（やってしまえば思ったよりもかんたんなのですが）<br/>
          ここでは、ナラティブブックの登録方法や基本的な使い方を動画にしたものを列挙しています。<br/>
          各タイトルはリンクになっており、該当する動画ページ（Youtube）にジャンプして閲覧することができます。動画では、一つ一つのステップを、画面写真を見ながら説明していますので、実際に操作するときに、一時停止しながらやってみてください。<br/>
          また、かかりつけ設定などの一つの操作にもいくつかの方法がありますので、さっと動画をみてから、自分にあった方法で選んで実際にやってみてください。<br/>
          なお、通しですべてのマニュアルを閲覧できるプレイリストは<a href="https://www.youtube.com/watch?v=0E24f58orFU&list=PLbYxMSJzPemDW9dPc9GSVpSDr3XcBPSfB" target="_blank">こちら</a>でご覧になれます。
        </div>
      </section>
      <section class="manual block-section" v-show="currentContent == 'register'">
        <h3><img  src="/img/manual_registration_title.png" alt="ナラティブブックへの登録" /></h3>
        <h4>
          <a href="https://youtu.be/0E24f58orFU" target="_blank">
            [NBマニュアル]#1 新規施設管理者登録<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #1<br/>
          新規施設管理者の登録方法
        </div>
        <h4>
          <a href="https://youtu.be/9keNY5RU584" target="_blank">
            [NBマニュアル]#2-1 職員登録 その１&nbsp;案内状の送信<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #2-1<br/>
          職員の登録方法&nbsp;その１&nbsp;施設からの案内状の送信
        </div>
        <h4>
          <a href="https://youtu.be/HqWrRoSGu3Q" target="_blank">
            [NBマニュアル]#2-2 職員登録 その２&nbsp;案内状の承諾<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #2-2<br/>
          職員の登録方法&nbsp;その２&nbsp;案内状の承諾
        </div>
        <h4>
          <a href="https://youtu.be/dwUTTcxgKBw" target="_blank">
            [NBマニュアル]#3-1 ログイン設定 その１&nbsp;一般利用者編<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
      </section>
      <section class="manual block-section" v-show="currentContent == 'signin'">
        <h3><img  src="/img/manual_signin_and_qr_title.png" alt="ログイン設定とQRコードの登録など" /></h3>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #3-1<br/>
          ログインの設定&nbsp;その１&nbsp;一般利用者編
        </div>
        <h4>
          <a href="https://youtu.be/XGtN9iKf5mU" target="_blank">
            [NBマニュアル]#3-2 ログイン設定 その２&nbsp;職員の切り替え<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #3-2<br/>
          ログインの設定 その２&nbsp;職員の切り替え<br/>
          複数の施設に登録されている場合の施設の切替方法
        </div>
        <h4>
          <a href="https://youtu.be/GjYX-ZOQckc" target="_blank">
            [NBマニュアル]#4 アカウント作成<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #4<br/>
          アカウントの作成方法
        </div>
        <h4>
          <a href="https://youtu.be/PKgQrBDOUUU" target="_blank">
            [NBマニュアル]#5 公式カードのQRコード登録<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #5<br/>
          ナラティブブック公式カードのQRコードを登録する
        </div>
      </section>
      <section class="manual block-section" v-show="currentContent == 'relation'">
        <h3><img  src="/img/manual_relation_setting_title.png" alt="かかりつけの設定" /></h3>
        <h4>
          <a href="https://youtu.be/z2OQi3QqnEA" target="_blank">
            [NBマニュアル]#6-1-1 かかりつけ設定&nbsp;利用者から施設管理者への「かかりつけ依頼」の送信<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #6-1-1<br/>
          かかりつけ設定&nbsp;方法①<br/>
          利用者から施設管理者への「かかりつけ依頼」の送信<br/>
          その１&nbsp;利用者から「かかりつけ依頼」を送信する方法
        </div>
        <h4>
          <a href="https://youtu.be/06QLWZyOXGg" target="_blank">
            [NBマニュアル]#6-1-2 かかりつけ設定&nbsp;利用者から施設管理者への「かかりつけ依頼」を承認する方法<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #6-1-2<br/>
          かかりつけ設定&nbsp;方法①<br/>
          利用者から施設管理者への「かかりつけ依頼」の送信<br/>
          その２&nbsp;施設管理者が「かかりつけ依頼」を承認する方法
        </div>
        <h4>
          <a href="https://youtu.be/D2pkL2BCN2o" target="_blank">
            [NBマニュアル]#6-2-1-1 かかりつけ設定&nbsp;施設管理者からNBIDかメールアドレスで「かかりつけ案内」を送信する<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #6-2-1<br/>
          かかりつけ設定&nbsp;方法②<br/>
          施設管理者から利用者への「かかりつけ案内」の送信<br/>
          その１&nbsp;施設管理者から「かかりつけ案内」を送信する方法<br/>
          &nbsp;NBIDかメールアドレスで「かかりつけ案内」を送信する 
        </div>
        <h4>
          <a href="https://youtu.be/-orVslex3jY" target="_blank">
            [NBマニュアル]#6-2-1-2 かかりつけ設定&nbsp;施設管理者から利用者へ公式カードのQRコードで「かかりつけ案内」を送信する<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #6-2-1-2<br/>
          かかりつけ設定&nbsp;方法②<br/>
          施設管理者から利用者への「かかりつけ案内」の送信<br/>
          その１&nbsp;施設管理者から「かかりつけ案内」を送信する方法<br/>
          &nbsp;公式カードのQRコードで「かかりつけ案内」を送信する 
        </div>
        <h4>
          <a href="https://youtu.be/xD99gKdW1rA" target="_blank">
            [NBマニュアル]#6-2-2 かかりつけ設定&nbsp;利用者による承認<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #6-2-2<br/>
          利用者が、施設から送られた「かかりつけ案内」を承認する方法
        </div>
        <h4>
          <a href="https://youtu.be/7UH4bwyZ7RI" target="_blank">
            [NBマニュアル]#6-3 かんたんQRでのかかりつけ登録<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #6-3<br/>
          「かんたんQR」を利用してかかりつけを登録する
        </div>
      </section>
      <section class="manual block-section" v-show="currentContent == 'family'">
        <h3><img  src="/img/manual_family_setting_title.png" alt="家族設定" /></h3>
        <h4>
          <a href="https://youtu.be/Ubl7q9edaZg" target="_blank">
            [NBマニュアル]#7-1 かんたんQRで家族登録<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #7-1<br/>
          家族設定&nbsp;「かんたんQR」を利用して家族を登録する
        </div>
        <h4>
          <a href="https://youtu.be/nBvLl2rAdI8" target="_blank">
            [NBマニュアル]#7-2-1 家族設定&nbsp;NBIDかメールアドレスで依頼状を送信<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #7-2-1<br/>
          家族設定&nbsp;依頼状を送信する方法①<br/>
          相手のNBIDかメールアドレスを利用して、家族登録依頼を送信する
        </div>
        <h4>
          <a href="https://youtu.be/UGLqK0fJMWs" target="_blank">
            [NBマニュアル]#7-2-2 家族設定&nbsp;公式カードのQRコードで依頼状の送信<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #7-2-2<br/>
          家族設定&nbsp;依頼状を送信する方法②&nbsp;その１<br/>
          ナラティブブック公式カードのQRコードを利用して、家族登録依頼を送信する
        </div>
        <h4>
          <a href="https://youtu.be/rYK08u8fDI8" target="_blank">
            [NBマニュアル]#7-2-3 家族設定&nbsp;公式カードのコード番号で依頼状の送信<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #7-2-3<br/>
          家族設定&nbsp;依頼状を送信する方法②&nbsp;その２<br/>
          ナラティブブック公式カードのコード番号を手入力して、家族登録依頼を送信する
        </div>
        <h4>
          <a href="https://youtu.be/Hn2_5Z3vHd8" target="_blank">
            [NBマニュアル]#7-3 家族設定&nbsp;依頼状の承認<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #7-3<br/>
          家族設定&nbsp;依頼状を承認する方法
        </div>
        <h4>
          <a href="https://youtu.be/Fqp9yAqjt2s" target="_blank">
            [NBマニュアル]#8-1 プロフィール編集(一般)<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
      </section>
      <section class="manual block-section" v-show="currentContent == 'profile'">
        <h3><img  src="/img/manual_profile_title.png" alt="プロフィールの設定" /></h3>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #8-1<br/>
          一般利用者でプロフィールを編集する
        </div>
        <h4>
          <a href="https://youtu.be/3aOwUYx2xuI" target="_blank">
            [NBマニュアル]#8-2 プロフィール編集(職員)<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #8-2<br/>
          施設職員でプロフィールを編集する
        </div>
      </section>
      <section class="manual block-section" v-show="currentContent == 'usage'">
        <h3><img  src="/img/manual_basic_use_title.png" alt="基本的な使い方" /></h3>
        <h4>
          <a href="https://youtu.be/b5rkwHulKho" target="_blank">
            [NBマニュアル]#11-1-1 タイムラインへの投稿<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #11-1-1<br/>
          基本的な利用方法&nbsp;タイムラインの説明と投稿のしかた
        </div>
        <h4>
          <a href="https://youtu.be/yZjoD9gxlG0" target="_blank">
            [NBマニュアル]#11-1-2 ログイン情報<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #11-1-2<br/>
          基本的な利用方法&nbsp;ログインの切り替え方とログイン情報の管理
        </div>
        <h4>
          <a href="https://youtu.be/-FblbMZD1cY" target="_blank">
            [NBマニュアル]#11-2 タイムライン投稿の整理・抽出<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #11-2<br/>
          基本的な利用方法&nbsp;タイムラインの投稿記事の整理・抽出方法について
        </div>
        <h4>
          <a href="https://youtu.be/58jXBQGPd9Q" target="_blank">
            [NBマニュアル]#11-3 コミュニケーションのための機能<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #11-3<br/>
          基本的な利用方法&nbsp;コミュニケーションのための特別な機能<br/>
          &nbsp;返信・会話表示・写真からの記事検索
        </div>
        <h4>
          <a href="https://youtu.be/Lw7hdRHK5gg" target="_blank">
            [NBマニュアル]#11-4 医療従事者向け機能<img  src="/img/video_link_icon.png" />
          </a>
        </h4>
        <div class="descriptions">
          ナラティブブック 動画マニュアル #11-4<br/>
          基本的な利用方法&nbsp;医療従事者向けの特別な機能<br/>
          &nbsp;バイタル・お薬情報・本人曰く
        </div>
      </section>
    </article>
  </main>
</template>

<script>
export default {
  name: 'MamualMenu',

  data () {
    return {
      currentContent: ''
    }
  },

  methods: {
    displayContent (contentName) {
      if (this.currentContent === contentName) {
        this.currentContent = ''
      } else {
        this.currentContent = contentName
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';

div.outer-frame {
  height: 100%;
  background-color: $frame-background-color;
  color: $content-text-color;

  a {
    color: $content-text-color;
  }

  main {
    background-image: none;
    background-color: $frame-background-color;
    color: $content-text-color;
    height: auto;
    padding-bottom: 1px;
  }
  section.title {
    margin: 0;
    padding: 0;
    height: auto;
    background-color: $content-text-color;
    background-image: url('~@/assets/css/images/nb_akita_sub_hero.png');
    color: #fff;
    text-align: center;
    h1 {
      margin: 0;
      padding: 10px 0 0;
      text-align: center;
      font-size: 30px;
      font-weight: 500;
      text-shadow: 1px 1px 3px #000;
      span {
        display: inline-block;
      }
    }
    div.splitter {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      height: 2px;
    }
    div.sub-title {
      font-size: 14px;
      color: #fff;
      text-align: center;
    }
    .inline-nav {
      nav {
        ul {
          display: flex;
          margin: 0;
          padding: 0;
          list-style-type: none;
          margin-block-start: 0;
          margin-inline-start: 0;
          li {
            flex-grow: 1;
            cursor: pointer;
            padding: 10px 20px;
            border: 1px solid #fff;
            color: #fff;
            background-color: rgba($color: $content-text-color, $alpha: .5);
            align-items: center;
            text-align: center;
            span {
              display: inline-block;
            }
            &.current {
              color: $content-text-color;
              background-color: rgba($color: #fff, $alpha: .5);
            }
          }
        }
      }
    }
  }

  section.block-section {
    margin: 32px 67px;
    padding: 32px 46px;
    background-color: #fff;
    h2 {
      text-align: center;
    }
    h3 {
      text-align: center;
    }
  }
  section.manual {
    h4 a {
      font-size: 17px;
      text-decoration: none;
      border-bottom: 1px solid $content-text-color;
    }
    div.descriptions {
      font-size: 16px;
    }
  }
  @include mediaQuery('phone') {
    section.title {
      h1 {
        font-size: 24px;
      }
      .inline-nav {
        nav {
          ul {
            flex-wrap: wrap;
            border: 1px solid #fff;
            border-bottom: none;
            border-left: none;
            li {
              border: none;
              border-left: 1px solid #fff;
              border-bottom: 1px solid #fff;
            }
          }
        }
      }
    }
    section.block-section {
      margin: 10px;
      padding: 10px;
      h3 {
        img {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
