<template>
  <div class="document-content-block" :class="category">
    <div class="upper-decoration"></div>
    <h2>資料ダウンロードその他</h2>
    <div class="lower-decoration"></div>
    <div class="caption">
        ナラティブブック秋田のパンフレットや申込み書類関係はここでダウンロードいただけます。<br/>
        カード、パンフレット、スターティングリーフ等の一部の印刷物は医師会事務局にございます。必要な場合はご連絡ください。<br/>
        書類データはファイルサイズが大きいものがあります。ダウンロードの際はWifi環境などで行ってください。
    </div>
    <download-content-sub-menu
      :currentSubMenu="'documents'"
      :styleName="styleName"
      />
  </div>
</template>

<script>
import DownloadContentSubMenu from '@/components/narrativebook/DownloadContentSubMenu.vue'

export default {
  name: 'DocumentContentBlock',

  components: {
    DownloadContentSubMenu
  },

  props: {
    category: String
  },

  computed: {
    styleName () {
      if (this.category === 'care') {
        return 'care-content'
      } else if (this.category === 'kids') {
        return 'kids-content'
      } else {
        return 'akita-content'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/color_table.scss';
@import '~@/assets/css/media_query.scss';
@import '~@/assets/css/narrative_akita_title_style.scss';
// .document-content-block {
// }
</style>
